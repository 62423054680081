import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { TBaseUser } from '@core/interfaces/Endpoints';

import { UsersService } from '@shared/services/users.service';

@Component({
  selector: 'app-users-selector',
  templateUrl: './users-selector.component.html',
  styleUrls: ['./users-selector.component.scss'],
})
export class UsersSelectorComponent implements OnInit {
  @Output() selectedUsers = new EventEmitter<string[]>;
  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('auto') autocomplete: ElementRef<MatAutocomplete> | undefined;

  chosenUsers: TBaseUser[] = [];
  searchControl = new FormControl('');
  foundUsers: TBaseUser[] = [];

  notFound = false;
  isLoading = false;

  constructor(
    private _usersService: UsersService,
  ) {

  }

  ngOnInit() {
    this.searchControl.valueChanges.pipe(
      filter(value => typeof value === 'string' && true && value !== ''),
      tap(() => {
        this.isLoading = true;
        this.notFound = false;
      }),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(value => this._usersService.searchUser(value!).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.foundUsers = [];
            this.notFound = true;
          }
          return of([]);
        }),
      )),
    ).subscribe(searchedUsers => {
      this.foundUsers = searchedUsers.filter(user => !user.roles.includes('admin'));
      if(this.foundUsers.length === 0) this.notFound = true;
      this.isLoading = false;
    });
  }

  remove(user: TBaseUser): void {
    const index = this.getUserIndex(user);

    if (index >= 0) {
      this.chosenUsers.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.getUserIndex(event.option.value) === -1) {
      this.chosenUsers.push(event.option.value);
    }
    // uncomment this two lines if input should be reset when option selected
    // this.chipInput!.nativeElement.value = '';
    // this.searchControl.setValue(null);
  }

  getUserIndex(user: TBaseUser) {
    return this.chosenUsers.findIndex(u => u.username === user.username);
  }

  emitChosenUsersIds(){
    this.selectedUsers.emit(this.chosenUsers.map(user => user._id!));
  }
}
