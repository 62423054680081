<div class="playlist" *ngIf="videos.length > 0">
  <div class="cropped-video-container">
    <div class="video">
      <app-stream-player
        [videoUrl]="videoData"
        (videoEnded)="onVideoEnded()"
      ></app-stream-player>
    </div>
  </div>
  <div class="video-selector">
    <div class="video-selector__button" *ngFor="let video of videos; let i = index"
         (click)="getVideo(video.avatarVideo, i)" [class.active]="activeVideoIndex === i">
      Topic {{ i + 1 }}
    </div>
  </div>
</div>
