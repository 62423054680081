export const getFieldAddConfirmationMessage = (value: string, fieldName: string): string => {
  return `Are you sure you want to add ${value} as ${fieldName}?`;
};

export const getFieldEditConfirmationMessage = (
  oldValue: string,
  newValue: string,
  fieldName: string
): string => {
  return `Are you sure you want to replace ${fieldName} from ${oldValue} to ${newValue}?`;
};

export const materialsConfigInfoMessage =
  'Select how many materials should be displayed in the bot per response.';

export const publicationsCountInfoMessage =
  'Select how many publications should be referenced in the bot.';

export const botBehaviourInfoMessage =
  'You can specify "who" the bot should be, how it should "behave", for example: You are a virtual assistant, help people understand problems with given topics.';

export const botInstructionInfoMessage =
  'The additional instructions you want to give to the AI can be anything you want, for example: use only simple words, don`t make the answer too complicated.';

export const innerBotMessages = {
  infoBehaviour:
    'You can specify "who" the bot should be, how it should "behave", for example: You are a virtual assistant, help people understand problems with given topics.',
  infoInstruction:
    'The additional instructions you want to give to the AI can be anything you want, for example: use only simple words, don`t make the answer too complicated.',
  infoConfig:
    'This text is used for prompting, it must be filled with variables such as {question} and {content}. Example: Answer {question} using this {content}.',
  infoTopP:
    'This parameter is used to control the variety of answers generated. Imagine that you are selecting words from a bag. If Top P is high, you have a greater variety of words to choose from, which means the answers can be more varied and surprising. If the value is low, you choose from a smaller, more predictable set of words.',
  infoTemperature:
    'Controls the creativity or boldness of answers. A high temperature means the AI is more willing to take risks, giving creative, sometimes unexpected answers. A low temperature makes the AI more conservative and the answers more predictable and safe.',
  infoPresencePenalty:
    'This parameter helps avoid repetition. If it is high, the AI tries not to repeat things it has already said. It`s like reminding someone not to talk about the same thing over and over again. A low Presence Penalty value means the AI may repeat itself more often',
  infoFrequencyPenalty:
    'This is similar to Presence Penalty, but refers to the overall frequency of concepts or topics. High Frequency Penalty encourages the AI to introduce new ideas and topics, avoiding things that come up too often. Low Frequency Penalty allows the AI to stay more with popular or frequently mentioned topics.',
  materialsInfo: 'Enable or disable the display of materials in the bot per response.',
};
