import { environment } from '@env/environment';

import { PostRequestData } from '../utils';

export class TalkingAvatarApi {
  static getToken(): PostRequestData<{ token: string }> {
    return {
      url: `${environment.backendURL}/api/talking-avatar/get-token`,
    };
  }
}
