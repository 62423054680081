import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TSourceDetail } from '@core/interfaces/TMessage';

@Component({
  selector: 'app-original-resource-modal',
  templateUrl: './original-resource-modal.component.html',
  styleUrls: ['./original-resource-modal.component.scss'],
})
export class OriginalResourceModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { sourceDetails: TSourceDetail[] },
    private _dialogRef: MatDialogRef<OriginalResourceModalComponent>
  ) {}

  cancel() {
    this._dialogRef.close();
  }
}
