import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CommonService } from '@shared/services/common.service';
import { MetadataService, MetadataSlugs } from '@shared/services/metadata.service';
import { defaultAppName, defaultVersion } from 'app/constants/defaults';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit, OnDestroy {
  private _subs: Subscription[] = [];
  public version = '';
  public appName = '';

  constructor(
    private _metadataService: MetadataService,
    private _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this._metadataService.get<string>(MetadataSlugs.Version).subscribe({
        next: metadata => {
          if (metadata.value) {
            this.version = String(metadata.value);
          } else {
            this.version = defaultVersion;
          }
        },
        error: () => {
          this.version = defaultVersion;
        },
      })
    );
    this._subs.push(
      this._metadataService.get<string>(MetadataSlugs.AppName).subscribe({
        next: metadata => {
          if (metadata.value) {
            this.appName = metadata.value;
          } else {
            this.appName = defaultAppName;
          }
        },
        error: () => {
          this.appName = defaultAppName;
        },
      })
    );
  }

  saveAppName() {
    this._subs.push(
      this._metadataService.set(MetadataSlugs.AppName, this.appName).subscribe({
        next: () => {
          this._commonService.openSnackBar('Successfully saved!');
        },
        error: () => {
          this._commonService.openSnackBar('Error occurred');
        },
      })
    );
  }

  saveAppVersion() {
    this._subs.push(
      this._metadataService.set(MetadataSlugs.Version, this.version).subscribe({
        next: () => {
          this._commonService.openSnackBar('Successfully saved!');
        },
        error: () => {
          this._commonService.openSnackBar('Error occurred');
        },
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
