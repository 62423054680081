import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, User } from '@core';
import { MetadataService, MetadataSlugs } from '@shared/services/metadata.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { defaultDashboardContent } from '../../constants/defaults';
import { TBotItem } from '@core/interfaces/TBotItem';
import { ConversationBotComponent } from '../bots/conversation-bot/conversation-bot.component';
import { NavigationSkipped, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  private _subs: Subscription[] = [];

  protected selectedBot: TBotItem | null = null;
  protected dashboardContent: SafeHtml | undefined;
  protected user: User | null = null;
  @ViewChild(ConversationBotComponent) conversationBotComponent!: ConversationBotComponent;

  constructor(
    private auth: AuthService,
    private _metadataService: MetadataService,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  protected conversationBegan = false;

  ngOnInit() {
    this._subs.push(
      this.auth.user().subscribe(value => {
        this.user = value;
        this.cdr.detectChanges();
      })
    );
    this.getDashboardContent();
    this._subs.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationSkipped) {
          this.reset();
        }
      })
    );
  }

  submit(message: { message: string; files: any[] }) {
    this.conversationBegan = true;
    this.conversationBotComponent.setMessageAndSubmit(message.message, message.files);
  }

  private getDashboardContent() {
    this._subs.push(
      this._metadataService.get<string>(MetadataSlugs.DashboardContent).subscribe({
        next: metadata => {
          if (metadata.value) {
            this.dashboardContent = this._sanitizer.bypassSecurityTrustHtml(metadata.value);
          } else {
            this.dashboardContent = defaultDashboardContent;
          }
        },
        error: () => {
          this.dashboardContent = defaultDashboardContent;
        },
      })
    );
  }

  getGreeting(): string {
    const hour = new Date().getHours();
    const name = this.user?.displayName || this.user?.name || this.user?.email || 'user';
    const greeting = hour < 12 ? 'Good morning' : hour < 18 ? 'Good afternoon' : 'Good evening';
    return `${greeting}, ${name}`;
  }

  reset() {
    this.conversationBegan = false;
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
