export interface TSourceMaterial {
  _id: string;
  project: string[];
  name: string;
  status: SourceMaterialStatus;
  keywords: string;
  fileType?: MaterialTypeInput;
  url?: string;
  fileLink?: string;
  content?: string;
  file?: File;
  isProcessing: boolean;
}

export interface TSourceMaterialFileTypes {
  id: string;
  name: string;
}

export interface TMaterialSourceFolder {
  _id?: string;
  name: string;
  status: SourceMaterialStatus;
  project?: any[];
  resources?: TSourceMaterial[];
}

export enum SourceMaterialStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type MaterialTypeInput = 'video' | 'youtube' | 'image' | 'file' | 'link';

export const sourceMaterialsFiles = ['video', 'image', 'file'];
