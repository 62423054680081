import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import {
  TFileExportConfiguration,
  TFileExportDriversAvailable,
} from '@shared/services/file-export/file-export-interface';

import { CommonService } from '@shared/services/common.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { ExportFilesFactory } from '@shared/services/file-export/drivers';

@Component({
  selector: 'app-text-file-export-modal',
  templateUrl: './text-file-export-modal.component.html',
  styleUrls: ['./text-file-export-modal.component.scss'],
})

export class TextFileExportModalComponent implements OnInit, OnDestroy {
  @Output() public actionCallback: EventEmitter<TFileExportConfiguration> = new EventEmitter();
  protected readonly ExportFilesFactory = ExportFilesFactory;
  protected isLoading = false;
  private _sub: Subscription | undefined;
  public editForm = this.fb.nonNullable.group({
    text: ['', [Validators.required]],
    pages: this.fb.array([
      ['', [Validators.required]],
    ]),
    options: this.fb.group({
      pageNumber: [true, [Validators.required]],
      headerLogo: [true, [Validators.required]],
      confidentialityClause: [true, [Validators.required]],
      confidentialityClauseText: ['This document is confidential', [Validators.required]],
    }),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { text: string, buttonLabel: string },
    private dialogRef: MatDialogRef<TextFileExportModalComponent>,
    private _commonService: CommonService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {
  }

  get formPages() {
    return this.editForm.get('pages') as FormArray;
  }

  ngOnInit(): void {
    this.formPages.at(0).patchValue(this.data.text);

    this._sub = this._commonService.getIsLoadingObservable().subscribe(state => {
      this.toggleFormDisable(state);
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  addPage(index: number) {
    this.formPages.insert(index, this.fb.control(''));
  }

  removePage(index: number) {
    if (!this.formPages.at(index).value) {
      this.formPages.removeAt(index);
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { text: `Are you sure you want to delete page` },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      this.formPages.removeAt(index);
      dialogRef.close();
      dialogSubscription.unsubscribe();
    });
  }

  confirm(driver: TFileExportDriversAvailable) {
    const data = {
      driver,
      ...this.editForm.value,
    } as TFileExportConfiguration;

    this.actionCallback.emit(data);
  }

  ngOnDestroy() {
    this._sub?.unsubscribe();
  }

  toggleFormDisable(disabled: boolean) {
    this.isLoading = disabled;
    disabled ? this.editForm.disable() : this.editForm.enable();
  }

}
