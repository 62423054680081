export enum UserAPIField {
  NAME = 'name',
  DISPLAY_NAME = 'display_name',
}

export enum UserFormField {
  NAME = 'name',
  DISPLAY_NAME = 'displayName',
}

export enum UserField {
  NAME = 'name',
  DISPLAY_NAME = 'display name',
}

export const userFields = {
  [UserFormField.NAME]: {
    apiSlug: UserAPIField.NAME,
    slug: UserFormField.NAME,
    label: UserField.NAME,
  },
  [UserFormField.DISPLAY_NAME]: {
    apiSlug: UserAPIField.DISPLAY_NAME,
    slug: UserFormField.DISPLAY_NAME,
    label: UserField.DISPLAY_NAME,
  }
};
