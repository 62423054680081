import { Component, Input } from '@angular/core';
import { QuizQuestion } from '@core/interfaces/TBot';
import { TSourceDetail } from '@core/interfaces/TMessage';

type ParsedPart =
  | { type: 'text'; value: string | number }
  | {
      type: 'references';
      value: { ref: number; index: number };
    };

@Component({
  selector: 'app-quiz-reference',
  templateUrl: './quiz-reference.component.html',
  styleUrls: ['./quiz-reference.component.css'],
})
export class QuizReferenceComponent {
  protected parsedParts: ParsedPart[] = [];

  @Input() set answer(text: string) {
    this.parsedParts = this.parseString(text!);
  }

  @Input() sourcesDetails: TSourceDetail[] = [];

  parseString(input: string): ParsedPart[] {
    const regex = /{{\s*(\d+)\|(\d+)\s*}}/g;
    const parts: ParsedPart[] = [];
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(input)) !== null) {
      if (match.index > lastIndex) {
        parts.push({ type: 'text', value: input.slice(lastIndex, match.index).trim() });
      }

      parts.push({ type: 'references', value: { ref: Number(match[1]), index: Number(match[2]) } });

      lastIndex = regex.lastIndex;
    }

    if (lastIndex < input.length) {
      parts.push({ type: 'text', value: input.slice(lastIndex).trim() });
    }

    return parts;
  }
}
