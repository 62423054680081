<div class="p-24">
  <div class="d-flex justify-content-between align-items-center">
    <h3>Users</h3>
    <div class="d-flex justify-content-between align-items-center">
      <p class="m-r-4">2FA for all users:</p>
      <app-two-fa-toggle></app-two-fa-toggle>
      <button mat-raised-button (click)="addUser()" color="accent">
        <mat-icon>add_box</mat-icon>
        Add user
      </button>
    </div>
  </div>
  <mat-table [dataSource]="users" #table>

    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef>Username</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="d-flex align-items-center justify-content-between">
          <mat-icon class="action-icon" (click)="editUser(row)">edit</mat-icon>
          <mat-icon class="action-icon" (click)="deleteUser(row)">delete</mat-icon>
          <span class="username">{{ row.username }}</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="roles">
      <mat-header-cell *matHeaderCellDef>Roles</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.roles.join(', ') }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tfa">
      <mat-header-cell *matHeaderCellDef>2FA</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button
          [disabled]="!row.is2FAEnabled"
          mat-raised-button
          color="accent"
          (click)="disable2Fa(row)">
          <mat-icon>do_not_disturb</mat-icon>
          Disable 2FA
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['username', 'roles', 'tfa']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['username', 'roles', 'tfa']"></mat-row>
  </mat-table>


  <mat-paginator
    #paginator
    (page)="handlePageEvent($event)"
    [length]="paging.total"
    [pageSize]="paging.limit"
    [pageIndex]="paging.page - 1"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
