<div class="sidebar-logo-container">
  <!--  *ngIf="showToggle"-->
  <button mat-icon-button (click)="toggleSidenav.emit()">
    <mat-icon *ngIf="!sidenavOpened; else toOpen">menu</mat-icon>
    <ng-template #toOpen>
      <mat-icon>chevron_left</mat-icon>
    </ng-template>

  </button>
  <img
    class="pointer sidebar-logo"
    ngSrc="./assets/brands/prod/sidebarLogo.png"
    alt="logo"
    (click)="showVersionDialog()"
    height="252" width="264"/>
</div>
