import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SourcesApi } from '@core/api/sources/api';
import {
  TConfluenceImport,
  TConfluencePage,
  TConfluencePaginated,
  TConfluencePaging,
  TConfluenceSpace,
} from '@core/api/sources/types';
import { Subject } from 'rxjs';
import {
  TConfluenceSteps
} from '../../../routes/components/gingr-new-items-modals/new-confluence-modal/new-confluence-folder-modal.component';
import { IGNORE_ERRORS_KEY } from '@core';
import { MetadataService, MetadataSlugs } from '../metadata.service';

export interface TConfluenceSettings {
  username: string;
  password: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfluenceService {
  private $pages = new Subject<TConfluencePaginated<TConfluencePage>>();
  private $spaces = new Subject<TConfluencePaginated<TConfluenceSpace>>();
  private $error = new Subject<boolean>();
  private $isLoading = new Subject<{ step: TConfluenceSteps; isLoading: boolean }>();
  public isLoadingObservable = this.$isLoading.asObservable();

  constructor(
    private _http: HttpClient,
    private _metadataService: MetadataService
  ) {
  }

  getSpaces(paging: TConfluencePaging) {
    this.$isLoading.next({ step: 'spaces', isLoading: true });
    const { url } = SourcesApi.getConfluenceSpaces(paging);
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    return this._http.get<TConfluencePaginated<TConfluenceSpace>>(url, { headers }).subscribe({
      next: (value) => {
        this.$error.next(false);
        this.$spaces.next(value);
        this.$isLoading.next({ step: 'spaces', isLoading: false });
      },
      error: () => {
        this.$error.next(true);
      }
    });
  }

  getPages(spaceKey: string, paging: TConfluencePaging) {
    this.$isLoading.next({ step: 'pages', isLoading: true });
    const { url } = SourcesApi.getConfluencePages(spaceKey, paging);
    this._http.get<TConfluencePaginated<TConfluencePage>>(url).subscribe(value => {
      this.$pages.next(value);
      this.$isLoading.next({ step: 'pages', isLoading: false });
    });
  }

  getSpacesAsObservable() {
    return this.$spaces.asObservable();
  }

  getErrorAsObservable() {
    return this.$error.asObservable();
  }

  getPagesAsObservable() {
    return this.$pages.asObservable();
  }

  importFromConfluence(data: TConfluenceImport) {
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    const { url, body } = SourcesApi.import(data);
    return this._http.post(url, body, { headers });
  }

  saveConfluenceSettings(confluenceSettings: TConfluenceSettings, callback: () => void) {
    this._metadataService
      .set(MetadataSlugs.ConfluenceSettings, JSON.stringify(confluenceSettings))
      .subscribe({
        next: () => {
          callback();
        },
      });
  }
}
