<div class="content">
  <div></div>
  <div>Current value</div>
  <div>Version’s value</div>
  <ng-container *ngFor="let field of compareFields">
    <div [ngClass]="{isDiff: isDiff(field)}">{{ field | firstUpper | sanitizeStr }}</div>
    <div class="value current">{{ getValue(current, field) }}</div>
    <div class="value">{{ getValue(version, field) }}</div>
  </ng-container>
</div>
<div class="buttons">
  <button
    mat-raised-button
    color="accent"
    (click)="onRevert()">
    Revert this version
  </button>
  <button
    class="m-l-16"
    mat-raised-button
    color="accent"
    (click)="onDelete()">
    Delete
  </button>
</div>
