<div class="bot-results" [class]="msg?.type ?? 'message'" [innerHTML]="text | noSanitize" #botResults></div>

<ng-container *ngFor="let paragraph of msg!.paragraphs">
  <ng-container *ngIf="paragraph.materials?.length">
    <div class="materials-container">
      <app-materials-preview
        *ngFor="let mat of paragraph.materials"
        [material]="mat"
      ></app-materials-preview>
    </div>
  </ng-container>
</ng-container>
<div class="files" *ngIf="(msg?.files?.length ?? 0) >= 1">
  <app-file-preview-modal
    *ngFor="let file of msg!.files"
    [file]="file"
  ></app-file-preview-modal>
</div>
