<div class="new-item-modal">
  <h3 class="mat-h3">Confirmation</h3>
  <div class="mat-description" [innerHTML]="data.text"></div>

  <div class="d-flex justify-content-center">
    <mat-progress-spinner
      *ngIf="isLoading"
      class="spinner"
      [color]="'accent'"
      [mode]="'indeterminate'"
      [value]="50">
    </mat-progress-spinner>
  </div>

  <div class="d-flex align-items-center justify-content-between m-t-16">
    <button
      mat-raised-button
      color="accent"
      [disabled]="isLoading"
      (click)="cancel()">
      Cancel
    </button>
    <button
      mat-raised-button
      color="accent"
      [disabled]="isLoading"
      (click)="confirm()">
      Yes, confirm
    </button>
  </div>
</div>
