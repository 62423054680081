<div class="new-item-modal">
    <h3 class="mat-h3">Add new folder</h3>
    <form class="form-field-full" [formGroup]="newFolderForm">
        <div class="form-inputs">
            <mat-form-field class="gingr-input-wrapper w-full">
                <input
                       matInput
                       placeholder="Folder name"
                       type="text"
                       formControlName="name"
                       required />
                <mat-error *ngIf="name.invalid">
                    <span *ngIf="name.errors?.required">Please enter folder name</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="gingr-input-wrapper w-full">
              <mat-select formControlName="status" placeholder="Select status">
                <mat-option *ngFor="let st of statuses" [value]="st">
                  {{ st | titlecase }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="status.invalid">
                <span *ngIf="status.errors?.required">Please provide status
                </span>
              </mat-error>
            </mat-form-field>

        </div>
        <button
                class=""
                mat-raised-button
                color="accent"
                [disabled]="newFolderForm.invalid"
                (click)="addNewItem()">
            Add new folder
        </button>
    </form>
</div>
