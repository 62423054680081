import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CountriesApi } from '@core/api/countries/api';

import {
  AddCountryBody,
  AddRegionBody,
  TCountry,
  TRegion,
  TRegionResponse,
} from '@core/api/countries/types';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private countries: BehaviorSubject<TCountry[]> = new BehaviorSubject<TCountry[]>([]);
  private regions: BehaviorSubject<TRegion[]> = new BehaviorSubject<TRegion[]>([]);

  constructor(private _http: HttpClient) {}

  public getCountriesObservable(): Observable<TCountry[]> {
    return this.countries.asObservable();
  }

  public getRegionsObservable(): Observable<TRegion[]> {
    return this.regions.asObservable();
  }

  public getCountriesAndRegionsObservable(): {
    countries: Observable<TCountry[]>;
    regions: Observable<TRegion[]>;
  } {
    return {
      countries: this.getCountriesObservable(),
      regions: this.getRegionsObservable(),
    };
  }

  public getCountriesAndRegions(): void {
    this.getCountries(() => this.getRegions());
  }

  public getCountries(callback?: () => void) {
    const { url } = CountriesApi.getCountries();
    const response = this._http.get<TCountry[]>(url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: resp => {
        this.countries.next(resp.sort((a, b) => a.name.localeCompare(b.name)));
        if (callback) {
          callback();
        }
      },
    });
  }

  public deleteCountry(id: string) {
    const { url } = CountriesApi.deleteCountry(id);
    const response = this._http.delete(url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        this.countries.next([...this.countries.value.filter(country => country._id !== id)]);
      },
    });
  }

  public addCountry(body: AddCountryBody) {
    const { url } = CountriesApi.addCountry();
    const response = this._http.post<TCountry>(url, body);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        this.countries.next(
          [...this.countries.value, response].sort((a, b) => a.name.localeCompare(b.name))
        );
      },
    });
  }

  public updateSourceCountries(sourceId: string, countryIds: string[]) {
    const { url, body } = CountriesApi.updateSourceCountry({ sourceId, countryIds });
    const response = this._http.post(url, body);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        console.log(response);
      },
    });
  }

  public getRegions() {
    const { url } = CountriesApi.getRegions();
    const response = this._http.get<TRegionResponse[]>(url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: resp => {
        const regions: TRegion[] = [];
        resp.forEach(region => {
          regions.push({ ...region, countries: this.mapRegionCountries(region) });
        });

        this.regions.next(regions.sort((a, b) => a.name.localeCompare(b.name)));
      },
    });
  }

  public addRegion(body: AddRegionBody) {
    const { url } = CountriesApi.addRegion();
    const response = this._http.post<TRegionResponse>(url, body);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        const region: TRegion = { ...response, countries: this.mapRegionCountries(response) };
        this.regions.next(
          [...this.regions.value, region].sort((a, b) => a.name.localeCompare(b.name))
        );
      },
    });
  }

  public deleteRegion(id: string) {
    const { url } = CountriesApi.deleteRegion(id);
    const response = this._http.delete(url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        this.regions.next([...this.regions.value.filter(region => region._id !== id)]);
      },
    });
  }

  private mapRegionCountries(region: TRegionResponse): TCountry[] {
    return region.countries.map(country => {
      return this.countries.value.find(c => c._id === country) as TCountry;
    });
  }
}
