import { ContentItemType } from '@core/interfaces/ContentItemType';

export const allowedToDeleteContentItems = [
  ContentItemType.Bots,
  ContentItemType.Projects,
  ContentItemType.Folders,
  ContentItemType.ResultConversation,
];

export const allowedToDisplayItemInfo = [ContentItemType.Bots, ContentItemType.Projects];
