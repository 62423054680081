import { ContentItemType } from '@core/interfaces/ContentItemType';
import { BotStatusTypes, TBotExtraConfigurations } from '@core/interfaces/TBot';
import { TBotItem, TBotItemResponse } from '@core/interfaces/TBotItem';
import { Icons } from 'app/enums/icons';

export const formatBot = (bot: TBotItemResponse): TBotItem => {
  return {
    __type: ContentItemType.Bots,
    botData: undefined,
    group: { id: '', name: '' },
    icon: Icons.BotEyeSmall,
    title: bot.name,
    _id: bot._id,
    type: bot.botType,
    botType: bot.botType,
    botModel: bot.botModel,
    behaviour: bot.behaviour,
    config: bot.config,
    instruction: bot.instruction,
    bot_status: bot.bot_status as BotStatusTypes,
    language: bot.language,
    description: bot.description,
    name: bot.name,
    botCreatorId: bot.botsCreator,
    promptHints: bot.promptHints,
    display_resources: bot.display_resources,
    showOnDashboard: bot.showOnDashboard,
    maximum_resources: bot.maximum_resources,
    maximum_publications: bot.maximum_publications,
    extraConfigurations: bot.extraConfigurations,
    preSearch: bot.preSearch,
  };
};
