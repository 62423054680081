import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { TProjectItem } from '@core/interfaces/TProjectItem';

import { NewProjectModalComponent } from '../components/gingr-new-items-modals/new-project-modal/new-project-modal.component';
import { ProjectsService } from '@shared/services/projects.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit, OnDestroy {
  public projects: TProjectItem[] = [];
  private _subs: Subscription[] = [];

  constructor(
    public newItemDialog: MatDialog,
    private _projectsService: ProjectsService
  ) {}

  public ngOnInit(): void {
    this._subs.push(
      this._projectsService.getProjectsObservable().subscribe(results => {
        this.projects = results;
      })
    );
    this._projectsService.setProjects();
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  public addNewItem(): void {
    this.newItemDialog.open(NewProjectModalComponent);
  }
}
