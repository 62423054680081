<div class="new-item-modal">
  <h3 class="mat-h3">Add new bot</h3>
  <form class="form-field-full" [formGroup]="newBotForm">
    <div class="form-inputs">
      <mat-form-field class="gingr-input-wrapper">
        <input matInput placeholder="Bot name" type="text" formControlName="name" required />
        <mat-error *ngIf="name.invalid">
          <span *ngIf="name.errors?.required">Please enter bot name</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="gingr-input-wrapper">
        <mat-select formControlName="botModel" placeholder="Select bot model">
          <mat-option *ngFor="let model of botsModels" [value]="model.id">
            {{ model.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="botModel.invalid">
          <span *ngIf="botModel.errors?.required">Please provide bot model</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="gingr-input-wrapper">
        <mat-select formControlName="botType" placeholder="Select bot type">
          <mat-option *ngFor="let type of botTypes" [value]="type.id">
            {{ type.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="botType.invalid">
          <span *ngIf="botType.errors?.required">Please provide bot type</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="gingr-input-wrapper" *ngIf="isPredefinedBotType()">
        <mat-select formControlName="status" placeholder="Select status">
          <mat-option *ngFor="let st of statuses" [value]="st">
            {{ st | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="status.invalid">
          <span *ngIf="status.errors?.required">Please provide status</span>
        </mat-error>
      </mat-form-field>

      <div *ngIf="botType.value === 'botCreator'">
        <mat-form-field class="gingr-input-wrapper">
          <mat-select formControlName="language" placeholder="Select language">
            <mat-option *ngFor="let language of languages" [value]="language.code">
              {{ language.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="language.invalid">
            <span *ngIf="language.errors?.required">Please enter language</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="gingr-input-wrapper">
          <input matInput placeholder="Description" type="text" formControlName="description" />
        </mat-form-field>

        <mat-form-field class="gingr-input-wrapper">
          <input matInput placeholder="Bot instruction" type="text" formControlName="instruction" />
        </mat-form-field>

        <mat-form-field class="gingr-input-wrapper">
          <input matInput placeholder="Bot behaviour" type="text" formControlName="behaviour" />
        </mat-form-field>
      </div>
    </div>
    <button
      class=""
      mat-raised-button
      color="accent"
      [disabled]="!!newBotForm.invalid"
      (click)="addNewItem()"
    >
      Add new bot
    </button>
  </form>
</div>
