import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input({ required: true }) url: string | undefined;

  goToUrl(event: any) {
    event.preventDefault();
    window.open(this.url, '_blank');
  }
}
