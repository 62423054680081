import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss'],
})
export class YoutubePlayerComponent {
  @Input({ required: true }) link: string | undefined;
  safeUrl: SafeResourceUrl | undefined;

  constructor(private sanitizer: DomSanitizer) {
  }

  ytLink(): SafeResourceUrl {
    const link = new URL(this.link!);
    const url = 'https://www.youtube.com/embed/' + link.searchParams.get('v');
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return this.safeUrl;
  }
}
