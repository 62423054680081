import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TProject } from '@core/interfaces/Endpoints';
import { TLanguage } from '@core/interfaces/TBot';
import { TBotItem } from '@core/interfaces/TBotItem';

import { TCountry, TRegion } from '@core/api/countries/types';
import { BotsService, TBotConfig } from '@shared/services/bots.service';
import { CountriesService } from '@shared/services/countries.service';
import { ProjectsService } from '@shared/services/projects.service';

import { BotConfigHistoryModalComponent } from '../../modals/bot-config-history-modal/bot-config-history-modal.component';
import { DuplicateBotModalComponent } from '../../modals/duplicate-bot-modal/duplicate-bot-modal.component';
import { PromptHintsModalComponent } from '../../modals/propmt-hints-modal/prompt-hints-modal.component';
import { TalkingAvatarService } from '../../../bots/talking-avatar/talking-avatar.service';

@Component({
  selector: 'app-bots-content',
  templateUrl: './bots-content.component.html',
  styleUrls: ['./bots-content.component.scss'],
})
export class BotsContentComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  private _selectedBot: TBotItem = {} as TBotItem;
  filterAreOpened = false;
  countrySearchTextInput = new FormControl('');
  @Output() public deleteItem = new EventEmitter<TBotItem>();
  @ViewChild('talkingHead', { read: ElementRef }) talkingHead: ElementRef | undefined;

  @Input() bots: TBotItem[] = [];

  @Input() set selectedBot(value: TBotItem) {
    this._selectedBot = value;
  }

  get selectedBot(): any {
    return this._selectedBot;
  }

  private _subs: Subscription[] = [];
  botConfig = { configurations: [], instructions: [] } as TBotConfig;
  isBotEditing = false;
  languages: TLanguage[] = [];
  availableProjects: TProject[] = [];
  formProjects: { name: string; _id: string }[] = [];
  countries: TCountry[] = [];
  regions: TRegion[] = [];
  conversationSizeContainer = 0;

  public botConfigForm = this.fb.nonNullable.group({
    _id: [''],
    language: [{ name: '', code: '' }, [Validators.required]],
    countries: new FormControl<string[]>([], { nonNullable: true }),
    start: [''],
    end: [''],
    display_resources: [true],
  });

  constructor(
    private fb: FormBuilder,
    private _botService: BotsService,
    private _projectService: ProjectsService,
    private route: ActivatedRoute,
    private _countriesService: CountriesService,
    private _dialog: MatDialog,
    protected cdr: ChangeDetectorRef,
    private _talkingAvatarService: TalkingAvatarService
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this._botService.getCurrentBotConfigObservable().subscribe(results => {
        this.botConfig = results;
      })
    );
    this._subs.push(
      this._botService.getLanguagesObservable().subscribe(res => {
        this.languages = res;
        this.updateSelectedLanguage();
      })
    );
    this._subs.push(
      this._projectService.getProjectsObservable().subscribe(results => {
        if (results.length === 0) {
          this._projectService.setProjects();
        }
        this.availableProjects = results;
        this.formProjects = results.map(project => ({
          name: project.name,
          _id: project._id as string,
        }));
      })
    );

    this._countriesService.getCountriesAndRegions();
    const { countries, regions } = this._countriesService.getCountriesAndRegionsObservable();
    this._subs.push(countries.subscribe(c => (this.countries = c)));
    this._subs.push(regions.subscribe(r => (this.regions = r)));

    this._botService.fetchLanguages();
    this.botConfigForm.valueChanges.subscribe(
      (formData: Partial<{ language: { name: string; code: string } }>) => {
        if (formData.language?.code !== this.selectedBot.language) {
          this._botService.editBot(
            {
              id: this.selectedBot._id,
              language: this.botConfigForm.value.language?.code,
            },
            () => (this.selectedBot.language = this.botConfigForm.value.language?.code)
          );
        }
      }
    );
    if (this.route.children[0].params) {
      this._subs.push(
        this.route.children[0].params.subscribe(params => {
          if (params.id) {
            const bot = this.bots.find(item => item._id === params.id);
            if (bot && bot._id !== this.selectedBot._id) {
              this.selectedBot = bot;
            }
          }
        })
      );
    }

    window.addEventListener('resize', this.updateConversationSizeContainer.bind(this));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateConversationSizeContainer();
    }, 0);
  }

  private updateConversationSizeContainer(): void {
    try {
      const scaleFix = window.innerWidth < 1700 ? 200 : 0;
      this.conversationSizeContainer =
        (window.innerHeight - this.talkingHead?.nativeElement.offsetHeight - 200 ?? 0) + scaleFix;
    } catch (e) {
      this.conversationSizeContainer = 0;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedBot &&
      changes.selectedBot.previousValue !== changes.selectedBot.currentValue
    ) {
      this.isBotEditing = false;
      this.updateSelectedLanguage();
      this.resetCountries();
      this._talkingAvatarService.setSettings(this.selectedBot).catch(() => {});
      this.route.queryParams.subscribe(params => {
        this.botConfigForm.patchValue({
          _id: params.projectId,
        });
      });
    }
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
    window.removeEventListener('resize', this.updateConversationSizeContainer.bind(this));
  }

  public getCountriesAndRegions(): (TCountry | TRegion)[] {
    return [...this.regions, ...this.countries];
  }

  updateSelectedLanguage(): void {
    if (!this.selectedBot.language) {
      this.botConfigForm.controls.language.setValue(this.languages[0]);
    } else {
      this.botConfigForm.controls.language.setValue(
        this.languages.find(lang => lang.code === this.selectedBot.language) as TLanguage
      );
    }
  }

  public edit(): void {
    this.isBotEditing = true;
  }

  protected delete(): void {
    this.deleteItem.emit(this._selectedBot);
  }

  protected duplicate(): void {
    this._dialog.open(DuplicateBotModalComponent, { data: { bot: this._selectedBot } });
  }

  protected showHistoryModal() {
    this._dialog.open(BotConfigHistoryModalComponent, { data: { bot: this._selectedBot } });
  }

  protected showPromptHintsModal() {
    this._dialog.open(PromptHintsModalComponent, { data: { bot: this._selectedBot } });
  }

  public closeBotEdit(): void {
    this.isBotEditing = false;
  }

  public get selectedProjectId() {
    return this.botConfigForm.get('_id');
  }

  public get selectedCountries() {
    return this.botConfigForm.get('countries');
  }

  public get dateStart() {
    return this.botConfigForm.get('start');
  }

  public get dateEnd() {
    return this.botConfigForm.get('end');
  }

  protected resetCountrySearch() {
    this.countrySearchTextInput.patchValue('');
  }

  protected resetCountries() {
    this.botConfigForm.patchValue({ countries: [] });
  }

  protected get shouldFiltersBeVisible() {
    return this.showFiltersForCertainBot || this.filterAreOpened;
  }
  protected get showFiltersForCertainBot(){
    return ['compareBot', 'summaryBot'].includes(this.selectedBot.botType);
  }
}
