import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpeechRecognitionService {
  private recognition: any;
  public isListening = false;
  public transcript = '';
  public transcript$ = new BehaviorSubject<string>('');
  private silenceTimeout: any;
  private readonly silenceTimeoutDuration = 2000; // 2 seconds
  timeoutEvent = new EventEmitter<boolean>();

  constructor(private zone: NgZone) {
    const { webkitSpeechRecognition }: any = window;
    this.recognition = new webkitSpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.recognition.lang = navigator.language || 'en-US';

    this.recognition.onresult = (event: any) => {
      this.zone.run(() => {
        this.transcript = Array.from(event.results)
          .map((result: any) => result[0].transcript)
          .join('');
        this.transcript$.next(this.transcript);
        this.resetSilenceTimeout();
      });
    };

    this.recognition.onerror = (event: any) => {
      console.error(event.error);
    };

    this.recognition.onend = () => {
      this.isListening = false;
      clearTimeout(this.silenceTimeout);
    };
  }

  setLanguage(language: string): void {
    this.recognition.lang = language;
  }

  startListening(): void {
    if (!this.isListening) {
      this.recognition.start();
      this.isListening = true;
      this.resetSilenceTimeout();
    }
  }

  stopListening(): void {
    if (this.isListening) {
      this.recognition.stop();
      this.isListening = false;
      clearTimeout(this.silenceTimeout);
      this.timeoutEvent.emit(true);
    }
  }

  private resetSilenceTimeout(): void {
    clearTimeout(this.silenceTimeout);
    this.silenceTimeout = setTimeout(() => {
      this.stopListening();
    }, this.silenceTimeoutDuration);
  }
}
