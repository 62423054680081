import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PreviewModalComponent } from '@shared/components/file-preview-modal/preview-modal/preview-modal.component';
import { TReturnedFileData } from '@shared/services/bots/types/types';

@Component({
  selector: 'app-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.scss'],
})
export class FilePreviewModalComponent {
  constructor(private _dialog: MatDialog) {}

  @Input() file: TReturnedFileData | undefined;

  openModal() {
    this._dialog.open(PreviewModalComponent, {
      width: '90vw',
      data: {
        filename: this.file?.name,
      },
    });
  }
}
