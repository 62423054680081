import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SourceMaterialsService } from '@shared/services/source-materials.service';
import {
  MaterialTypeInput,
  sourceMaterialsFiles,
  SourceMaterialStatus,
  TMaterialSourceFolder,
  TSourceMaterial,
  TSourceMaterialFileTypes,
} from '@core/api/materials/types';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '@shared/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sources-materials-form',
  templateUrl: './sources-materials-form.component.html',
  styleUrls: ['./sources-materials-form.component.scss'],
})
export class SourcesMaterialsFormComponent implements OnInit, OnDestroy {
  @Output() closeForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input({ required: true }) set selectedMaterial(item: TSourceMaterial | null) {
    if (item) {
      this.selectedItem = item!;
      this.updateForm(item);
      this.updateValidators();
    }
  }

  @Input({ required: true }) folder: TMaterialSourceFolder | undefined;

  protected readonly Object = Object;
  protected readonly SourceMaterialStatus = SourceMaterialStatus;
  protected selectedItem: TSourceMaterial | undefined;
  protected _subs: Subscription[] = [];

  public materialForm = this._fb.nonNullable.group({
    name: ['', [Validators.required, Validators.minLength(3)]],
    file: [''],
    keywords: ['', [Validators.required, Validators.minLength(3)]],
    content: [''],
    url: [''],
    status: [SourceMaterialStatus.Active, [Validators.required]],
    fileType: [''],
  });
  protected allowedFileTypes: TSourceMaterialFileTypes[] = [];
  protected isLoading = false;
  protected isProcessing = false;

  constructor(
    protected sourceMaterialsService: SourceMaterialsService,
    private _commonServices: CommonService,
    private _fb: FormBuilder,
  ) {
  }

  protected get isEditMode() {
    return !!this.selectedItem?._id;
  }

  ngOnInit() {
    this.isLoading = true;
    this._subs.push(this.sourceMaterialsService.getFileTypes().subscribe(types => {
        this.allowedFileTypes = types;
        this.isLoading = false;
      }),
    );
  }

  private updateForm(data: TSourceMaterial) {
    this.materialForm.patchValue({
      name: data?.name,
      url: data?.url,
      keywords: data?.keywords,
      content: data?.content,
      status: data?.status ?? SourceMaterialStatus.Active,
      fileType: data?.fileType,
    });
  }

  private updateValidators() {
    if (this.isEditMode) {
      this.materialForm.get('file')!.clearValidators();
      this.materialForm.get('fileType')!.clearValidators();
    } else {
      this.materialForm.get('file')!.setValidators([Validators.required]);
      this.materialForm.get('fileType')!.setValidators([Validators.required]);
    }
    this.materialForm.get('file')!.updateValueAndValidity();
    this.materialForm.get('fileType')!.updateValueAndValidity();
  }

  protected onSubmit() {
    this.processMaterial(this.isEditMode);
  }

  private processMaterial(isUpdate: boolean) {
    this.toggleFormDisable(true);
    const action = isUpdate
      ? this.sourceMaterialsService.edit(this.selectedItem!._id, this.materialForm.value as TSourceMaterial)
      : this.sourceMaterialsService.create(this.getFormData());

    const subscription = action.subscribe(value => {
      if (isUpdate) {
        Object.assign(this.selectedItem!, value);
      }
      this._commonServices.openSnackBar(isUpdate ? 'Updated successfully' : 'Created successfully');
      subscription.unsubscribe();
      this.toggleFormDisable(false);
      this.closeForm.emit(true);
    });
  }

  private getFormData(): FormData {
    const data = { ...this.materialForm.value };
    const formData = new FormData();

    if (!sourceMaterialsFiles.includes(data.fileType!)) {
      data.url = data.file;
      delete data.file;
    }

    Object.entries(data).forEach(([field, value]) => {
      if (field === 'file') {
        formData.append('file', this.materialForm.value.file![0]);
      } else {
        formData.append(field, value);
      }
    });

    formData.append('folder', this.folder!._id!);

    return formData;
  }

  toggleFormDisable(disable: boolean) {
    this.isProcessing = disable;
    if (disable) {
      this.materialForm.disable();
    } else {
      this.materialForm.enable();
    }
  }

  get fileType(): MaterialTypeInput {
    return this.materialForm.value.fileType as MaterialTypeInput;
  }

  ngOnDestroy() {
    this._subs.forEach(sub => sub.unsubscribe());
  }

}

