import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { DateRange } from 'app/enums/date-range';
import { Subscription } from 'rxjs';

import { ContentItemType } from '@core/interfaces/ContentItemType';
import { BotTypeId } from '@core/interfaces/TBot';
import { TCurrentConversation, TResultsBotItem } from '@core/interfaces/TResultsItem';

import { BotResultsAdapter } from '@shared/services/bots/adapters/BotResultsAdapter';
import { BotMessagesService } from '@shared/services/bots/bot-messages.service';
import { ResultsService } from '@shared/services/results.service';
import { getDateRange } from '@shared/utils/date';
import { getUnique } from '@shared/utils/getUnique';
import { resultsConversationRegex } from '@shared/utils/routing';
import { NewConversationModalComponent } from '../components/gingr-new-items-modals/new-conversation-modal/new-conversation-modal.component';
import { ConfirmationModalComponent } from '../components/modals/confirmation-modal/confirmation-modal.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

export interface TGroup<T> {
  name: string;
  groupId: string;
  groupItems: T[];
  isOpened: boolean;
  favorites?: T[];
  todayItems?: T[];
  yesterdayItems?: T[];
  last7DaysItems?: T[];
  last30DaysItems?: T[];
  olderItems?: T[];
  botType: BotTypeId;
}

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit, OnDestroy {
  public conversations: TResultsBotItem[] = [];
  private _subs: Subscription[] = [];
  public drawerOpened = true;
  public isBotSelected = false;
  public canSelectResults = false;
  public canDeleteResults = false;
  public groups: TGroup<TResultsBotItem>[] = [];
  public resultsLoading = false;
  public itemsSelection: TResultsBotItem[] = [];
  public groupsSelected: { [key: string]: boolean } = {
    favorites: false,
    todayItems: false,
    yesterdayItems: false,
    last7DaysItems: false,
    last30DaysItems: false,
    olderItems: false,
  };

  public selectedItem: TCurrentConversation | TGroup<TCurrentConversation> | null | any = null;
  public selectedItemBotType: BotTypeId | null = null;

  constructor(
    public newItemDialog: MatDialog,
    private _resultsService: ResultsService,
    public dialog: MatDialog,
    private router: Router,
    private _botMessageService: BotMessagesService
  ) {
    this._subs.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd || event instanceof Scroll) {
          this.goToResultByUrl();
        }
      })
    );
    this._botMessageService.setAdapter(new BotResultsAdapter());
  }

  private goToResultByUrl = (): void => {
    const pathMatch = this.router.url.match(resultsConversationRegex);

    if (pathMatch) {
      const conversationId = pathMatch[1];
      if (!this.selectedItem?._id || this.selectedItem?._id !== conversationId) {
        this._resultsService.fetchResultConversation(conversationId, res => {
          this.setResultsToComponent(res);
          this.selectedItem = res;
        });
      }
    }
  };

  protected setResultsToComponent(conversation: TCurrentConversation): void {
    this._botMessageService.isLoading = true;
    this._resultsService.fetchResultConversation(conversation._id, res => {
      this._botMessageService.appendMessageViaAdapter(res);
      this._botMessageService.isLoading = false;
    });
  }

  public ngOnInit(): void {
    this._subs.push(
      this._resultsService.getResultObservable().subscribe(result => {
        this.conversations = result;
        this.groups = this._getGroups();
      })
    );
    this._subs.push(
      this._resultsService.getCurrentConversationObservable().subscribe(result => {
        if (result && this.selectedItem && result?._id === this.selectedItem?._id) {
          this.selectedItem = result;
        }
      })
    );
    this._subs.push(
      this._resultsService.getResultsLoadingObservable().subscribe(result => {
        this.resultsLoading = result;
      })
    );
    this._resultsService.fetchResultsByBot();
  }

  public addNewItem(): void {
    this.newItemDialog.open(NewConversationModalComponent);
  }

  private _getGroupsIds(): (string | undefined)[] {
    return getUnique(this.conversations.map(it => it.group?.id).filter(i => i)) || [];
  }

  public setSelectedGroup(groupName: string): void {
    const group = this.conversations.find(c => c.title === groupName) as TResultsBotItem;
    this.router.navigateByUrl(`/bots/${group._id}`);
  }

  public toggleGroup(group: TGroup<TResultsBotItem>): void {
    group.isOpened = !group.isOpened;
  }

  public toggleSelectAll(): void {
    this.canSelectResults = !this.canSelectResults;
  }

  public openDeleteMultipleModal(): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        text: `Are you sure you want remove the selected results?`,
      },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      const ids = this.itemsSelection.map(i => i._id as string);
      this._resultsService.deleteMultiple(ids);
      dialogRef.close();
      this.itemsSelection = [];
      dialogSubscription.unsubscribe();
    });
  }

  public toggleStarConversationCallback(cId: string): void {
    this.groups = this._updateFavorites(cId);
  }

  // TODO: fix type
  public selectItem(item: any, botType: BotTypeId): void {
    // if (item.__type === ContentItemType.ResultConversation) {
    //   this._resultsService.fetchResultConversation(item._id as string);
    // }
    this.selectedItem = item;
    this.setResultsToComponent(item);
    this.selectedItemBotType = botType;
    this.isBotSelected = Boolean(this.selectedItem.group);
    const navigationRoute =
      item.__type === ContentItemType.ResultConversation ? '/results/conversation' : '/results';
    this.router.navigateByUrl(`${navigationRoute}/${item._id}`);
  }

  public afterDeleteItem(deletedItemId: string): void {
    if (this.selectedItem._id === deletedItemId) {
      this.selectedItem = null;
      this.router.navigateByUrl(`/results`);
    }
  }

  public toggleSelectedItem(item: TResultsBotItem): void {
    const found = this.itemsSelection.find(i => i._id === item._id);
    if (found) {
      this.itemsSelection = this.itemsSelection.filter(i => i._id !== item._id);
    } else {
      this.itemsSelection.push(item);
    }
  }

  public isIndeterminate(group: TResultsBotItem[]): boolean {
    if (this.itemsSelection.length === 0) {
      return false;
    }
    if (group.length === 0) {
      return false;
    }
    const groupIds = group.map(i => i._id);
    const selectedGroupItemsCount = this.itemsSelection.filter(i =>
      groupIds.includes(i._id)
    ).length;
    return selectedGroupItemsCount > 0 && selectedGroupItemsCount < group.length;
  }

  public getNestedListCSS(group: TGroup<TResultsBotItem>): string {
    const classes: string[] = [`gingr-content__drawer-content--nested`];
    if (!group.isOpened) {
      classes.push(`gingr-content__drawer-content--nested--hidden`);
    }
    return classes.join(` `);
  }

  public toggleDrawer(): void {
    this.drawerOpened = !this.drawerOpened;
  }

  public toggleSelectGroup(event: MatCheckboxChange, group: TResultsBotItem[]): void {
    if (event.checked) {
      this.itemsSelection = [...this.itemsSelection, ...group];
    } else {
      const groupsIds = group.map(item => item._id);
      this.itemsSelection = this.itemsSelection.filter(item => !groupsIds.includes(item._id));
    }
  }

  private _getGroups(): TGroup<TResultsBotItem>[] {
    const groups: (string | undefined)[] = this._getGroupsIds();
    return groups?.map(groupId => {
      const items: TResultsBotItem[] = this.conversations.filter(
        item => item.group?.id === groupId
      );
      const children = items.map(i => i.children)[0];
      const groupItems = children?.map(child => {
        return {
          title: child.messages[0]?.text,
          __type: ContentItemType.ResultConversation,
          dateRange: child.date ? getDateRange(child.date) : null,
          ...child,
        };
      });
      return {
        groupId: groupId || '',
        name: items[0].group?.name || '',
        ...this._setGroupItems(groupItems),
        isOpened: false,
        botType: items[0].botType as BotTypeId,
      };
    });
  }

  private _updateFavorites(updatedConversationId: string): TGroup<TResultsBotItem>[] {
    return this.groups.map(group => {
      const groupItems = group.groupItems.map(child => {
        if (child._id === updatedConversationId) {
          child.favorite = !child.favorite;
        }
        return child;
      });
      return {
        ...group,
        ...this._setGroupItems(groupItems),
      };
    });
  }

  private _setGroupItems(groupItems: TResultsBotItem[] | undefined) {
    return {
      groupItems: groupItems || [],
      favorites: groupItems?.filter(item => this._isFavorite(item)),
      todayItems: groupItems?.filter(
        item => item.dateRange === DateRange.Today && !this._isFavorite(item)
      ),
      yesterdayItems: groupItems?.filter(
        item => item.dateRange === DateRange.Yesterday && !this._isFavorite(item)
      ),
      last7DaysItems: groupItems?.filter(
        item => item.dateRange === DateRange.Previous7Days && !this._isFavorite(item)
      ),
      last30DaysItems: groupItems?.filter(
        item => item.dateRange === DateRange.Previous30Days && !this._isFavorite(item)
      ),
      olderItems: groupItems?.filter(
        item => item.dateRange === DateRange.Older && !this._isFavorite(item)
      ),
    };
  }

  private _isFavorite(item: TResultsBotItem): boolean {
    return item.favorite || item.haveFavoriteMessage;
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
