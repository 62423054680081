import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GlobalInstructionsApi } from '@core/api/global-instructions/api';

import { TGlobalInstruction } from '@core/api/global-instructions/types';

@Injectable({
  providedIn: 'root',
})
export class GlobalInstructionsService {

  constructor(private _http: HttpClient) {
  }

  fetch(): Observable<TGlobalInstruction[]> {
    return this._http.get<TGlobalInstruction[]>(GlobalInstructionsApi.fetch().url);
  }

  get(id: string): Observable<TGlobalInstruction> {
    return this._http.get<TGlobalInstruction>(GlobalInstructionsApi.get(id).url);
  }

  create(instruction: TGlobalInstruction): Observable<TGlobalInstruction> {
    const { url, body } = GlobalInstructionsApi.create(instruction);
    return this._http.post<TGlobalInstruction>(url, body);
  }

  update(instruction: TGlobalInstruction): Observable<TGlobalInstruction> {
    const { url, body } = GlobalInstructionsApi.update(instruction);
    return this._http.put<TGlobalInstruction>(url, body);
  }

  delete(id: string) {
    return this._http.delete(GlobalInstructionsApi.delete(id).url);
  }

}
