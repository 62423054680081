import { Injectable, OnDestroy } from '@angular/core';
import { MetadataService, MetadataSlugs } from '@shared/services/metadata.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppGlobalSettings } from '@shared/services/settings/types';
import { T } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService  {
  private settings: AppGlobalSettings = {};
  private settingsSubject: BehaviorSubject<AppGlobalSettings> = new BehaviorSubject<AppGlobalSettings>(this.settings);

  constructor(
    private _metadataService: MetadataService,
  ) {
    const masterBotStatusSub = this._metadataService.get<string>(MetadataSlugs.MasterBotStatus).subscribe(value => {
      this.setSetting('master_bot_status', value.value);
      masterBotStatusSub.unsubscribe();
    });
    const twoFactoSub = this._metadataService.get<boolean>(MetadataSlugs.Is2FaRequired).subscribe(value => {
      this.setSetting('two_factor_auth_required', value.value);
      twoFactoSub.unsubscribe();
    });
  }

  getSettingsAsObservable() {
    return this.settingsSubject.asObservable();
  }

  getSettingsValue() {
    return this.settingsSubject.getValue();
  }

  storeSettingMetadata<T extends keyof AppGlobalSettings>(key: T, value: AppGlobalSettings[T], callback?: () => void) {
    const sub = this._metadataService.set(key as MetadataSlugs, <string|null>value ?? null).subscribe(() => {
      this.setSetting(key, value);
      sub.unsubscribe();
      callback?.();
    });
  }

  protected setSetting<T extends keyof AppGlobalSettings>(key: T, value: AppGlobalSettings[T]) {
    this.settings[key] = value;
    this.settingsSubject.next(this.settings);
  }

}
