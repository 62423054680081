import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { SourceMaterialStatus, TMaterialSourceFolder } from '@core/api/materials/types';
import { SourceMaterialsService } from '@shared/services/source-materials.service';


@Component({
  selector: 'app-source-folder-modal',
  templateUrl: './source-folder-modal.component.html',
  styleUrls: ['./source-folder-modal.component.scss'],
})
export class SourceFolderModalComponent {
  @Output() folderChanged = new EventEmitter<TMaterialSourceFolder>();
  folder: TMaterialSourceFolder | undefined;

  protected isLoading = false;
  folderForm = this.fb.nonNullable.group(
    {
      name: ['', [Validators.required, Validators.minLength(3)]],
    },
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TMaterialSourceFolder,
    private materialService: SourceMaterialsService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SourceFolderModalComponent>,
  ) {
    this.folderForm.patchValue({ name: this.data?.name });
    this.folder = this.data;
  }


  confirm(): void {
    this.isLoading = true;

    if (this.data) {
      this.updateFolder();
    } else {
      this.createNewFolder();
    }
  }

  updateFolder() {
    const data: TMaterialSourceFolder = { ...this.folder! };
    data.name = this.folderForm.value.name!;
    this.materialService.updateFolder(
      data, () => {
        this.isLoading = false;
        this.dialogRef.close();
        this.folderChanged.emit(data);
      });
  }

  private createNewFolder() {
    this.materialService.createFolder({
      name: this.folderForm.value.name!,
      status: SourceMaterialStatus.Active,
    }, folder => {
      this.isLoading = false;
      this.dialogRef.close();
      this.folderChanged.emit(folder);
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
