<div class="modal">
  <form class="form-field-full" [formGroup]="instructionsForm">
    <div>
      <div class="m-l-0 custom-label">Name <span class="asterisk">*</span></div>
      <mat-form-field class="gingr-input-wrapper w-full">
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>
    <div>
      <div class="m-l-0 custom-label">Content <span class="asterisk">*</span></div>
      <mat-form-field class="gingr-input-wrapper w-full">
        <input matInput formControlName="content" />
      </mat-form-field>
    </div>
    <div>
      <div class="m-l-0 custom-label">Keywords <span class="asterisk">*</span></div>
      <mat-form-field class="gingr-input-wrapper w-full">
        <input matInput formControlName="keywords" />
      </mat-form-field>
    </div>

    <div>

      <div class="m-l-0 custom-label">Keywords <span class="asterisk">*</span></div>
      <mat-form-field class="gingr-input-wrapper w-full">
        <mat-select formControlName="status" placeholder="Select status">
          <mat-option *ngFor="let status of globalInstructionsStatuses" [value]="status">
            {{ status | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="d-flex align-items-center justify-content-end m-t-48 controls">
      <button mat-raised-button class="m-r-24 secondary-button"
              color="primary"
              (click)="cancel()"
              [disabled]="isLoading"
      >Cancel
      </button>
      <button
        mat-raised-button
        color="accent"
        name="submit"
        [disabled]="instructionsForm.invalid || isLoading"
        type="submit"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </form>
</div>
