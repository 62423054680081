<div class="new-item-modal">
  <h2 class="mat-h2 m-b-24">Confluence import</h2>
  <div *ngIf="!errorWhenFetching; else errorWhenFetchingInfo">

    <div *ngIf="step === 'select'">
      <div class="columns">

        <div>
          <h3 class="mat-h3">Spaces</h3>
          <div class="list-container">
            <app-spinner *ngIf="isProcessing.spaces" [message]="''" [small]="true"></app-spinner>
            <div *ngIf="!isProcessing.spaces">
              <p class="no-results" *ngIf="spaces?.results?.length === 0 && !isProcessing.spaces">No spaces</p>
              <div class="item"
                   *ngFor="let space of spaces?.results"
                   [ngClass]="{active: selectedSpace?.key === space.key}"
                   (click)="selectSpace(space)">
                <span>{{ space.name }}</span>
                <app-counter-badge [tick]="isWholeSpaceSelected(space)"
                                   [number]="countSelectedItems(space)"></app-counter-badge>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <button (click)="toggleSelectWholeSpace()"
                    [disabled]="!selectedSpace || pages?.results?.length === 0 || isProcessing.pages" color="accent"
                    mat-mini-fab
                    aria-label="Next">
              <mat-icon>done_all</mat-icon>
            </button>
            <app-simple-paginator
              (navChanged)="handlePageNavigationEvent('spaces', $event)"
              [disable]="{ next: !!spaces?._links?.next, prev: !!spaces?._links?.prev }"
            ></app-simple-paginator>
          </div>
        </div>

        <div>
          <h3 class="mat-h3">Pages</h3>
          <div class="list-container" [ngClass]="{ disabled: isWholeSpaceSelected(selectedSpace!) }">
            <app-spinner *ngIf="isProcessing.pages" [message]="''" [small]="true"></app-spinner>
            <div *ngIf="!isProcessing.pages">
              <p class="no-results" *ngIf="pages === null">Space not selected</p>
              <p class="no-results" *ngIf="pages?.results?.length === 0 && !isProcessing.pages">No pages</p>
              <p class="item" [ngClass]="{ active: isPageSelected(page) }" (click)="togglePageSelection(page)"
                 *ngFor="let page of pages?.results">{{ page.title }}</p>
            </div>
          </div>
          <app-simple-paginator
            (navChanged)="handlePageNavigationEvent('pages', $event)"
            [disable]="{ next: !!pages?._links?.next, prev: !!pages?._links?.prev }"
          ></app-simple-paginator>
        </div>
      </div>
      <div>
        <h3 class="mat-h3 m-t-24">Selected items</h3>
        <div class="list-container list-container__selected">
          <p class="no-results" *ngIf="selected.pages.length === 0 && selected.spaces.length === 0">No items
            selected</p>
          <div *ngFor="let item of selected.spaces" class="selected-item-list">
            <mat-icon (click)="unselectSpace(item)">delete</mat-icon>
            <p class="item"><strong>{{ item.name }} </strong></p>
          </div>
          <div *ngFor="let item of selected.pages" class="selected-item-list">
            <mat-icon (click)="unselectPage(item)">delete</mat-icon>
            <p class="item"><strong>{{ item.space }} </strong>&nbsp;- {{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="step === 'create_folder'">
      <app-source-folder-form [isDisabled]="isProcessing.posting"
                              (formChange)="newFolderData = $event"></app-source-folder-form>
    </div>
  </div>

  <ng-template #errorWhenFetchingInfo>
    <p>
      Can't connect to the Confluence API. Go to <span class="link" (click)="goToSettings()">API Settings</span> and
      verify credentials.</p>
  </ng-template>

  <div class="d-flex justify-content-between">
    <div>
      <button
        mat-raised-button
        color="accent"
        [disabled]="isProcessing.posting"
        (click)="dialogRef.close()"
      >
        Cancel
      </button>
      <button
        *ngIf="step === 'create_folder'"
        mat-raised-button
        color="accent"
        (click)="step = 'select'"
        [disabled]="isProcessing.posting"
      >
        Previous
      </button>
    </div>
    <div>
      <button
        style="align-self: flex-end"
        [disabled]="isNextDisabled()"
        mat-raised-button
        color="accent"
        (click)="goNext()"
      >
        Next
      </button>
    </div>
  </div>

</div>
