<h3 class="p-24 p-l-32 mat-h3">General app settings</h3>
<div class="p-24 p-t-0 d-flex flex-col w-full">
  <mat-tab-group mat-stretch-tabs="false" color="accent" class="settings">
    <mat-tab label="Countries">
      <ng-template matTabContent>
        <app-countries-allowed></app-countries-allowed>
      </ng-template>
    </mat-tab>
    <mat-tab label="Global Static Instruction">
      <ng-template matTabContent>
        <app-global-vocabulary-rules></app-global-vocabulary-rules>
      </ng-template>
    </mat-tab>
    <mat-tab label="Global Dynamic Intructions">
      <ng-template matTabContent>
        <app-global-instructions></app-global-instructions>
      </ng-template>
    </mat-tab>
    <mat-tab label="Dashboard content">
      <ng-template matTabContent>
        <app-dashboard-content></app-dashboard-content>
      </ng-template>
    </mat-tab>
    <mat-tab label="Version">
      <ng-template matTabContent>
        <app-version></app-version>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
