import { environment } from '@env/environment';
import { DeleteRequestData, GetRequestData } from '@core/api/utils';

export class FileStorageApi {
  static readonly apiUrl = environment.backendURL + '/api/fileStorage';

  static upload(file: File): { formData: FormData; url: string } {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return {
      url: `${this.apiUrl}/upload`,
      formData,
    };
  }

  static listFiles(): GetRequestData {
    return {
      url: `${this.apiUrl}`,
    };
  }

  static download(fileKey: string): GetRequestData {
    return {
      url: `${this.apiUrl}/download/${fileKey}`,
    };
  }

  static checkFileExists(fileKey: string): GetRequestData {
    return {
      url: `${this.apiUrl}/exists/${fileKey}`,
    };
  }

  static getFileMetadata(fileKey: string): GetRequestData {
    return {
      url: `${this.apiUrl}/metadata/${fileKey}`,
    };
  }

  static removeFile(fileKey: string): DeleteRequestData {
    return {
      url: `${this.apiUrl}/${fileKey}`,
    };
  }
}
