<div class="new-item-modal">
  <h3 class="mat-h3">Create new material folder</h3>
  <form [formGroup]="folderForm" (ngSubmit)="confirm()">
    <div>
      <mat-form-field class="gingr-input-wrapper w-full">
        <input matInput formControlName="name" placeholder="Folder name" />
      </mat-form-field>
    </div>
    <div class="d-flex align-items-center justify-content-between m-t-8">
      <button
        [disabled]="isLoading"
        mat-raised-button
        color="accent"
        type="button"
        (click)="cancel()">
        Cancel
      </button>
      <button
        [disabled]="isLoading || !folderForm.valid"
        mat-raised-button
        color="accent">
        Save
      </button>
    </div>
  </form>
</div>
