import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { INITIAL_PAGING, PAGE_SIZES, TPaging } from '@core/interfaces/TPaging';
import { Subscription } from 'rxjs';

import { TFolderItem } from '@core/interfaces/TFolderItem';
import { TSourceItem } from '@core/interfaces/TSourceItem';

import { CommonService } from '@shared/services/common.service';
import { FoldersService } from '@shared/services/folders.service';

import { EditSourceFolderModalComponent } from '../../modals/edit-source-folder-modal/edit-source-folder-modal.component';
import { SourceSortField } from '@core/interfaces/TSources';
import { SortDirection } from '@angular/material/sort';
import { NewSourceModalComponent } from '../../gingr-new-items-modals/new-source-modal/new-source-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-folders-content',
  templateUrl: './folders-content.component.html',
  styleUrls: ['./folders-content.component.scss'],
})
export class FoldersContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public selectedItem: TFolderItem = {} as TFolderItem;
  @Input() public returnedFromSource = false;
  private _subs: Subscription[] = [];
  folderSources: TSourceItem[] = [];
  isLoading = false;
  paging: TPaging = {} as TPaging;
  sorting: { sortField: SourceSortField; sortDirection: SortDirection } = {
    sortField: 'name',
    sortDirection: 'asc',
  };

  @Output() public sourceSelected: EventEmitter<TSourceItem> = new EventEmitter<TSourceItem>();

  constructor(
    private _commonService: CommonService,
    private _folderService: FoldersService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this._folderService.getFoldersObservable().subscribe(res => {
        const newFolderData: TFolderItem | undefined = res.find(
          it => it._id === this.selectedItem._id
        );
        if (newFolderData) {
          this.selectedItem = newFolderData;
        }
      })
    );
    this._subs.push(
      this._commonService.getIsLoadingObservable().subscribe(res => {
        this.isLoading = res;
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItem?.previousValue !== changes.selectedItem?.currentValue) {
      if (!this.returnedFromSource) {
        this._folderService.setFolderSourcesPaging(INITIAL_PAGING);
      }

      this._subs.push(
        this._folderService.getFolderSourcesPagingObservable().subscribe(res => {
          this.paging = res;
        })
      );
      this.getFolderSources();
      this._subs.push(
        this._folderService.getFolderSourcesObservable().subscribe(res => {
          this.folderSources = res;
        })
      );
    }
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  handleSourceSelected(source: TSourceItem): void {
    this.sourceSelected.emit(source);
  }

  public openEditModal(): void {
    this.dialog.open(EditSourceFolderModalComponent, {
      data: { source: this.selectedItem },
    });
  }

  handleSortChange(sortState: { sortField: SourceSortField; sortDirection: SortDirection }): void {
    this.sorting = sortState;
    this.getFolderSources();
  }

  handlePageChange(pagingState: TPaging): void {
    this.paging = pagingState;
    this.getFolderSources();
  }

  public openNewSourceModal(): void {
    this.dialog.open(NewSourceModalComponent, {
      data: { folderId: this.selectedItem._id },
    });
  }

  public goToBatchUpload(): void {
    this.router.navigate(['/sources/batch-upload/', { folderId: this.selectedItem._id }]);
  }

  private getFolderSources(): void {
    this._folderService.getFolderSources(
      this.selectedItem._id,
      this.paging.page,
      this.paging.limit,
      this.sorting.sortDirection,
      this.sorting.sortField
    );
  }
}
