<div class="new-item-modal inner-bot-modal">
  <h3 class="mat-h3">Add new bot</h3>
  <form [formGroup]="newBotForm">
    <div class="form-inputs">
      <div class="form-input">
        <span class="custom-label">Bot name</span>
        <mat-form-field class="gingr-input-wrapper form-field-full">
          <input matInput placeholder="Bot name" type="text" formControlName="name" required />
          <mat-error *ngIf="name.invalid">
            <span *ngIf="name.errors?.required">Please enter bot name</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-input">
        <span class="custom-label">Description</span>
        <mat-form-field class="gingr-input-wrapper form-field-full">
          <textarea
            matInput
            placeholder="Description"
            formControlName="description"
            rows="3"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="form-input">
        <span class="custom-label d-flex align-items-center">Presearch</span>
        <mat-form-field class="gingr-input-wrapper form-field-full">
          <textarea
            matInput
            placeholder="presearch"
            formControlName="preSearch"
            rows="2"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="form-input">
        <span class="custom-label d-flex align-items-center"
          >Instruction
          <mat-icon class="icon" matTooltip="{{ innerBotMessages.infoInstruction }}"
            >info</mat-icon
          ></span
        >
        <mat-form-field class="gingr-input-wrapper form-field-full">
          <textarea
            matInput
            placeholder="instruction"
            formControlName="instruction"
            rows="3"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="form-input">
        <span class="custom-label d-flex align-items-center"
          >Behaviour
          <mat-icon class="icon" matTooltip="{{ innerBotMessages.infoBehaviour }}"
            >info</mat-icon
          ></span
        >
        <mat-form-field class="gingr-input-wrapper form-field-full">
          <textarea
            matInput
            placeholder="behaviour"
            formControlName="behaviour"
            rows="3"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="form-input">
        <span class="custom-label d-flex align-items-center"
          >Config
          <mat-icon class="icon" matTooltip="{{ innerBotMessages.infoConfig }}"
            >info</mat-icon
          ></span
        >
        <mat-form-field class="gingr-input-wrapper form-field-full">
          <textarea matInput placeholder="config" formControlName="config" rows="3"></textarea>
        </mat-form-field>
      </div>

      <div class="form-input-row">
        <div class="form-input">
          <span class="custom-label d-flex align-items-center"
            >Materials
            <mat-icon class="icon" matTooltip="{{ innerBotMessages.materialsInfo }}"
              >info</mat-icon
            ></span
          >
          <div class="d-flex">
            <mat-slide-toggle formControlName="display_resources" class="m-l-16" />
          </div>
        </div>
        <div class="form-input">
          <span class="custom-label d-flex align-items-center"
            >Top P
            <mat-icon class="icon" matTooltip="{{ innerBotMessages.infoTopP }}"
              >info</mat-icon
            ></span
          >
          <mat-slider
            class="gingr-input-wrapper"
            [min]="inputsConfig.top_p.min"
            [max]="inputsConfig.top_p.max"
            [step]="inputsConfig.top_p.step"
            discrete
            [displayWith]="formatTopP"
          >
            <input matInput matSliderThumb formControlName="top_p" />
          </mat-slider>
        </div>
        <div class="form-input">
          <span class="custom-label d-flex align-items-center"
            >Temperature
            <mat-icon class="icon" matTooltip="{{ innerBotMessages.infoTemperature }}"
              >info</mat-icon
            ></span
          >
          <mat-slider
            class="gingr-input-wrapper"
            [min]="inputsConfig.temperature.min"
            [max]="inputsConfig.temperature.max"
            [step]="inputsConfig.temperature.step"
            discrete
            [displayWith]="formatSlider"
          >
            <input matInput matSliderThumb formControlName="temperature" />
          </mat-slider>
        </div>
        <div class="form-input">
          <span class="custom-label d-flex align-items-center"
            >Presence Penalty
            <mat-icon class="icon" matTooltip="{{ innerBotMessages.infoPresencePenalty }}"
              >info</mat-icon
            ></span
          >
          <mat-slider
            class="gingr-input-wrapper"
            [min]="inputsConfig.presence_penalty.min"
            [max]="inputsConfig.presence_penalty.max"
            [step]="inputsConfig.presence_penalty.step"
            discrete
            [displayWith]="formatSlider"
          >
            <input matInput matSliderThumb formControlName="presence_penalty" />
          </mat-slider>
        </div>
        <div class="form-input">
          <span class="custom-label d-flex align-items-center"
            >Frequency Penalty
            <mat-icon class="icon" matTooltip="{{ innerBotMessages.infoFrequencyPenalty }}"
              >info</mat-icon
            ></span
          >
          <mat-slider
            class="gingr-input-wrapper"
            [min]="inputsConfig.frequency_penalty.min"
            [max]="inputsConfig.frequency_penalty.max"
            [step]="inputsConfig.frequency_penalty.step"
            discrete
            [displayWith]="formatSlider"
          >
            <input matInput matSliderThumb formControlName="frequency_penalty" />
          </mat-slider>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between w-full">
      <button mat-raised-button color="accent" (click)="showHistoryModal()">Show history</button>

      <button
        mat-raised-button
        color="accent"
        [disabled]="newBotForm.invalid"
        (click)="addOrUpdateBot()"
      >
        Save
      </button>
    </div>
  </form>
</div>
