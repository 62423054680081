<ng-template [ngIf]="instructions">
  <button
    mat-raised-button
    class="m-l-16 m-t-24"
    color="accent"
    (click)="createModal()"
  >
    <mat-icon>add</mat-icon>
    Add
  </button>


  <mat-table [dataSource]="instructions" class="mat-elevation-z8">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-icon class="icon" (click)="openDeleteModal(row)">delete</mat-icon>
        <mat-icon class="icon edit-icon" (click)="openEditModal(row)">edit</mat-icon>
        {{ row.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="content">
      <mat-header-cell *matHeaderCellDef>Content</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.content }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="keywords">
      <mat-header-cell *matHeaderCellDef>Keywords</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.keywords }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef class="justify-content-center">Status</mat-header-cell>
      <mat-cell *matCellDef="let row"
                class="justify-content-center d-flex flex-col list"
      >
        <app-badge
          clickable="true"
          (click)="toggleStatus(row)"
          [color]="row.status === 'active'? 'active' : 'inactive'"
        >
          {{ row.status | titlecase }}
        </app-badge>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['name', 'content', 'keywords', 'status']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['name','content', 'keywords', 'status']"></mat-row>
  </mat-table>


</ng-template>
