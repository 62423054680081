import { ContentItemType } from '@core/interfaces/ContentItemType';

export const getNavigationRoute = (type: ContentItemType): string => {
  switch (type) {
    case ContentItemType.Bots:
      return '/bots';
    case ContentItemType.Results:
      return '/results';
    case ContentItemType.ResultConversation:
      return '/results/conversation';
    case ContentItemType.Sources:
      return '/sources/item';
    case ContentItemType.Folders:
      return '/sources';
    case ContentItemType.Projects:
      return '/projects';
    default:
      return '';
  }
};

export const getPathToSourceItem = (folderId: string, sourceId: string): string => {
  return `/sources;folder_id=${folderId};source_id=${sourceId}`;
};

export const resultsConversationRegex = /\/results\/conversation\/([a-zA-Z0-9]+)/;

