<div class="new-item-modal modal">
  <h3 class="mat-h3">Bot "{{ data.bot.name }}" prompt hints</h3>


  <form [formGroup]="form">
    <div formArrayName="hints" class="hints-list" #hintsList>
      <div *ngFor="let string of hints.controls; let i=index" [formGroupName]="i" class="hint-input">

        <div class="text-input">
          <mat-form-field class="gingr-input-wrapper w-full hide-sub">
            <input
              matInput
              type="text"
              [maxlength]="maxLength"
              formControlName="hintText"
            />
          </mat-form-field>
          <mat-error *ngIf="hints.at(i).get('hintText')?.errors && hints.at(i).get('hintText')?.touched">
            <span
              *ngIf="hints.at(i).get('hintText')?.errors?.minlength">
              Hint is too short. Expected min. {{ minLength }} characters
            </span>
            <span *ngIf="hints.at(i).get('hintText')?.errors?.maxlength">
              Hint is too long. Expected max, {{ maxLength }} characters
            </span>
            <span *ngIf="hints.at(i).get('hintText')?.errors?.required">
              This input is required
            </span>
          </mat-error>
        </div>


        <mat-icon matTooltip="Remove hint" (click)="removeHint(i)" fontIcon="remove_circle_outline"></mat-icon>

      </div>
    </div>

  </form>


  <div class="d-flex align-items-center justify-content-between m-t-16">
    <button
      mat-raised-button
      color="accent"
      (click)="addHint(); scrollToBottom()">
      <mat-icon fontIcon="add_circle"></mat-icon>
      Add hint
    </button>
    <div>

      <button
        mat-raised-button
        color="accent"
        (click)="cancel()">
        Cancel
      </button>
      <button
        [disabled]="!form.valid"
        class="m-l-8"
        mat-raised-button
        color="accent"
        (click)="confirm()">
        Save
      </button>
    </div>

  </div>
</div>
