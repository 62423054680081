import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IGNORE_ERRORS_KEY } from '@core';

export class DriverBase {
  private http: HttpClient;
  protected source: SafeUrl | undefined;
  protected sanitizer: DomSanitizer;

  constructor() {
    this.http = inject(HttpClient);
    this.sanitizer = inject(DomSanitizer);
  }

  loadFileContent(url: string, callback: (blob: Blob) => void) {
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    this.http
      .get(url, { headers, responseType: 'blob' })
      .pipe(
        catchError(() => {
          return of(null);
        })
      )
      .subscribe(blob => {
        if (blob) {
          callback(blob);
        }
      });
  }
}
