import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ContentItemType } from '@core/interfaces/ContentItemType';
import { TBotItem } from '@core/interfaces/TBotItem';
import { TProjectItem } from '@core/interfaces/TProjectItem';

import { ListModalComponent } from '../../components/modals/list-modal/list-modal.component';
import { BotsService } from '@shared/services/bots.service';
import { ProjectsService } from '@shared/services/projects.service';

@Component({
  selector: 'app-project-bots',
  templateUrl: './project-bots.component.html',
  styleUrls: ['./project-bots.component.scss'],
})
export class ProjectBotsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public selectedProject: TProjectItem = {} as TProjectItem;
  public allBots: TBotItem[] = [];
  public projectBots: TBotItem[] = [];
  private _subs: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private _botsService: BotsService,
    private _projectService: ProjectsService
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this._botsService.getBotsObservable().subscribe(results => {
        this.allBots = results;
        if (results.length === 0) {
          this._botsService.fetchBots();
        }
        if (this.selectedProject.bots?.length) {
          this.projectBots = this._botsService.getProjectBots(this.selectedProject.bots);
        }
      })
    );
    this._subs.push(
      this._projectService.getProjectsObservable().subscribe(results => {
        this.selectedProject =
          results.find(it => it._id === this.selectedProject._id) || ({} as TProjectItem);
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProject.previousValue !== changes.selectedProject.currentValue) {
      this.projectBots = this._botsService.getProjectBots(
        changes.selectedProject.currentValue.bots
      );
    }
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  openAllBotsListModal(): void {
    const dialogRef = this.dialog.open(ListModalComponent, {
      data: {
        projectId: this.selectedProject._id,
        itemsIds: this.projectBots.map(bot => bot._id),
        items: this.allBots,
        type: ContentItemType.Bots,
      },
    });

    dialogRef.componentInstance.toggleCallback.subscribe(() => {
      this.projectBots = this._botsService.getProjectBots(this.selectedProject.bots);
    });
  }

  navigateToBot(botId: string): void {
    this.router.navigate([`bots/${botId}`], {
      queryParams: { projectId: this.selectedProject._id },
    });
  }
}
