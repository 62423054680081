import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { CommonService } from '@shared/services/common.service';
import { MetadataService, MetadataSlugs } from '@shared/services/metadata.service';

@Component({
  selector: 'app-global-vocabulary-rules',
  templateUrl: './global-vocabulary-rules.component.html',
  styleUrls: ['./global-vocabulary-rules.component.scss'],
})
export class GlobalVocabularyRulesComponent implements OnInit {

  public vocabularyRulesForm = this._fb.nonNullable.group({
    rules: ['', [Validators.required, Validators.min(10)]],
  });

  constructor(
    private _metadataService: MetadataService,
    private _fb: FormBuilder,
    private _commonService: CommonService,
  ) {
  }

  ngOnInit() {
    this.get();
  }

  submit() {
    this._metadataService.set(
      MetadataSlugs.GlobalVocRules,
      this.vocabularyRulesForm.value.rules!.toString(),
    ).subscribe({
      error: err => {
        console.log(err);
      },
      next: () => {
        this._commonService.openSnackBar('Updated successfully');
      },
    });
  }

  get() {
    this._metadataService.get<string>(MetadataSlugs.GlobalVocRules).subscribe(value => {
      this.vocabularyRulesForm.patchValue({ rules: value.value! });
    });
  }
}
