import { environment } from '@env/environment';

import { GetRequestData, PostRequestData, PutRequestData } from '../utils';
import { SourceUpdateBody, TConfluenceImport, TConfluencePaging } from './types';

export class SourcesApi {
  static updateSource(body: SourceUpdateBody): PutRequestData<SourceUpdateBody> {
    const sourceId = body._id;
    return {
      url: `${environment.backendURL}/api/source/${sourceId}`,
      body,
    };
  }

  static getConfluenceSpaces(paging: TConfluencePaging): GetRequestData {
    return {
      url: `${environment.backendURL}/api/confluence/spaces?limit=${paging.limit}&start=${paging.start}`,
    };
  }


  static getConfluencePages(spaceKey: string, paging: TConfluencePaging): GetRequestData {
    return {
      url: `${environment.backendURL}/api/confluence/spaces/${spaceKey}/pages?limit=${paging.limit}&start=${paging.start}`,
    };
  }

  static import(body: TConfluenceImport): PostRequestData<TConfluenceImport> {
    return {
      url: `${environment.backendURL}/api/confluence/import`,
      body,
    };
  }
}
