import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { RecordStreamService } from '../../../../bots/talking-avatar/record-stream.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-stream-player',
  templateUrl: './stream-player.component.html',
  styleUrls: ['./stream-player.component.scss'],
})
export class StreamPlayerComponent implements AfterViewInit {
  private _subs: Subscription[] = [];

  constructor(private _recordService: RecordStreamService) {}

  @ViewChild('videoPlayer', { static: true }) videoElement:
    | ElementRef<HTMLVideoElement>
    | undefined;
  protected isPlaying = true;
  protected controlsAreShown = false;
  protected hideControls = false;

  @Output() loaded = new EventEmitter<boolean>();
  @Output() videoEnded = new EventEmitter<void>();

  @Input() set streamData(value: MediaProvider | undefined) {
    if (value) {
      this.initPlayer(value);
    }
  }

  @Input() set videoUrl(data: { url: string; loop?: boolean }) {
    if (this.videoElement) {
      this.hideControls = data.loop || false;
      this.videoElement.nativeElement.autoplay = true;
      this.videoElement.nativeElement.loop = data.loop || false;
      this.videoElement.nativeElement.src = data.url;
      this.videoElement.nativeElement.controls = false;
      this.videoElement.nativeElement.onended = () => {
        this.videoEnded.emit();
      };
    }
  }

  ngAfterViewInit() {
    this._recordService.setVideoElement(this.videoElement);
  }

  private initPlayer(value: MediaStream | MediaSource | Blob) {
    if (this.videoElement) {
      this.hideControls = false;
      this.videoElement.nativeElement.srcObject = value;
      this.videoElement.nativeElement.onloadedmetadata = () => {
        this.loaded.emit(true);
        this.videoElement!.nativeElement.play().catch(console.error);
      };
    }
  }

  togglePlayPause() {
    if (this.videoElement?.nativeElement.paused) {
      this.isPlaying = true;
      this.videoElement?.nativeElement.play();
    } else {
      this.isPlaying = false;
      this.videoElement?.nativeElement.pause();
    }
  }
}
