<div class="m-b-24">
  <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" [multiple]="false">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div *ngIf="!files.length">Drop file here</div>
      <button type="button" mat-raised-button color="accent" class="m-t-12" (click)="openFileSelector()">Browse Files</button>
      <div *ngIf="files.length" class="m-t-12"><strong>{{files[0].relativePath}}</strong></div>
    </ng-template>
  </ngx-file-drop>

  <div *ngIf="isDataInvalid" class="m-t-12 error">You can select only 1 file.</div>
</div>
