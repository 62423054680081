<div class="compare-modal">
  <h3 class="mat-h3">Compare source</h3>
  <div>
    <p>Selected source:</p>
    <p>{{ data.source.name }}</p>
    <p>Search for source to compare with:</p>
  </div>
  <div class="gingr-input-wrapper gingr-input-wrapper--with-form search-wrapper">
    <form [formGroup]="searchForm" (ngSubmit)="search()">
      <input
        class="gingr-input--primary"
        color="primary"
        matInput
        placeholder="Search"
        formControlName="query"
        autocomplete="off"
      />
    </form>
    <div class="search-buttons">
      <button mat-icon-button (click)="clearSearch()">
        <mat-icon>close</mat-icon>
      </button>
      <button mat-icon-button (click)="search()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="isSearchLoading" class="d-flex align-items-center w-full">
    <mat-progress-spinner class="spinner" [color]="'accent'" [mode]="'indeterminate'">
    </mat-progress-spinner>
  </div>
  <div *ngIf="!isSearchLoading && searchResults.total === 0">No results</div>
  <div *ngIf="!isSearchLoading && searchResults.total > 0">
    <mat-table [dataSource]="searchResults.data" #table>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="d-flex align-items-center fs-14">
            <div class="d-flex flex-col">
              <span class="source-name">{{ row.name }}</span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="justify-content-center max-width-20"
          >Actions</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="justify-content-center max-width-20">
          <span [matTooltip]="!row.content ? noContentMessage : ''" [matTooltipPosition]="'above'">
            <button
              mat-raised-button
              color="primary"
              class="compare-button"
              (click)="compare(row._id)"
              [disabled]="!row.content"
            >
              Compare
            </button></span
          >
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="['name', 'actions']"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['name', 'actions']"></mat-row>
    </mat-table>
    <mat-paginator
      #paginator
      (page)="handlePageEvent($event)"
      [length]="searchResults.total"
      [pageSize]="10"
      [pageIndex]="paging.page - 1"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
</div>
