import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { TSourceItem } from '@core/interfaces/TSourceItem';

import { CommonService } from '@shared/services/common.service';
import { SourcesService } from '@shared/services/sources.service';

@Component({
  selector: 'app-new-file-modal',
  templateUrl: './new-file-modal.component.html',
  styleUrls: ['./new-file-modal.component.scss'],
})
export class NewFileModalComponent implements OnInit, OnDestroy {
  @Output() handleFileContent = new EventEmitter<string>();

  private _subs: Subscription[] = [];
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { file: File; source: TSourceItem },
    private dialogRef: MatDialogRef<NewFileModalComponent>,
    private _commonService: CommonService,
    private _sourceService: SourcesService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this._subs.push(
      this._commonService.getIsLoadingObservable().subscribe(results => {
        this.isLoading = results;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  public cancel() {
    this.dialogRef.close(undefined);
  }

  public addNewFile(): void {
    this._sourceService.addNewSourceFile(
      { file: this.data.file, sourceId: this.data.source._id as string },
      res => {
        this.handleFileContent.emit(res.source.content);
        this.dialogRef.close(this.data.file);
        this._commonService.openSnackBar(`File added successfully!`);
      },
      () => {
        this.cancel();
        this._commonService.openSnackBar(`File adding failed! Please try again!`);
      }
    );
  }
}
