import { Component, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ImagePreviewModalComponent } from './image-preview-modal/image-preview-modal.component';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent {

  private _link: string | undefined;
  protected imageSource: SafeUrl | undefined;

  @Input({ required: true }) set imageLink(link: string) {
    this._link = link;
    this.loadImage();
  }

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
  ) {
  }

  loadImage() {
    const headers = new HttpHeaders().set('X-Skip-Error-Handling', 'true');
    this.http.get(this._link!, { headers, responseType: 'blob' })
      .pipe(
        catchError(() => {
          this.imageSource = './assets/images/material_not_found.svg';
          return of(null);
        }),
      )
      .subscribe(blob => {
        if (blob) {
          const objectURL = URL.createObjectURL(blob);
          this.imageSource = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        } else {
          console.error('Load file failed');
        }
      });
  }

  openDialog() {
    this.dialog.open(ImagePreviewModalComponent, {
      data: { imageSource: this.imageSource },
    });
  }
}
