<div class="new-item-modal">
    <h3 class="mat-h3">Add new project</h3>
    <form class="form-field-full" [formGroup]="newProjectForm" >
        <div class="form-inputs">
            <mat-form-field class="gingr-input-wrapper w-full">
                <input
                       matInput
                       placeholder="Source name"
                       type="text"
                       formControlName="name"
                       required />
                <mat-error *ngIf="name.invalid">
                    <span *ngIf="name.errors?.required">Please enter source name
                    </span>
                </mat-error>
            </mat-form-field>
        </div>
        <button
                class=""
                mat-raised-button
                color="accent"
                [disabled]="newProjectForm.invalid"
                (click)="addNewItem()">
            Add new project
        </button>
    </form>
</div>
