<span [matTooltip]="isStarred ? 'Remove from favorites' : 'Add to favorites'">
  <button
    *ngIf="canStar"
    mat-icon-button
    color="{{ isStarred ? 'accent' : 'white' }}"
    (click)="toggle()"
  >
    <mat-icon class="icon">star</mat-icon>
  </button>
</span>
