<ng-container *ngFor="let part of parsedParts">
  <span *ngIf="part.type === 'text'">{{ part.value }}</span>

  <app-source-preview-button
    *ngIf="part.type === 'references'"
    [sourceDetail]="sourcesDetails[part.value.ref - 1] || null"
    [label]="part.value.index"
  ></app-source-preview-button>
</ng-container>

