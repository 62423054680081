<div [matMenuTriggerFor]="menu">
  <ng-content></ng-content>
</div>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="edit.emit()">
    <mat-icon>mode_edit</mat-icon>
    <span>Edit</span>
  </button>
  <button mat-menu-item (click)="history.emit()">
    <mat-icon>history</mat-icon>
    <span>Configuration history</span>
  </button>
  <button mat-menu-item (click)="promptHints.emit()" *ngIf="bot?.botType === 'botCreator'">
    <mat-icon>content_paste</mat-icon>
    <span>Prompt hints</span>
  </button>
  <button mat-menu-item (click)="duplicate.emit()">
    <mat-icon>content_copy</mat-icon>
    <span>Duplicate</span>
  </button>
  <button mat-menu-item (click)="delete.emit()">
    <mat-icon>delete</mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>
