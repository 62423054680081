import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import {
  allowedNewBotTypes,
  PredefinedBotType,
  PredefinedBotTypes,
  TBotType,
  TLanguage,
} from '@core/interfaces/TBot';

import { BotsService } from '@shared/services/bots.service';

import { allowedStatuses } from '../../../../enums/status';
import { TBotModel } from '@core/api/bots/types';


@Component({
  selector: 'app-new-bot-modal',
  templateUrl: './new-bot-modal.component.html',
  styleUrls: ['./new-bot-modal.component.scss'],
})
export class NewBotModalComponent implements OnInit, OnDestroy {
  public newBotForm: FormGroup;
  statuses = allowedStatuses;
  botTypes: TBotType[] = [];
  languages: TLanguage[] = [];

  private _subs: Subscription[] = [];
  protected botsModels: TBotModel[] = [];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NewBotModalComponent>,
    private _botService: BotsService
  ) {
    this.newBotForm = this.fb.nonNullable.group({
      name: ['', [Validators.required]],
      botType: ['', [Validators.required]],
      botModel: ['', [Validators.required]],
    });

    this.newBotForm.get('botType')!.valueChanges.subscribe((botType: string) => {
      this.updateFormControls(botType);
      this.updateSelectedLanguage();
    });
  }

  private updateFormControls(botType: string): void {
    Object.keys(this.newBotForm.controls).forEach(key => {
      if (!['name', 'botType', 'botModel'].includes(key)) {
        this.newBotForm.removeControl(key);
      }
    });

    if (PredefinedBotTypes.includes(botType as PredefinedBotType)) {
      this.newBotForm.addControl('status', this.fb.control('', [Validators.required]));
    }

    if (botType === 'botCreator') {
      this.newBotForm.addControl('language', this.fb.control('', [Validators.required]));
      this.newBotForm.addControl('description', this.fb.control(''));
      this.newBotForm.addControl('instruction', this.fb.control(''));
      this.newBotForm.addControl('behaviour', this.fb.control(''));
    }
  }

  public ngOnInit(): void {
    this._subs.push(
      this._botService.getBotTypesObservable().subscribe(results => {
        this.botTypes = results.filter(botType => allowedNewBotTypes.includes(botType.id));
      })
    );
    this._subs.push(
      this._botService.getBotsModelsObservable().subscribe(res => {
        this.botsModels = res;
      })
    );

    this._subs.push(
      this._botService.getLanguagesObservable().subscribe(res => {
        this.languages = res;
        this.updateSelectedLanguage();
      })
    );
    this._botService.getBotModels();
    this._botService.fetchLanguages();
  }

  public get name() {
    return this.newBotForm.get('name')!;
  }

  public get botType() {
    return this.newBotForm.get('botType')!;
  }

  public get status() {
    return this.newBotForm.get('status')!;
  }

  public get language() {
    return this.newBotForm.get('language')!;
  }

  public get description() {
    return this.newBotForm.get('description')!;
  }

  public get instruction() {
    return this.newBotForm.get('instruction')!;
  }

  public get behaviour() {
    return this.newBotForm.get('behaviour')!;
  }

  public get botModel() {
    return this.newBotForm.get('botModel')!;
  }
  updateSelectedLanguage(): void {
    if (this.newBotForm.controls.language) {
      if (!this.language) {
        this.newBotForm.controls.language.setValue(this.languages[0]);
      } else {
        this.newBotForm.controls.language.setValue(
          this.languages.find(lang => lang.code === this.language.value) as TLanguage
        );
      }
    }
  }

  public isPredefinedBotType(): boolean {
    return PredefinedBotTypes.includes(this.botType.value as PredefinedBotType);
  }

  public addNewItem(): void {
    if (this.isPredefinedBotType()) {
      this.addNewPredefinedBot();
    }
    if (this.botType.value === 'botCreator') {
      this.addNewBotCreator();
    }
  }

  private addNewPredefinedBot(): void {
    this._botService.addNewPredefinedBot(
      {
        name: this.newBotForm.value.name || '',
        botType: this.newBotForm.value.botType || '',
        status: this.newBotForm.value.status || '',
        botModel: this.newBotForm.value.botModel,
      },
      () => this.dialogRef.close()
    );
  }

  private addNewBotCreator(): void {
    this._botService.addBotCreator(
      {
        name: this.newBotForm.value.name || '',
        language: this.newBotForm.value.language || '',
        description: this.newBotForm.value.description,
        instruction: this.newBotForm.value.instruction,
        behaviour: this.newBotForm.value.behaviour,
        botModel: this.newBotForm.value.botModel,
      },
      () => this.dialogRef.close()
    );
  }
  ngOnDestroy() {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
