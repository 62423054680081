import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TSearchResponseItem } from '@core/interfaces/TSearch';
import { TSourceItem } from '@core/interfaces/TSourceItem';

import { BotsService } from '@shared/services/bots.service';
import { SearchService } from '@shared/services/search.service';
import { initialPaging } from '@shared/utils/paging';
import { NoContent } from 'app/constants/messages';

@Component({
  selector: 'app-compare-sources-modal',
  templateUrl: './compare-sources-modal.component.html',
  styleUrls: ['./compare-sources-modal.component.scss'],
})
export class CompareSourcesModalComponent implements OnInit, OnDestroy {
  isSearchLoading = false;
  paging = initialPaging;
  private _subs: Subscription[] = [];
  searchResults: TSearchResponseItem<TSourceItem> = {} as TSearchResponseItem<TSourceItem>;
  primaryCompareBotId: string | null = null;
  noContentMessage = NoContent;

  public searchForm = this.fb.nonNullable.group({
    query: [''],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { source: TSourceItem },
    private fb: FormBuilder,
    private _botsService: BotsService,
    private _searchService: SearchService,
    private dialogRef: MatDialogRef<CompareSourcesModalComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this._searchService.getSearchLoadingObservable().subscribe(res => {
        this.isSearchLoading = res;
      })
    );
    this._subs.push(
      this._searchService.getSearchResponseObservable().subscribe(res => {
        if (res) {
          this.searchResults = res.sources;
          this.paging = { totalResults: res.totalResults ?? 0, page: res.page ?? 1 };
        }
      })
    );

    this._subs.push(
      this._botsService.getPrimaryCompareBotIdObservable().subscribe(res => {
        this.primaryCompareBotId = res;
      })
    );
    this._botsService.getPrimaryCompareBot();
  }

  public search(): void {
    if (this.searchForm.get('query')?.value !== '') {
      this._searchService.search(
        'sources',
        this.searchForm.get('query')?.value ?? '',
        this.paging.page
      );
    }
  }

  public clearSearch(): void {
    this.searchForm.get('query')?.setValue('');
    this.searchResults = {} as TSearchResponseItem<TSourceItem>;
    this.paging = initialPaging;
  }

  handlePageEvent(e: PageEvent) {
    this.paging.page = e.pageIndex + 1;
    this._searchService.search(
      'sources',
      this.searchForm.get('query')?.value ?? '',
      this.paging.page
    );
  }

  compare(sourceId: string): void {
    if (!this.primaryCompareBotId) return;

    this.router.navigateByUrl(
      `/bots/${this.primaryCompareBotId}?s1=${this.data.source._id}&s2=${sourceId}`
    );
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._searchService.clearSearch();
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
