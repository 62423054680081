export const defaultDashboardContent = `<div>
      <h2>Private generative AI – OneRay</h2>
      <div>Welcome to the private generative AI environment called OneRay.</div>
      <div>The purpose of this environment is to test, demo and learn the application of a fully private, meaning
        not
        to any open sources connected generative AI solution with the objective to generate various specific
        content
        outputs.
      </div>
      <h3>Status</h3>
      <div>The system can sync with approved Veeva documents, publications and additional external, relevant
        documents. These documents will be tokenized into a Large Learning Model (LLM) to be used to generate
        content – or insights.
      </div>
      <h3>How to use OneRay</h3>
      <div>
        <ul>
          <li>On the left side navigation find the button “Bots”. In there you’ll find the list of bots you can
            choose
            from. Find more information on the “info-pop-up” for each bot. Each Bot behaves differently, based on
            pre-defined configurations. Clicking on a bot name brings you to a page with a text field to enter
            your
            question or statement. By clicking on “Create response” you’ll see the results.
          </li>
        </ul>
      </div>
      <div>
        <ul>
          <li>On the “Results” page you’ll see all created results. You can also start a conversation with any bot
            there, by simply clicking on the bots name.
          </li>
        </ul>
      </div>
      <h3>Prompt instructions</h3>
      <div>The response of the bots varies based on the way a question (or a prompt) is written. Note that for
        example
        “Training bots” will always respond in the form of a training structure, hence there’s no need to say
        “write
        me a training on ...”.
      </div>
      <div>Prompts guide:</div>
      <div>
        <ul>
          <li>For chatbots, write any questions or just simply a keyword. The bot will search for the most
            suitable
            content. If you’re not happy with the result, specify further.
          </li>
        </ul>
      </div>
      <div>
        <ul>
          <li>For Training or eMail bots, focus on a broad or specific topic you want to have detailed
            information.
            There are several bots with different styles, tonality, format &amp; lengths – try them out and
            provide
            comments by clicking on the red button – what you like and don’t like.
          </li>
        </ul>
      </div>
      <div><b>KEY: YOUR FEEDBACK IN ORDER TO IMPROVE THE RESULTS OF THE BOTS – THANK YOU!!!</b></div>
      <div>In case of questions or comments, please use the “Give feedback” button on the right side.</div>
    </div>`;

export const defaultVersion = `ONERAY AI, Version 2.0, 2024. Copyright by FFI-Ventures`;
export const defaultAppName = `OneRay AI`;
