import { Component, Input } from '@angular/core';
import { TSourceMaterial } from '@core/api/materials/types';
import { SourcesMaterialsApi } from '@core/api/materials/api';

@Component({
  selector: 'app-materials-preview',
  templateUrl: './materials-preview.component.html',
  styleUrls: ['./materials-preview.component.scss'],
})
export class MaterialsPreviewComponent {
  private _material: TSourceMaterial | undefined;

  @Input() asSingle = false;
  @Input({ required: true }) set material(material: TSourceMaterial | undefined) {
    if (material) {
      this._material = material;
    }
  }

  get material(): TSourceMaterial | undefined {
    return this._material;
  }

  linkToFile() {
    return SourcesMaterialsApi.downloadFile(this.material!.fileLink).url;
  }
}
