import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@core';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TwoFactorAuthQrCodeModalComponent } from '../two-factor-auth-qr-code/two-factor-auth-qr-code-modal.component';

@Component({
  selector: 'toggle-user-two-factor-auth',
  templateUrl: './toggle-user-two-factor-auth.component.html',
  standalone: true,
  styleUrls: ['./toggle-user-two-factor-auth.component.scss'],
  imports: [
    MatButtonModule,
    NgIf,
    MatIconModule,
  ],
})
export class ToggleUserTwoFactorAuthComponent {
  private _user: User | undefined;

  @Input() disableCancel = false;
  @Input({ required: true }) set user(user: User) {
    this._user = user;
  }

  get user(): User {
    return this._user!;
  }

  constructor(
    private dialog: MatDialog,
  ) {
  }

  protected toggle2Fa() {
    this.dialog.open(TwoFactorAuthQrCodeModalComponent, {
      disableClose: true,
      data: { user: this.user, disableCancel: false },
    });
  }

}
