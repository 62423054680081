<div class="new-item-modal">
  <form [formGroup]="editForm">
    <h3 class="mat-h3">Edit text before export</h3>

    <div class="mat-description" formArrayName="pages">
      <div *ngFor="let page of formPages.controls; index as i" class="page-container">
        <div class="page-container__input">
          <span  class="custom-label">Page {{ i + 1 }}</span>
          <mat-form-field
            class="gingr-input-wrapper w-full"
          >
          <textarea
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            [formControlName]="i"
            cdkAutosizeMinRows="5"
          ></textarea>
          </mat-form-field>
        </div>

        <div class="page-container__buttons">
          <mat-icon matTooltip="Remove page" (click)="removePage(i)" fontIcon="remove_circle_outline"></mat-icon>
          <mat-icon matTooltip="Add page below" (click)="addPage(i+1)" fontIcon="add_circle"></mat-icon>
        </div>

      </div>
    </div>


    <h3 class="mat-h3">Settings</h3>
    <div formGroupName="options">
      <div>
        <mat-checkbox class="m-r-24" formControlName="pageNumber">Page numbers</mat-checkbox>
        <mat-checkbox formControlName="headerLogo">Add logo in header</mat-checkbox>
      </div>
      <div class="m-y-16">
        <mat-checkbox formControlName="confidentialityClause">Confidentiality clause
        </mat-checkbox>
      </div>
      <div *ngIf="editForm.value.options!.confidentialityClause">
        <mat-form-field class="gingr-input-wrapper w-full">
          <input
            matInput
            formControlName="confidentialityClauseText"
            [maxlength]="60"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-between m-t-24">
      <div>
        <button
          mat-raised-button
          color="accent"
          [disabled]="isLoading"
          (click)="cancel()">
          Cancel
        </button>
      </div>
      <div>

        <button
          *ngFor="let driver of ExportFilesFactory.drivers"
          class="m-r-16"
          mat-raised-button
          [disabled]="isLoading"
          color="accent"
          data-cy="export-to"
          (click)="confirm(driver.slug)">
          <mat-icon>{{ driver.icon }}</mat-icon>
          Export to {{ driver.slug.toUpperCase() }}
        </button>

      </div>
    </div>
  </form>
</div>
