import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { TSource } from '@core/interfaces/Endpoints';

import { TCountry, TRegion } from '@core/api/countries/types';
import { CommonService } from '@shared/services/common.service';
import { CountriesService } from '@shared/services/countries.service';
import { FoldersService } from '@shared/services/folders.service';
import { SourcesService } from '@shared/services/sources.service';
import { priorityLabels, PriorityValue } from '@shared/utils/priority';

import { allowedStatuses } from '../../../../enums/status';

@Component({
  selector: 'app-new-source-modal',
  templateUrl: './new-source-modal.component.html',
  styleUrls: ['./new-source-modal.component.scss'],
})
export class NewSourceModalComponent implements OnInit, OnDestroy {
  countries: TCountry[] = [];
  regions: TRegion[] = [];
  statuses = allowedStatuses;

  public newSourceForm = this.fb.nonNullable.group({
    name: ['', [Validators.required]],
    fileUrl: ['URL'],
    originalFileName: ['URL'],
    status: ['', [Validators.required]],
    source: ['Source'],
    references: ['ref'],
    content: [''],
    priority: [1],
    publicationDate: [''],
    countries: [[] as string[]],
  });
  file: File | undefined;

  private _subs: Subscription[] = [];
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { folderId: string },
    private fb: FormBuilder,
    private _commonService: CommonService,
    private _folderService: FoldersService,
    private _sourceService: SourcesService,
    private _countriesService: CountriesService,
    private dialogRef: MatDialogRef<NewSourceModalComponent>
  ) {}

  ngOnInit(): void {
    this._countriesService.getCountriesAndRegions();
    const { countries, regions } = this._countriesService.getCountriesAndRegionsObservable();
    this._subs.push(
      countries.subscribe(response => {
        this.countries = response;
      })
    );
    this._subs.push(
      regions.subscribe(response => {
        this.regions = response;
      })
    );
    this._subs.push(
      this._commonService.getIsLoadingObservable().subscribe(results => {
        this.isLoading = results;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  public getCountriesAndRegions(): (TCountry | TRegion)[] {
    return [...this.regions, ...this.countries];
  }

  public getValue(key: string) {
    return this.newSourceForm.get(key)!.value;
  }

  public get status() {
    return this.newSourceForm.get('status')!;
  }

  public get name() {
    return this.newSourceForm.get('name')!;
  }

  public getPriorityLabel(priority: number): string {
    return priorityLabels[priority as PriorityValue];
  }

  public addNewItem(): void {
    if (this.newSourceForm.valid) {
      const newSourceItem: TSource = {
        fileUrl: this.getValue('fileUrl'),
        folder: [this.data.folderId],
        metadata: [],
        name: this.getValue('name') ?? ``,
        originalFileName: this.getValue('originalFileName'),
        source: this.getValue('source') ?? ``,
        status: this.getValue('status') ?? ``,
        priority: Number(this.getValue('priority')) ?? 0,
        content: this.getValue('content') ?? ``,
        references: this.getValue('references') ?? ``,
        publicationDate: this.getValue('publicationDate') ?? ``,
        countries: this.getValue('countries') as string[],
      };

      this._folderService.addNewSourceToFolder(newSourceItem, source => {
        if (this.file && source._id) {
          this._sourceService.addNewSourceFile(
            { file: this.file, sourceId: source._id },
            () => {
              this._countriesService.updateSourceCountries(
                source._id,
                this.getValue('countries') as string[]
              );
              this.dialogRef.close();
            },
            () => {
              this._commonService.openSnackBar(`File adding failed! Please try again!`);
              this.dialogRef.close();
            }
          );
        } else {
          this.dialogRef.close();
        }
      });
    }
  }

  onFileUpload(file: File) {
    this.file = file;
    if (file.name) {
      // get filename without extension
      const name = file.name.split('.').slice(0, -1).join('.');
      this.newSourceForm.get('fileUrl')!.setValue(file.name);
      this.newSourceForm.get('originalFileName')!.setValue(name);
      this.newSourceForm.get('name')!.setValue(name);
    }
  }
}
