import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MetadataApi } from '@core/api/metadata/api';

import { TMetadata } from '@core/api/metadata/types';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  constructor(private _http: HttpClient) {}

  public set(slug: MetadataSlugs | string, value: string | null, name?: string) {
    const { url, body } = MetadataApi.set({ name: name || slug, value, slug });
    return this._http.post(url, body);
  }

  public get<T>(slug: MetadataSlugs | string): Observable<TMetadata<T>> {
    const { url } = MetadataApi.get(slug);
    return this._http.get<TMetadata<T>>(url);
  }
}

export enum MetadataSlugs {
  GlobalVocRules = 'global_vocabulary_rules',
  DashboardContent = 'dashboard_content',
  MasterBotStatus = 'master_bot_status',
  Version = 'app_version',
  AppName = 'app_name',
  Is2FaRequired = 'two_factor_auth_required',
  BotEngine = 'bot_engine',
  ConfluenceSettings = 'confluence_settings',
  TalkingAvatarSettings = 'talking_avatar',
}
