import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TalkingAvatarService } from './talking-avatar.service';

@Component({
  selector: 'app-talking-avatar',
  templateUrl: './talking-avatar.component.html',
  styleUrls: ['./talking-avatar.component.scss'],
})
export class TalkingAvatarComponent implements OnInit, OnDestroy, AfterViewInit {
  streamData: MediaProvider | undefined;
  isPlaceHolderVisible = true;
  @ViewChild('placeholderVideo') placeholderVideo!: ElementRef<HTMLVideoElement>;
  @Output() visibleToggle = new EventEmitter();

  constructor(protected service: TalkingAvatarService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.placeholderVideo) {
        this.playPlaceholder();
      }
    }, 1000);
  }

  async ngOnInit() {
    this.service.getStreamDetailsAsObservable().subscribe(data => {
      if (data === null) {
        this.playPlaceholder();
        return;
      }
      this.streamData = data;
    });
  }

  isAvailable() {
    return this.service.config?.isAvailable ?? false;
  }

  toggleClose() {
    this.service.isClosed = !this.service.isClosed;
    this.visibleToggle.emit(this.service.isClosed);
  }

  playPlaceholder() {
    this.streamData = undefined;
    if (this.placeholderVideo) {
      this.placeholderVideo.nativeElement.muted = true;
      this.isPlaceHolderVisible = true;
      this.placeholderVideo.nativeElement.play();
    }
  }

  ngOnDestroy(): void {
    this.service.closeSession();
  }
}
