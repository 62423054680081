import { TExportFileDriver, TFileExportDriversAvailable } from '@shared/services/file-export/file-export-interface';
import { DocxDriver } from '@shared/services/file-export/drivers/docxDriver';
import { JsPdfDriver } from '@shared/services/file-export/drivers/jsPdfDriver';
import { PptxDriver } from '@shared/services/file-export/drivers/pptxDriver';

export class ExportFilesFactory {

  public static readonly drivers:TExportFileDriver[] = [
    {
      slug: 'pdf',
      icon: 'picture_as_pdf',
      class: JsPdfDriver
    },
    {
      slug: 'docx',
      icon: 'description',
      class: DocxDriver
    },
    {
      slug: 'pptx',
      icon: 'photo_library',
      class: PptxDriver
    }
  ];

  public static instantiateDriver(slug: TFileExportDriversAvailable){
    const driver = this.drivers.find(value => value.slug === slug);
    if (!driver) {
      throw new Error(`Driver ${slug} not found`);
    }
    return new driver.class();
  }
}
