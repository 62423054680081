<app-spinner *ngIf="isLoading" [message]="''"></app-spinner>

<ul
  *ngIf="!isLoading"
  class="gingr-content__drawer-content gingr-content__drawer-content--items"
>
  <li
    [ngClass]="{ selected: selectedFolder?._id === item._id }"
    *ngFor="let item of folders"
    class="gingr-content__drawer-content__item"
  >
      <span class="gingr-content__drawer-content__item"
            (click)="selectFolder(item)"
      >
      <div class="item-title">
      <div appTextEllipsis>{{ item.name }}</div>
      </div>
      </span>
    <div class="d-flex"
         (click)="deleteFolder(item)"
    >
          <span class="d-flex gingr-content__drawer-content__item__sufix delete-icon">
          <mat-icon class="icon">delete</mat-icon>
          </span>
    </div>
  </li>
</ul>
