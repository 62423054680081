import { environment } from '@env/environment';

import { TGlobalInstruction } from '@core/api/global-instructions/types';
import { DeleteRequestData, GetRequestData, PostRequestData } from '@core/api/utils';

export class GlobalInstructionsApi {
  static fetch(): GetRequestData {
    return {
      url: `${environment.backendURL}/api/globalInstructions/`,
    };
  }

  static get(id: string): GetRequestData {
    return {
      url: `${environment.backendURL}/api/globalInstructions/${id}`,
    };
  }

  static create(body: TGlobalInstruction): PostRequestData<TGlobalInstruction> {
    return {
      url: `${environment.backendURL}/api/globalInstructions/`,
      body,
    };
  }

  static update(instruction: TGlobalInstruction): PostRequestData<TGlobalInstruction> {
    return {
      url: `${environment.backendURL}/api/globalInstructions/${instruction._id}`,
      body: instruction,
    };
  }

  static delete(id: string): DeleteRequestData {
    return {
      url: `${environment.backendURL}/api/globalInstructions/${id}`,
    };
  }
}
