import { Component, Input, OnDestroy } from '@angular/core';
import { TBotItem } from '@core/interfaces/TBotItem';
import { BotsService } from '@shared/services/bots.service';
import { TAdaptedMessage } from '@shared/services/bots/types/types';
import { CommonService } from '@shared/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-good-prompt-button',
  templateUrl: './good-prompt-button.component.html',
  styleUrls: ['./good-prompt-button.component.scss'],
})
export class GoodPromptButtonComponent implements OnDestroy {
  @Input() message: TAdaptedMessage | null = null;
  @Input() bot: TBotItem | null = null;
  public isSelected = false;
  private _subs: Subscription[] = [];

  constructor(
    private _botsService: BotsService,
    private _commonService: CommonService
  ) {}

  public addToHints() {
    if (!this.bot) return;

    const hint = this.message!.paragraphs.map(p => p.text).join();

    const hints =
      this.bot.promptHints && this.bot.promptHints.length > 0
        ? [...this.bot.promptHints, hint]
        : [hint];
    this._subs.push(
      this._botsService.updatePromptHints(this.bot._id, hints).subscribe(() => {
        this._commonService.openSnackBar('Prompt added to hints');
        this.bot!.promptHints = hints;
        this.isSelected = true;
      })
    );
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
