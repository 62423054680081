import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { IGNORE_ERRORS_KEY } from '@core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent {
  private _link: string | undefined;
  videoUrl: SafeUrl | undefined;
  protected isFileMissing = false;
  protected isLoading = false;

  @Input({ required: true }) set videoLink(link: string) {
    this._link = link;
    this.loadVideo();
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }

  loadVideo() {
    this.isLoading = true;
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    this.http.get(this._link!, { headers, responseType: 'blob' })
      .pipe(
        catchError(() => {
          this.isFileMissing = true;
          return of(null);
        }),
        finalize(() => this.isLoading = false),
      )
      .subscribe(data => {
        if (data) {
          const blob = new Blob([data], { type: 'video/mp4' });
          this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        }
      });
  }

}
