import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TSource } from '@core/interfaces/Endpoints';
import { TMetadata, TMetadataNew } from '@core/interfaces/TMetadata';

@Component({
  selector: 'app-new-source-metadata-modal',
  templateUrl: './new-source-metadata-modal.component.html',
  styleUrls: ['./new-source-metadata-modal.component.scss'],
})
export class NewSourceMetadataModalComponent {
  @Output() metadataCreated = new EventEmitter<TMetadataNew>();
  public newSourceMetadataForm = this.fb.nonNullable.group({
    slug: ['Slug', [Validators.required]],
    value: ['Value', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { source: TSource },
    private dialogRef: MatDialogRef<NewSourceMetadataModalComponent>
  ) {}

  public get slug() {
    return this.newSourceMetadataForm.get('slug')!;
  }

  public get value() {
    return this.newSourceMetadataForm.get('value')!;
  }

  public addNewItem(): void {
    const sourceMetadata: TMetadataNew = {
      slug: this.slug.value,
      value: this.value.value,
    };
    this.metadataCreated.emit(sourceMetadata);
    this.dialogRef.close();
    // if (!this.data.source.metadata) {
    //   this.data.source.metadata = [];
    // }
    // this.data.source.metadata.push(sourceMetadata);
    // this.dialogRef.close();
  }
}
