import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-star-button',
  templateUrl: './star-button.component.html',
  styleUrls: ['./star-button.component.scss'],
})
export class StarButtonComponent {
  @Input() canStar = false;
  @Input() isStarred = false;
  @Input() onToggle: (() => void) | undefined;

  public toggle() {
    if (this.onToggle) {
      this.onToggle();
    }
  }
}
