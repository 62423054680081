import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VersionDialogComponent } from '@theme/sidebar/version-dialog/version-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sidebar-logo',
  templateUrl: './sidebar-logo.component.html',
  styleUrls: ['./sidebar-logo.component.scss'],
})
export class SidebarLogoComponent {
  @Output() public toggleSidenav = new EventEmitter<void>();
  @Input() public sidenavOpened = true;
  constructor(private _dialog: MatDialog) {}

  public showVersionDialog(): void {
    this._dialog.open(VersionDialogComponent, {
      width: '600px',
    });
  }
}
