import { TLogoDimensions } from '@shared/services/file-export/file-export-interface';

export class FileImage {
  private _logoBase: string | undefined;
  private _logoDimensions: TLogoDimensions | undefined;

  get logoDimensions(): TLogoDimensions | undefined {
    return this._logoDimensions;
  }

  get logoBase(): string | undefined {
    return this._logoBase;
  }

  public async setLogo(logoPath: string) {
    try {
      const response = await fetch(logoPath);
      if (!response.ok) {
        console.info('Getting image failed', response.status);
        return false;
      }
      const blob = await response.blob();
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          this._logoDimensions = { h: img.height, w: img.width };
          if (typeof reader.result === 'string') {
            this._logoBase = reader.result;
            resolve(true);
          } else {
            reject('Failed to load image as base64 string');
          }
        };
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            img.src = reader.result;
          }
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
