<div class="d-flex w-full h-full">
  <mat-card class="m-auto">
    <mat-card-header>
      <img src="assets/brands/prod/logo.png" alt="logo" />
      <mat-card-title>{{ 'login_title' | translate }}!</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form class="form-field-full" [formGroup]="loginForm" *ngIf="!is2FaRequired">
        <div class="form-inputs">
          <div class="gingr-input-wrapper">
            <input
              placeholder="{{ 'username' | translate }}"
              formControlName="username"
              required
              class="gingr-input--light"
            />
            <mat-error *ngIf="username.invalid && username.touched">
              <span *ngIf="username.errors?.required"
                >{{ 'please_enter_username' | translate }}
              </span>
              <span *ngIf="username.errors?.remote">{{ username.errors?.remote }}</span>
            </mat-error>
          </div>

          <div class="gingr-input-wrapper">
            <input
              placeholder="{{ 'password' | translate }}"
              type="password"
              formControlName="password"
              required
              class="gingr-input--light"
            />
            <mat-error *ngIf="password.invalid && password.touched">
              <span *ngIf="password.errors?.required"
                >{{ 'please_enter_password' | translate }}
              </span>
              <span *ngIf="password.errors?.remote">{{ password.errors?.remote }}</span>
            </mat-error>
            <mat-error>
              <span *ngIf=credentialsError>{{ credentialsError }}</span>
            </mat-error>
          </div>
        </div>
        <!--
        <mat-checkbox color="primary" formControlName="rememberMe"
          >{{ 'remember_me' | translate }}
        </mat-checkbox> -->
        <div class="forgot-password-wrapper">
          <a color="primary" routerLink="/auth/forgot-password">{{
            'forgot_password' | translate
          }}</a>
        </div>
        <button
          class="w-full m-y-16"
          mat-raised-button
          color="primary"
          [disabled]="!!loginForm.invalid"
          [loading]="isSubmitting"
          (click)="login()"
        >
          {{ 'login' | translate }}
        </button>

        <!-- <div>
          {{ 'have_no_account' | translate }}?
          <a routerLink="/auth/register">{{ 'create_one_account' | translate }}</a>
        </div> -->
      </form>
      <form class="form-field-full" [formGroup]="twoFactorAuthForm" *ngIf="is2FaRequired">
        <div class="form-inputs">
          <div class="gingr-input-wrapper">
            <span class="label-2fa">Enter your 2FA code</span>
            <input
              maxlength="6"
              placeholder="Two factor authentication code"
              type="text"
              formControlName="token"
              class="gingr-input--light"
            />
            <mat-error>
              <span *ngIf=token2faError>{{ token2faError }}</span>
            </mat-error>
          </div>
          <button
            class="w-full m-y-16"
            mat-raised-button
            color="primary"
            [disabled]="!twoFactorAuthForm.valid"
            (click)="verify2Fa()"
          >
            Verify
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
