<pre *ngIf="orderedSources.length > 0 || referenceSources.length > 0">{{ referenceLabel }}:</pre>
<div class="m-t-8 d-flex references-content" *ngFor="let osrc of orderedSources; index as i">
  <span
  >{{ osrc.order }}.
    <a [href]="getSourceLink(osrc.data)" target="_blank">{{
        osrc.data.references || osrc.data.name
      }}</a>
      <app-source-preview-button class="m-l-8" [sourceDetail]="osrc.data"></app-source-preview-button>
  </span>
</div>

<div class="m-t-8 d-flex references-content" *ngFor="let rsrc of referenceSources; index as i">
  <span *ngIf="isNumbered">
    <span
    >{{ i + 1 }}.
      <a *ngIf="rsrc" [href]="getSourceLink(rsrc)" target="_blank">{{ rsrc.references || rsrc.name }}</a>
      <app-source-preview-button class="m-l-8" [sourceDetail]="rsrc"></app-source-preview-button>
    </span>
  </span>
  <span *ngIf="!isNumbered">
    <span *ngIf="rsrc.references">
      <span class="source-name">{{ rsrc.name }}:</span>
      <span class="reference">{{ rsrc.references }}</span>
    </span>
    <span *ngIf="!rsrc.references">
      <span class="source-name">{{ rsrc.name }}</span>
    </span>
    <app-source-preview-button class="m-l-8"  [sourceDetail]="rsrc"></app-source-preview-button>
  </span>
</div>
