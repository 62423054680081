import { TConfluencePage, TConfluenceSpace } from '@core/api/sources/types';
import { ChangeDetectorRef, inject } from '@angular/core';

export class ConfluenceItemSelectionBase {
  protected cdr: ChangeDetectorRef;

  protected selectedSpace: TConfluenceSpace | undefined;
  protected selected = {
    spaces: [] as TConfluenceSpace[],
    pages: [] as TConfluencePage[],
  };

  constructor() {
    this.cdr = inject(ChangeDetectorRef);
  }

  protected togglePageSelection(page: TConfluencePage) {
    if (this.isWholeSpaceSelected(this.selectedSpace!)) {
      return;
    }
    const exists = this.selected.pages.some(p => p.id === page.id);
    if (!exists) {
      page.space = this.selectedSpace?.name;
      page.spaceKey = this.selectedSpace?.key;
      this.selected.pages.unshift(page);
    } else {
      this.unselectPage(page);
    }
  }

  toggleSelectWholeSpace() {
    const exists = this.selected.spaces.some(s => s.key === this.selectedSpace?.key);
    if (!exists) {
      this.selected.pages = this.selected.pages.filter(p => p.spaceKey !== this.selectedSpace?.key);
      this.selected.spaces.push(this.selectedSpace!);
    } else {
      this.unselectSpace(this.selectedSpace!);
    }
  }

  protected unselectPage(page: TConfluencePage) {
    this.selected.pages = this.selected.pages.filter(item => item.id !== page.id);
  }

  protected unselectSpace(page: TConfluenceSpace) {
    this.selected.spaces = this.selected.spaces.filter(item => item.key !== page.key);
  }

  protected isPageSelected(page: TConfluencePage): boolean {
    return this.selected.pages.some(p => p.id === page.id);
  }

  protected isWholeSpaceSelected(space: TConfluenceSpace): boolean {
    return this.selected.spaces.some(s => s.key === space?.key);
  }

  protected countSelectedItems(space: TConfluenceSpace): number {
    return this.selected.pages.filter(p => p.spaceKey === space.key).length;
  }
}
