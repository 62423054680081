import { HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';

export class StringUtils {
  static brandResource(resource: 'logo'| 'logoLight' | 'logoFile', ext = 'png'): string {
    return `./assets/brands/${environment.brand}/${resource}.${ext}`;
  }

  static urlParamsToStringArray(items: any[], key: string, param: string): string {
    let params = new HttpParams();
    items.forEach(item => {
      params = params.append(`${param}`, item[key]);
    });
    return params.toString();
  }

  static sanitizeMarkdown(text: string | undefined) {
    if (!text) return '';
    const markdownRegex = /[_*~`]/g;
    return text.replace(markdownRegex, '');
  }
}
