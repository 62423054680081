import { Component, Input, OnChanges } from '@angular/core';

import { TBotItem } from '@core/interfaces/TBotItem';
import { TResultsBotItem } from '@core/interfaces/TResultsItem';

@Component({
  selector: 'app-bot-info',
  templateUrl: './bot-info.component.html',
  styleUrls: ['./bot-info.component.scss'],
})
export class BotInfoComponent implements OnChanges {
  @Input() public botItem: TBotItem | TResultsBotItem = {} as TBotItem;
  botType = '';

  ngOnChanges(): void {
    if ('type' in this.botItem) {
      this.botType = this.botItem.type as string;
    } else if ('botType' in this.botItem) {
      this.botType = this.botItem.botType as string;
    }
  }
}
