import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserStorageService {

  put(key: string, item: any) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  get<T>(key: string): T | null{
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) as T : null;
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }
}
