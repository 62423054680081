<div
  class="matero-container-wrap"
  [ngClass]="{
    'matero-sidenav-collapsed': options.sidenavCollapsed && options.navPos !== 'top',
    'matero-navbar-side': options.navPos === 'side',
    'matero-navbar-top': options.navPos === 'top',
    'matero-header-above': options.headerPos === 'above',
    'matero-header-fixed': options.headerPos === 'fixed',
    'matero-header-white': options.theme !== 'dark'
  }"
  [dir]="options.dir"
>
  <ng-progress></ng-progress>

  <!-- Header Above -->
  <app-header
    *ngIf="options.showHeader"
    (toggleSidenavNotice)="sidenavNotice.toggle()"
  >
  </app-header>

  <mat-sidenav-container class="matero-container" autoFocus>
    <mat-sidenav
      #sidenav
      class="matero-sidenav mat-secondary"
      [mode]="isOver ? 'over' : 'side'"
      [opened]="options.navPos === 'side' && options.sidenavOpened && !isOver"
      (openedChange)="onSidenavOpenedChange($event)"
      (closedStart)="onSidenavClosedStart()"
    >
      <app-sidebar
        [showToggle]="!isOver"
        [showUser]="!!options.showUserPanel"
        (toggleCollapsed)="toggleCollapsed()"
        [toggleChecked]="!!options.sidenavCollapsed"
      >
      </app-sidebar>
    </mat-sidenav>

    <mat-sidenav #sidenavNotice position="end" mode="over">
      <app-sidebar-notice></app-sidebar-notice>
    </mat-sidenav>

    <mat-sidenav-content #content class="matero-content-wrap">
      <!-- <app-header *ngIf="options.showHeader && options.headerPos!=='above'"
                  [showToggle]="!options.sidenavCollapsed && options.navPos!=='top'"
                  [showBranding]="options.navPos==='top'"
                  (toggleSidenav)="sidenav.toggle()"
                  (toggleSidenavNotice)="sidenavNotice.toggle()">
      </app-header> -->

      <app-topmenu *ngIf="options.navPos === 'top'"></app-topmenu>

      <div class="matero-content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <app-sidebar-logo (toggleSidenav)="toggleSidebar()" [sidenavOpened]="isSidebarOpened"></app-sidebar-logo>

  <app-multi-summary></app-multi-summary>
  <app-two-factor-auth-monitor></app-two-factor-auth-monitor>
</div>
