<div [ngClass]="{'input-container': fixedToBottom }">
  <div [ngClass]="{'input': fixedToBottom }">
    <form [formGroup]="messagesForm" (ngSubmit)="submit()">
      <div class="message">
        <div class="message--content" [class.is-focussed]="isInputFocused">
          <div class="message--files" *ngIf="files.length > 0">
            <app-input-file-preview
              [itemFormIndex]="i" *ngFor="let file of files; index as i" [file]="file"
              (removeAt)="removeFile(i)"
            ></app-input-file-preview>
          </div>
          <div class="message--input" [ngClass]="{padding: !filterButton}">
            <div class="message--filter" [ngClass]="{active: filtersAreOpened}" *ngIf="filterButton"
                 (click)="toggleFilters()">
              <mat-icon>filter_list</mat-icon>
            </div>
            <div class="w-full">
        <textarea
          [hidden]="isDragging"
          autosize
          formControlName="message"
          (keydown.enter)="onEnter($event, true)"
          (keydown.shift.enter)="onEnter($event, false)"
          [minRows]="isExtended ? 3 : 1"
          (focus)="isInputFocused = true"
          (blur)="isInputFocused = false"
          [placeholder]="placeholder"
        ></textarea>
              <div class="w-full dnd-container" *ngIf="allowAttachFile" [hidden]="!isDragging">
                <app-conversation-dnd
                  (isDragging)="isDragging = $event"
                  (filesDropped)="handleFileInput($event)"
                ></app-conversation-dnd>
              </div>
            </div>
            <div class="message--send">
              <div *ngIf="allowAttachFile && !isExtended" (click)="fileInput.click()">
                <mat-icon class="action-icon">attach_file</mat-icon>
              </div>
              <div *ngIf="messagesForm.valid">
                <img
                  *ngIf="!isWriting"
                  class="pointer action-icon"
                  (click)="submit()"
                  src="assets/icons/buttons/send.svg" alt="rec"
                />
              </div>
              <img
                class="pointer action-icon"
                *ngIf="isWriting"
                (click)="writingAbort.emit()"
                src="assets/icons/buttons/abort.svg" alt="rec"
              />

              <app-speech-recognition
                class="action-icon"
                [hidden]="!(messagesForm.get('message')?.value === '' && !isWriting)"
                [language]="'en-US'"
                (transcription)="updateMessage($event)"
                (started)="setPlaceholder('Listening...')"
                (finished)="setPlaceholder()"
              ></app-speech-recognition>

            </div>
          </div>
          <div class="message--footer" *ngIf="allowAttachFile && isExtended" (click)="fileInput.click()">
            <div class="message--footer-drop">
              <span>Collaborate with OneRay by using documents, images and more...</span>
            </div>
            <div>
              <div>
                <mat-icon>attach_file</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <input type="file" multiple #fileInput (change)="onFileSelected($event)" hidden>
      </div>
    </form>
  </div>
</div>
