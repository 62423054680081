<ng-container *ngIf="selectedItem">

  <form *ngIf="!isLoading" [formGroup]="materialForm" (ngSubmit)="onSubmit()" class="form-field-full" id="materials-form">

    <h3>{{ isEditMode ? 'Edit material' : 'Create new material' }}</h3>

    <div>
      <span class="custom-label">Name</span>
      <mat-form-field class="gingr-input-wrapper w-full">
        <input formControlName="name" matInput type="text" required />
      </mat-form-field>
    </div>

    <div>
      <span class="custom-label">Keywords</span>
      <mat-form-field class="gingr-input-wrapper w-full">
        <input formControlName="keywords" matInput type="text" required />
      </mat-form-field>
    </div>

    <div>
      <span class="custom-label">Content</span>
      <mat-form-field class="gingr-input-wrapper w-full">
        <input formControlName="content" matInput type="text" />
      </mat-form-field>
    </div>

    <div>
      <span class="custom-label">Status</span>
      <mat-form-field class="gingr-input-wrapper w-full">
        <mat-select formControlName="status" placeholder="Select status">
          <mat-option *ngFor="let status of Object.keys(SourceMaterialStatus)" [value]="status">
            {{ status }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!isEditMode">
      <span class="custom-label">Type</span>
      <mat-form-field class="gingr-input-wrapper w-full">
        <mat-select formControlName="fileType" placeholder="Chose one">
          <mat-option *ngFor="let type of allowedFileTypes" [value]="type.id">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="isEditMode">
      <app-materials-preview [asSingle]="true" [material]="selectedItem"></app-materials-preview>
    </div>

    <app-material-file-input
      *ngIf="!isEditMode"
      formControlName="file"
      [type]="fileType"
    ></app-material-file-input>

    <app-spinner *ngIf="isProcessing" message="Processing..."></app-spinner>
    <button
      class="m-t-4"
      *ngIf="!isProcessing"
      color="accent"
      mat-raised-button
      type="submit"
      [disabled]="!materialForm.valid"
    >
      {{ isEditMode ? "Update" : "Save" }}
    </button>
    <button
      class="m-t-4 m-l-8"
      *ngIf="!isProcessing"
      color="primary"
      mat-raised-button
      (click)="closeForm.emit(true)"
    >
      Cancel
    </button>
  </form>
</ng-container>
