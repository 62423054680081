<div class="new-item-modal">
  <h3 class="mat-h3">Two factor authentication</h3>
  <app-spinner *ngIf="state === 'loading'"></app-spinner>
  <div *ngIf="state === 'confirmation'">
    <p [innerHTML]="initialMessage()"></p>
    <div class="d-flex justify-content-between w-full">
      <button
        *ngIf="!data.disableCancel"
        mat-raised-button
        color="accent"
        type="button"
        (click)="cancel()">
        Cancel
      </button>

      <button
        mat-raised-button
        color="accent"
        type="button"
        (click)="confirm()">
        Yes, confirm
      </button>

      <button
        *ngIf="data.disableCancel"
        mat-raised-button
        color="accent"
        type="button"
        (click)="logout()">
        Logout
      </button>
    </div>

  </div>

  <div *ngIf="state === 'displayQr'">
    <p>Scan this code in your two factor application:</p>
    <div class="content m-y-24">
      <img [src]="twoFactorAuthData.qr" alt="QR">
    </div>
    <p class="m-y-24">Or type the code: <strong>{{ twoFactorAuthData.secret }}</strong></p>
    <app-notice [label]="'Info'"
                [text]="'To use two factor authentication you have to have a 2fa application (like Google Authenticator). Below is the list of supported applications.'"></app-notice>

    <div class="m-y-24">
      <app-app-auth-list></app-app-auth-list>
    </div>


    <div class="d-flex align-items-center justify-content-between">
      <mat-checkbox [(ngModel)]="confirmation" class="m-r-16">I confirm that I have added this code to my 2FA
        application
      </mat-checkbox>
      <button
        [disabled]="!confirmation"
        mat-raised-button
        color="accent"
        type="button"
        (click)="cancel()">
        Confirm
      </button>
    </div>
  </div>

</div>
