import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({ name: 'formattedDate' })
export class FormattedDatePipe implements PipeTransform {
  transform(date: string) {
    return moment(date).format('MMM DD, YYYY - HH:mm');
  }
}
