import { Component } from '@angular/core';
import { ContentItemType } from '@core/interfaces/ContentItemType';
import { ResultsService } from '@shared/services/results.service';

@Component({
  selector: 'app-new-conversation-modal',
  templateUrl: './new-conversation-modal.component.html',
  styleUrls: ['./new-conversation-modal.component.scss'],
})
export class NewConversationModalComponent {
  constructor(private _resultsService: ResultsService) {}

  public addNewItem(): void {
    this._resultsService.addNewItem({
      __type: ContentItemType.Results,
      bot: {
        description: `new item bot description`,
        icon: `bot_logo`,
        type: 'Bot3',
      },
      content: `New results`,
      icon: `results-nobg`,
      title: `New results item`,
      group: {
        id: `1`,
        name: `Awesome bots`,
      },
    });
  }
}
