import { environment } from '@env/environment';

import { DeleteRequestData, GetRequestData, PostRequestData } from '../utils';
import { AddCountryBody, AddRegionBody, UpdateSourceCountryBody } from './types';

export class CountriesApi {
  static getCountries(): GetRequestData {
    return {
      url: `${environment.backendURL}/api/countries/list`,
    };
  }
  static deleteCountry(id: string): DeleteRequestData {
    return {
      url: `${environment.backendURL}/api/countries/${id}`,
    };
  }
  static addCountry(): PostRequestData<AddCountryBody> {
    return {
      url: `${environment.backendURL}/api/countries`,
    };
  }

  static updateSourceCountry(
    body: UpdateSourceCountryBody
  ): PostRequestData<UpdateSourceCountryBody> {
    return {
      url: `${environment.backendURL}/api/countries/updateSource/`,
      body,
    };
  }

  static deleteCountryFromSource(countryId: string, sourceId: string): DeleteRequestData {
    return {
      url: `${environment.backendURL}/api/countries/${countryId}/source/${sourceId}`,
    };
  }

  static getRegions(): GetRequestData {
    return {
      url: `${environment.backendURL}/api/regions/list`,
    };
  }

  static getRegion(id: string): GetRequestData {
    return {
      url: `${environment.backendURL}/api/regions/${id}`,
    };
  }

  static addRegion(): PostRequestData<AddRegionBody> {
    return {
      url: `${environment.backendURL}/api/regions`,
    };
  }

  static deleteRegion(id: string): DeleteRequestData {
    return {
      url: `${environment.backendURL}/api/regions/${id}`,
    };
  }
}
