import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TProject } from '@core/interfaces/Endpoints';
import { ProjectsService } from '@shared/services/projects.service';

@Component({
  selector: 'app-new-project-modal',
  templateUrl: './new-project-modal.component.html',
  styleUrls: ['./new-project-modal.component.scss'],
})
export class NewProjectModalComponent {
  public newProjectForm = this.fb.nonNullable.group({
    name: ['New project', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private _projectsService: ProjectsService,
    private dialogRef: MatDialogRef<NewProjectModalComponent>
  ) {}

  public get name() {
    return this.newProjectForm.get('name')!;
  }

  public addNewItem(): void {
    const newItem: TProject = {
      name: this.newProjectForm.get(`name`)?.value ?? ``,
      status: `active`,
    };
    this._projectsService.addNewProject(newItem, () => this.dialogRef.close());
  }
}
