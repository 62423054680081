import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

import { TFolderItem } from '@core/interfaces/TFolderItem';
import { TJobStatus } from '@core/interfaces/TJobStatus';

import { JobStatusService } from '@shared/services/job-status.service';

@Component({
  selector: 'app-job-status',
  templateUrl: './job-status.component.html',
  styleUrls: ['./job-status.component.scss'],
})
export class JobStatusComponent implements OnInit, OnDestroy {

  private _folder: TFolderItem | undefined;
  private _subs: Subscription[] = [];
  private _lastFetchTime: number | undefined;
  protected jobStatus: TJobStatus | undefined;

  private readonly checkInterval = 60 * 1000;

  @Input()
  public set folder(folder: TFolderItem | undefined) {
    this._folder = folder;
    this.fetch();
  }

  constructor(
    private _jobsStatusService: JobStatusService,
  ) {
  }

  get icon() {
    switch (this.jobStatus?.status) {
      case 'completed':
        return 'done';
      case 'failed':
        return 'error';
      case 'waiting':
        return 'pending_actions';
      case 'in progress':
        return 'autorenew';
      default:
        return '';
    }
  }

  get text() {
    switch (this.jobStatus?.status) {
      case 'completed':
        return 'Job completed';
      case 'failed':
        return 'Job failed';
      case 'waiting':
        return 'Job waiting';
      case 'in progress':
        return 'Job in progress';
      default:
        return '';
    }
  }

  ngOnInit(): void {
    this._subs.push(this._jobsStatusService.getJobsStatusObservable()
      .subscribe(res => {
        this.jobStatus = res.find(job => job.folderId === this._folder?._id);
      }));

    this._subs.push(interval(this.checkInterval)
      .subscribe(() => {
        if (!this.wasRecentlyUpdated()) {
          this.fetch();
        }
      }));
  }

  private fetch() {
    this._jobsStatusService.fetchJobsStatus();
    this._lastFetchTime = Date.now();
  }

  private wasRecentlyUpdated(): boolean {
    return (Date.now() - this._lastFetchTime! < this.checkInterval);
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
