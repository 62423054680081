<div class="d-flex w-full h-full">
  <mat-card class="m-auto">
    <mat-card-header>
      <img src="assets/brands/prod/logo.png" alt="logo" />
      <ng-container *ngIf="!emailSent">
        <mat-card-title>{{ 'forgot_your_password' | translate }}!</mat-card-title>
        <mat-card-subtitle>{{ 'forgot_your_password_description' | translate }}!</mat-card-subtitle>
      </ng-container>
      <ng-container *ngIf="emailSent">
        <mat-card-title>{{ 'check_your_email' | translate }}!</mat-card-title>
        <mat-card-subtitle>{{ getCheckYourEmailDescription() }}</mat-card-subtitle>
      </ng-container>
    </mat-card-header>

    <mat-card-content>
      <form class="form-field-full" [formGroup]="forgotPasswordForm">
        <div class="form-inputs" *ngIf="!emailSent">
          <div class="gingr-input-wrapper">
            <input
              placeholder="{{ 'email_address' | translate }}"
              type="text"
              formControlName="email"
              required
              class="gingr-input--light"
            />
            <mat-error *ngIf="email.invalid">
              <span *ngIf="email.errors?.required">{{ 'please_enter_email' | translate }} </span>
              <span *ngIf="email.errors?.remote">{{ email.errors?.remote }}</span>
            </mat-error>
          </div>
        </div>
        <!--
          <mat-checkbox color="primary" formControlName="rememberMe"
            >{{ 'remember_me' | translate }}
          </mat-checkbox> -->
        <button
          class="w-full m-y-16"
          mat-raised-button
          color="primary"
          [disabled]="!!forgotPasswordForm.invalid"
          [loading]="isSubmitting"
          *ngIf="!emailSent"
          (click)="sendRemind()"
        >
          {{ 'continue' | translate }}
        </button>
        <button class="w-full m-y-16" mat-raised-button color="primary" *ngIf="emailSent">
          {{ 'resend_email' | translate }}
        </button>
        <div class="link-wrapper">
          <a color="primary" routerLink="/">{{ 'back_to_home' | translate }}</a>
        </div>
        <!-- <div>
            {{ 'have_no_account' | translate }}?
            <a routerLink="/auth/register">{{ 'create_one_account' | translate }}</a>
          </div> -->
      </form>
    </mat-card-content>
  </mat-card>
</div>
