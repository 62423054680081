import { IBotMessageAdapter } from '@shared/services/bots/adapters/IBotMessageAdapter';
import { TAdaptedBotConversation } from '../types/types';
import { TSourceDetail } from '@core/interfaces/TMessage';

export class BaseAdapter implements IBotMessageAdapter {
  adaptedData: TAdaptedBotConversation | undefined;

  adapt(data: any, currentValue?: any, sourceDetails?: TSourceDetail[]): TAdaptedBotConversation {
    throw new Error('Method not implemented.');
  }
}
