<span [matTooltip]="tooltip"
      [matTooltipPosition]="'above'"
      *ngIf="source"
>
  <button
    mat-raised-button
    color="primary"
    class="action-button"
    (click)="addToCompareList()"
    [disabled]="isDisabled"
  >
    Add to action box
  </button>
</span>
