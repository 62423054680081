import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  GlobalInstructionsStatusEnum,
  globalInstructionsStatuses,
  TGlobalInstruction,
} from '@core/api/global-instructions/types';

@Component({
  selector: 'app-general-instructions-modal',
  templateUrl: './general-instructions-modal.component.html',
  styleUrls: ['./general-instructions-modal.component.scss'],
})
export class GeneralInstructionsModalComponent {

  @Output() public changed: EventEmitter<TGlobalInstruction> = new EventEmitter();

  protected isLoading = false;
  protected readonly globalInstructionsStatuses = globalInstructionsStatuses;

  public instructionsForm = this.fb.nonNullable.group({
    _id: [this.data?._id],
    name: [this.data?.name, [Validators.required]],
    content: [this.data?.content, [Validators.required]],
    keywords: [this.data?.keywords, [Validators.required]],
    status: [this.data?.status ?? GlobalInstructionsStatusEnum.Inactive],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TGlobalInstruction | null,
    private dialogRef: MatDialogRef<GeneralInstructionsModalComponent>,
    private fb: FormBuilder,
  ) {
  }

  protected save() {
    this.disableForm();
    this.changed.emit(this.instructionsForm.value as TGlobalInstruction);
  }

  protected cancel() {
    this.dialogRef.close();
  }

  public disableForm() {
    this.isLoading = true;
    this.instructionsForm.disable();
  }

  public enableForm() {
    this.isLoading = false;
    this.instructionsForm.enable();
  }

}
