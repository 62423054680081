import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TProjectItem } from '@core/interfaces/TProjectItem';
import { UsersService } from '@shared/services/users.service';
import { CommonService } from '@shared/services/common.service';
import { Subject, Subscription } from 'rxjs';
import { TBaseUser } from '@core/interfaces/Endpoints';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '@core';

@Component({
  selector: 'app-project-users',
  templateUrl: './project-users.component.html',
  styleUrls: ['./project-users.component.scss'],
})
export class ProjectUsersComponent implements OnInit, OnDestroy {

  @Input() public selectedProject: TProjectItem = {} as TProjectItem;
  private _subs: Subscription[] = [];
  protected users: TBaseUser[] = [];
  private _usersUpdate = new Subject<void>();
  protected isLoading = true;
  protected allowedColumnsForRole: string[] = ['username'];

  constructor(
    private _usersService: UsersService,
    private _commonService: CommonService,
    private _authService: AuthService,
  ) {

  }


  ngOnInit(): void {
    this._subs.push(
      this._authService.user().subscribe(user => {
        if (user.roles?.includes('admin')){
          this.allowedColumnsForRole.push('actions');
        }
      })
    );


    this._subs.push(
      this._usersUpdate
        .pipe(
          switchMap(() => this._usersService.getUsersForProject(this.selectedProject._id!),
          ),
        ).subscribe(users => {
        this.users = users;
        this.isLoading = false;
      }),
    );

    this._usersUpdate.next();
  }

  protected attachUsers(usersIds: string[]) {
    this._subs.push(this._usersService.attachUsersToProject(this.selectedProject._id!, usersIds).subscribe({
      next: () => {
        this._commonService.openSnackBar('Users assigned successfully');
        this._usersUpdate.next();
      },
      error: () => this._commonService.openSnackBar('Users assigned failed'),
    }));
  }

  protected detachUser(user: TBaseUser) {
    this._subs.push(
      this._usersService.detachUserFromProject(this.selectedProject._id!, user.username!).subscribe({
        next: () => {
          this._commonService.openSnackBar(`User "${user.username}" was detached from project"`);
          this.users = this.users.filter(usr => usr._id !== user._id);
        },
        error: () => this._commonService.openSnackBar('There was an error during detaching user from project'),
      }),
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

}
