import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sanitizeStr'
})
export class SanitizeStrPipe implements PipeTransform {

  transform(str: string,): string {
    return str.replace('_', ' ');
  }

}
