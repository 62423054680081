import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OriginalResourceModalComponent } from '@shared/components/show-original-resources/original-resource-modal/original-resource-modal.component';
import { TSourceDetail } from '@core/interfaces/TMessage';

@Component({
  selector: 'app-show-original-resources',
  templateUrl: './show-original-resources.component.html',
  styleUrls: ['./show-original-resources.component.css'],
})
export class ShowOriginalResourcesComponent {
  @Input() originalSources: TSourceDetail[] = [];

  constructor(private _dialog: MatDialog) {}

  openModal() {
    this._dialog.open(OriginalResourceModalComponent, {
      data: { sourceDetails: this.originalSources },
    });
  }
}
