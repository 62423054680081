<div class="modal">
  <h2>{{ source?.name }}</h2>
  <div class="modal--content">
    <app-spinner *ngIf="isLoading"></app-spinner>
    <span class="mark-text">
    {{ source?.content }}
    </span>
  </div>
  <div *ngIf="!isLoading" class="d-flex align-items-center m-t-16 justify-content-between">

    <button
      mat-raised-button
      color="accent"
      (click)="cancel()">
      Close
    </button>

    <div>
      <button
        mat-raised-button
        class="m-r-16"
        color="accent"
        [disabled]="!this.source"
        (click)="goToSource()">
        Go to source
      </button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="!this.source?.fileLink"
        (click)="goToOriginal()">
        Go to original
      </button>
    </div>
  </div>
</div>

