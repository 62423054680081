<div class="container" *ngIf="isAvailable()">
  <div class="icon" (click)="toggleClose()">
    <img *ngIf="service.isClosed" src="/assets/icons/buttons/avatar-off-full.svg" alt="avatar" />
    <img *ngIf="!service.isClosed" src="/assets/icons/buttons/avatar-on-full.svg" alt="avatar" />
  </div>
  <div *ngIf="!service.isClosed" class="container--video cropped-video">
    <app-stream-player
      class="cropped-video__content"
      [ngClass]="{hidden: isPlaceHolderVisible}"
      [streamData]="streamData"
      (loaded)="isPlaceHolderVisible = !$event"
    ></app-stream-player>
    <video
      class="cropped-video__content"
      #placeholderVideo
      loop
      playsinline
      width="900"
      height="506"
      [ngClass]="{hidden: !isPlaceHolderVisible}"
      src="/assets/avatar/prev.mov"
    ></video>
  </div>
</div>
