import {
  IFileExportDriver,
  TExportOptions,
  TLogoDimensions,
  TLogoPosition,
} from '@shared/services/file-export/file-export-interface';
import { FileImage } from '@shared/services/file-export/tools/file-image';
import { jsPDF } from 'jspdf';

export class JsPdfDriver implements IFileExportDriver {

  private file: jsPDF | undefined;
  private readonly pageMarginX = 20;
  private readonly pageMarginBottom = 20;
  private pageMarginTop = 30;
  private readonly pageHeaderMargin = 10;
  private pageWidth = 0;
  private pageHeight = 0;
  private pageNo = 1;
  private pages: string[] = [];
  private lineHeight = 0;
  private logoFile: FileImage | undefined;
  private options: TExportOptions | undefined;


  setPages(pages: string[]): this {
    this.file = new jsPDF().setFontSize(12);
    this.pageNo = 1;
    this.pages = pages;
    this.pageHeight = this.file.internal.pageSize.height || this.file.internal.pageSize.getHeight();
    this.pageWidth = this.file.internal.pageSize.width || this.file.internal.pageSize.getWidth();
    this.lineHeight = this.file.getLineHeight() / this.file.internal.scaleFactor;
    return this;
  }

  setOptions(options: TExportOptions): IFileExportDriver {
    this.options = options;
    return this;
  }

  async generate(filename: string) {
    if (!this.pages) {
      return;
    }
    this.writeContentToFile();

    this.file!.save(`${filename}.pdf`);
    this.file!.close();
  }

  private writeContentToFile() {
    let yPosition = this.pageMarginTop;

    this.pages.forEach((pageText, index) => {
      this.addPageTemplate();
      const textLines = this.getTextLinesForText(pageText);

      for (const line of textLines) {
        if (yPosition > this.pageHeight - this.pageMarginBottom) {
          this.file!.addPage();
          this.addPageTemplate();
          yPosition = this.pageMarginTop;
        }

        this.file!.text(line, this.pageMarginX / 2, yPosition);
        yPosition += this.lineHeight;
      }

      if (index < this.pages.length - 1) {
        this.file!.addPage();
        yPosition = this.pageMarginTop;
      }
    });
  }

  public cleanUp() {
    this.file = undefined;
    this.pages = [];
    this.logoFile = undefined;
  }

  protected calculateLogoDimensionsForFileHeader(dimensions: TLogoDimensions): TLogoPosition {
    const height = this.pageMarginTop - this.pageHeaderMargin * 2;
    const width = dimensions.w * (height / dimensions.h);

    return {
      x: (this.pageWidth / 2) - width / 2,
      y: this.pageHeaderMargin,
      w: width,
      h: height,
    };
  }

  protected addPageTemplate() {
    this.addPageNo();
    this.placeLogo();
    this.addFooterText(this.options?.confidentialityClauseText);
  }

  protected getTextLinesForText(text: string) {
    return this.file!.splitTextToSize(text, this.pageWidth - this.pageMarginX);
  }


  protected placeLogo() {
    if (!this.options?.headerLogo) {
      this.pageMarginTop = this.pageMarginBottom;
      return;
    }
    try {
      const { x, y, w, h } = this.calculateLogoDimensionsForFileHeader(this.logoFile!.logoDimensions!);
      this.file!.addImage(this.logoFile!.logoBase!, x, y, w, h, '', 'MEDIUM');
    } catch (e) {
      console.warn(e);
    }
  }

  async setLogo(filePath: string): Promise<any> {
    this.logoFile = new FileImage();
    if (await this.logoFile.setLogo(filePath) === false) {
      // same height if no image or exception threw;
      this.pageMarginTop = this.pageMarginBottom;
    }
    return;
  }

  protected addPageNo() {
    if (!this.options?.pageNumber) return;

    this.file!.text(
      `Page ${this.pageNo}`,
      this.pageWidth - (this.pageMarginX / 2),
      (this.pageHeight - this.pageMarginBottom) + this.pageMarginBottom / 2,
      { align: 'right' },
    );

    this.pageNo++;
  }

  protected addFooterText(text: string | undefined) {
    if (!this.options?.confidentialityClause || !text) return;
    this.file!.text(
      text,
      this.pageMarginX / 2,
      (this.pageHeight - this.pageMarginBottom) + this.pageMarginBottom / 2,
      { align: 'left' },
    );
  }
}
