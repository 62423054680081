import { IFileExportDriver, TExportOptions } from '@shared/services/file-export/file-export-interface';
import PptxGenJS from 'pptxgenjs';
import { FileImage } from '@shared/services/file-export/tools/file-image';

export class PptxDriver implements IFileExportDriver {
  private pages: string[] = [];
  private options: TExportOptions | null = null;
  private logoPath: string | undefined;
  private logoFile: FileImage | undefined;
  private pres: PptxGenJS | undefined;

  setPages(pages: string[]): IFileExportDriver {
    this.pages = pages;
    return this;
  }

  async setLogo(filePath: string): Promise<any> {
    this.logoPath = filePath;
    this.logoFile = new FileImage();
    return await this.logoFile.setLogo(filePath);
  }

  setOptions(options: TExportOptions): IFileExportDriver {
    this.options = options;
    return this;
  }

  generate(filename: string): void {
    this.pres = new PptxGenJS();

    this.pages.forEach(text => {

      const slide = this.pres!.addSlide({ masterName: 'MASTER' });

      this.addLogo(slide);
      this.addFooter(slide);

      slide.addText(text, {
        x: 0.5,
        y: 1,
        color: '000000',
        fit: 'resize',
        align: this.pres!.AlignH.left,
      });

    });

    this.pres!.writeFile({ fileName: `${filename}.pptx` });
  }

  protected addLogo(slide: PptxGenJS.Slide) {
    if (this.options?.headerLogo) {
      const logoHeight = 0.3;
      const logoWidth = this.calculateImageWidth(logoHeight);
      slide.addImage({
        path: this.logoPath,
        x: 0.2,
        y: 0.2,
        w: logoWidth,
        h: logoHeight,
      });
    }
  }

  protected addFooter(slide: PptxGenJS.Slide) {
    if (this.options?.confidentialityClause) {
      slide.addText(this.options.confidentialityClauseText, {
        y: '97%',
        w: '100%',
        fontSize: 12,
        color: '000000',
        align: this.pres!.AlignH.center,
      });

    }
  }

  cleanUp(): void {
    this.pages = [];
    this.options = null;
    this.pres = undefined;
    this.logoFile = undefined;
  }

  protected calculateImageWidth(logoHeight: number): number {
    if (this.logoFile?.logoDimensions) {
      return this.logoFile.logoDimensions.w * (logoHeight / this.logoFile.logoDimensions?.h);
    }
    return 0;
  }
}
