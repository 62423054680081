import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TBotItem } from '@core/interfaces/TBotItem';
import { TFolderItem } from '@core/interfaces/TFolderItem';
import { TConversation } from '@core/interfaces/TResultsItem';
import { TSearchIn, TSearchResponseItem } from '@core/interfaces/TSearch';
import { TSourceItem } from '@core/interfaces/TSourceItem';

import { SearchService } from '@shared/services/search.service';
import { AllSearchResultsComponent } from 'app/routes/components/modals/all-search-results/all-search-results.component';

@Component({
  selector: 'app-top-search',
  templateUrl: './top-search.component.html',
  styleUrls: ['./top-search.component.scss'],
})
export class TopSearchComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;
  private _subs: Subscription[] = [];
  loading = false;
  conversations: TSearchResponseItem<TConversation> = {} as TSearchResponseItem<TConversation>;
  sources: TSearchResponseItem<TSourceItem> = {} as TSearchResponseItem<TSourceItem>;
  folders: TFolderItem[] = [];
  bots: TBotItem[] = [];
  searchIn: TSearchIn | undefined;

  public searchForm = this.fb.nonNullable.group({
    query: [''],
  });

  constructor(
    private _searchService: SearchService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this._subs.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.setSearchIn();
        }
      })
    );
  }

  ngOnInit(): void {
    this.setSearchIn();

    this._subs.push(
      this._searchService.getSearchLoadingObservable().subscribe(res => {
        this.loading = res;
      })
    );
    this._subs.push(
      this._searchService.getFoldersObservable().subscribe(res => {
        this.folders = res;
      })
    );
    this._subs.push(
      this._searchService.getBotsObservable().subscribe(res => {
        this.bots = res;
      })
    );
    this._subs.push(
      this._searchService.getSearchResponseObservable().subscribe(res => {
        if (res && this.searchIn) {
          this.conversations = res.conversations;
          this.sources = res.sources;
        }
      })
    );
  }

  private setSearchIn(): void {
    this.clearSearch();
    this._searchService.clearSearch();
    const firstUrlSegment = this.router.url.split(';')[0].split('/')[1];
    if (firstUrlSegment === 'sources') {
      this.searchIn = 'sources';
    } else if (firstUrlSegment === 'results') {
      this.searchIn = 'conversations';
    } else {
      this.searchIn = undefined;
    }
  }

  public queryValue(): string | undefined {
    return this.searchForm.get('query')?.value;
  }

  public search(): void {
    // this.trigger?.openMenu();
    if (this.searchIn && this.queryValue() !== '') {
      this._searchService.instantSearch(this.searchIn, this.queryValue() ?? '');
    }
  }

  public clearSearch(): void {
    this.trigger?.closeMenu();
    this.searchForm.get('query')?.setValue('');
    this.conversations = {} as TSearchResponseItem<TConversation>;
    this.sources = {} as TSearchResponseItem<TSourceItem>;
  }

  public redirectToSource(source: TSourceItem): void {
    if (!source.folder.length) {
      console.error('Source has no folder');
      return;
    }

    this.router.navigate(['sources', { folder_id: source.folder[0], source_id: source._id }]);
  }

  public redirectToConversation(conversation: TConversation): void {
    this.router.navigateByUrl(`/results/conversation/${conversation._id}`);
  }

  public showAllResults(): void {
    const dialogRef = this.dialog.open(AllSearchResultsComponent, {
      data: { query: this.queryValue(), searchIn: this.searchIn },
      minHeight: '70vh',
      maxHeight: '90vh',
      minWidth: '70vw',
    });

    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(
      (selected: TConversation | TSourceItem) => {
        if (this.searchIn === 'conversations') {
          this.redirectToConversation(selected as TConversation);
        } else if (this.searchIn === 'sources') {
          this.redirectToSource(selected as TSourceItem);
        }
        dialogRef.close();
        dialogSubscription.unsubscribe();
      }
    );
  }

  ngOnDestroy(): void {
    this._searchService.clearSearch();
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
