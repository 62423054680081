import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ContentItemType } from '@core/interfaces/ContentItemType';
import { BotTypeId } from '@core/interfaces/TBot';
import { TBotItem } from '@core/interfaces/TBotItem';
import { TFolderItem } from '@core/interfaces/TFolderItem';

import { FoldersService } from '@shared/services/folders.service';
import { ProjectsService } from '@shared/services/projects.service';

@Component({
  selector: 'app-list-modal',
  templateUrl: './list-modal.component.html',
  styleUrls: ['./list-modal.component.scss'],
})
export class ListModalComponent implements OnInit, OnDestroy {
  @Output() toggleCallback = new EventEmitter();

  public availableItems: TFolderItem[] | TBotItem[] = [];
  public botTypes: Record<string, BotTypeId> = {};
  private _subs: Subscription[] = [];
  public title = 'Add or delete';

  public form = this.fb.nonNullable.group({
    allItems: this.fb.array([
      this.fb.group({
        id: ['', [Validators.required]],
        name: ['', [Validators.required]],
        isSelected: [true, [Validators.required]],
      }),
    ]),
  });

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { projectId: string; itemsIds: string[]; items?: []; type: ContentItemType },
    private dialogRef: MatDialogRef<ListModalComponent>,
    private _folderService: FoldersService,
    private _projectService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.allItems.clear();
    if (this.data.type === ContentItemType.Folders) {
      this.botTypes = {};
      this._subs.push(
        this._folderService.getFoldersObservable().subscribe(res => {
          this.availableItems = res;
          this.availableItems.forEach(item => {
            this.allItems.push(
              this.fb.group({
                id: [item._id],
                name: [item.name],
                isSelected: [this.data.itemsIds.includes(item._id)],
              })
            );
          });
        })
      );
    } else if (this.data.type === ContentItemType.Bots && this.data.items) {
      this.botTypes = this.data.items.reduce((acc: Record<string, BotTypeId>, item: TBotItem) => {
        acc[item._id] = item.botType;
        return acc;
      }, {});
      this.availableItems = this.data.items;
      this.data.items.forEach((item: TBotItem) => {
        this.allItems.push(
          this.fb.group({
            id: [item._id],
            name: [item.name],
            isSelected: [this.data.itemsIds.includes(item._id)],
          })
        );
      });
    }
    this.title =
      this.data.type === ContentItemType.Folders
        ? 'Add or delete sources in this project'
        : 'Add or delete bots in this project';
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  get allItems() {
    return this.form.get('allItems') as FormArray;
  }

  toggleItemSelection(event: any, itemId: string): void {
    this.form.patchValue({
      allItems: this.allItems.value.map((item: { id: string; isSelected: boolean }) => {
        if (item.id === itemId) {
          item.isSelected = event.checked;
        }
        return item;
      }),
    });
    if (this.data.type === ContentItemType.Folders) {
      this.toggleFolderSelection(event, itemId);
    } else if (this.data.type === ContentItemType.Bots) {
      this.toggleBotSelection(event, itemId);
    }
  }

  toggleFolderSelection(event: any, folderId: string): void {
    const availableFolders = this.availableItems as TFolderItem[];
    const item = availableFolders.find(item => item._id === folderId);
    if (item) {
      if (event.checked) {
        this._folderService.addFolderProject(item, this.data.projectId, () =>
          this.toggleCallback.emit()
        );
      } else {
        this._folderService.removeFolderProject(item, this.data.projectId, () =>
          this.toggleCallback.emit()
        );
      }
      this.toggleCallback.emit();
    }
  }

  toggleBotSelection(event: any, botId: string): void {
    const availableBots = this.availableItems as TBotItem[];
    const item = availableBots.find(item => item._id === botId);
    if (item) {
      if (event.checked) {
        this._projectService.addBotToProject(this.data.projectId, item._id, () =>
          this.toggleCallback.emit()
        );
      } else {
        this._projectService.removeBotFromProject(this.data.projectId, item._id, () =>
          this.toggleCallback.emit()
        );
      }
    }
  }

  save(): void {
    // this.saveCallback.emit(this.projectFoldersForm.value.allFolders as FolderData[]);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
