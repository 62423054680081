<div *ngIf="type">

  <div *ngIf="type === 'link'">
    <span class="custom-label">Link</span>
    <mat-form-field class="gingr-input-wrapper w-full">
      <input [(ngModel)]="file" matInput type="text" required (input)="onInput()" [disabled]="isDisabled" />
    </mat-form-field>
  </div>

  <div *ngIf="type === 'youtube'">
    <p class="custom-label">YouTube video</p>
    <span class="custom-label"><strong>Hint:</strong> Provide an URL to video (from the browser url input)</span>
    <mat-form-field class="gingr-input-wrapper w-full">
      <input [(ngModel)]="file" matInput type="text" required (input)="onInput()" [disabled]="isDisabled" />
    </mat-form-field>
  </div>

  <div *ngFor="let item of inputTypes">
    <div *ngIf="type === item.type">
      <span class="custom-label">{{ item.title }}</span>
      <app-drag-and-drop
        (handleFileUpload)="onInput($event)"
        [allowedExt]="item.ext"
        [maxFileSizeMB]="item.size"
      ></app-drag-and-drop>
    </div>
  </div>
</div>

