import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SpeechRecognitionService } from './speech-recognition.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-speech-recognition',
  templateUrl: './speech-recognition.component.html',
  styleUrls: ['./speech-recognition.component.scss'],
})
export class SpeechRecognitionComponent implements OnInit, OnDestroy {
  private transcriptSubscription: Subscription | undefined;

  @Output() transcription: EventEmitter<string | null> = new EventEmitter<string | null>();
  @Output() started = new EventEmitter();
  @Output() finished = new EventEmitter();

  @Input({ required: true }) set language(lang: string) {
    this.speechService.setLanguage(lang);
  }

  constructor(public speechService: SpeechRecognitionService) {}

  ngOnInit(): void {
    this.transcriptSubscription = this.speechService.transcript$.subscribe(transcript => {
      this.transcription.emit(transcript);
    });
    this.speechService.timeoutEvent.subscribe(() => {
      this.finished.emit();
    });
  }

  ngOnDestroy(): void {
    if (this.transcriptSubscription) {
      this.transcriptSubscription.unsubscribe();
    }
  }

  toggleListening(): void {
    if (this.speechService.isListening) {
      this.speechService.stopListening();
      this.finished.emit();
    } else {
      this.speechService.startListening();
      this.transcription.emit(null);
      this.started.emit();
    }
  }
}
