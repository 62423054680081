<mat-dialog-content class="version-dialog-container">
  <div class="d-flex justify-content-center align-items-baseline">
    <img [src]="brandSidebarLogoPath" [ngClass]="brandSidebarLogoClass" alt="logo" />
    <img
      [style]="{ width: brandLogoWidth }"
      [src]="brandLogoPath"
      class="align-middle m-2 brand-logo"
      alt="logo"
    />
  </div>
  <div class="d-flex flex-col align-items-center m32">
    <p class="m-8 f-s-20 f-w-700">About {{ appName }}</p>
    <p class="m-8">{{ version }}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
