import { CanActivateFn } from '@angular/router';
import { AuthService } from '@core';
import { inject } from '@angular/core';
import { filter, map } from 'rxjs/operators';

export const roleGuard: CanActivateFn = () => {
  return inject(AuthService).user().pipe(
    map(user => user.roles?.includes('admin')),
    filter(Boolean)
  );
};
