import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { environment } from '@env/environment';

import { MetadataService, MetadataSlugs } from '@shared/services/metadata.service';
import { StringUtils } from '@shared/utils/stringUtils';
import { defaultAppName, defaultVersion } from 'app/constants/defaults';

@Component({
  selector: 'app-version-dialog',
  templateUrl: './version-dialog.component.html',
  styleUrls: ['./version-dialog.component.scss'],
})
export class VersionDialogComponent implements OnInit, OnDestroy {
  public version = '';
  public appName = '';
  private _subs: Subscription[] = [];

  public brandSidebarLogoPath = './assets/brands/prod/sidebarLogo.png';
  public brandSidebarLogoClass = 'sidebar-logo';
  public brandLogoPath = StringUtils.brandResource('logo');
  public brandLogoWidth = environment.logoWidthPx ?? '220px';

  constructor(private _metadataService: MetadataService) {}

  ngOnInit(): void {
    this._subs.push(
      this._metadataService.get<string>(MetadataSlugs.Version).subscribe({
        next: metadata => {
          if (metadata.value) {
            this.version = metadata.value;
          } else {
            this.version = defaultVersion;
          }
        },
        error: () => {
          this.version = defaultVersion;
        },
      })
    );
    this._subs.push(
      this._metadataService.get<string>(MetadataSlugs.AppName).subscribe({
        next: metadata => {
          if (metadata.value) {
            this.appName = metadata.value;
          } else {
            this.appName = defaultAppName;
          }
        },
        error: () => {
          this.appName = defaultAppName;
        },
      })
    );
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
