import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-source-content-modal',
  templateUrl: './source-content-modal.component.html',
  styleUrls: ['./source-content-modal.component.scss'],
})
export class SourceContentModalComponent implements OnInit, OnDestroy {
  @Output() public actionCallback = new EventEmitter();

  private _subs: Subscription[] = [];
  isLoading = false;

  public contentForm = this.fb.nonNullable.group({
    content: [this.data.content, [Validators.required]],
  });

  ngOnInit(): void {
    this._subs.push(
      this._commonService.getIsLoadingObservable().subscribe(results => {
        this.isLoading = results;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { content: string },
    private dialogRef: MatDialogRef<SourceContentModalComponent>,
    private fb: FormBuilder,
    private _commonService: CommonService
  ) {}

  public saveContent(): void {
    this.actionCallback.emit(this.contentForm.value.content);
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
