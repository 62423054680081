import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as Mark from 'mark.js';
import { Router } from '@angular/router';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { skip } from 'rxjs/operators';


@Component({
  selector: 'app-source-preview-modal',
  templateUrl: './source-preview-modal.component.html',
  styleUrls: ['./source-preview-modal.component.scss'],
})
export class SourcePreviewModalComponent implements OnDestroy {

  private sub: Subscription | undefined;
  protected source: TSourceItem | undefined;
  protected isLoading = true;

  constructor(
    private dialogRef: MatDialogRef<SourcePreviewModalComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: {
      source: BehaviorSubject<TSourceItem>,
      markText: string | undefined | null,
    },
  ) {
    this.sub = this.data.source.pipe(skip(1)).subscribe((newSource) => {
      this.source = newSource;
      this.isLoading = false;
      if (this.data.markText) {
        setTimeout(() => {
          this.mark(this.data!.markText!);
        }, 300);
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  goToSource() {
    this.cancel();
    this.router.navigate(['sources', { folder_id: this.source?.folder[0], source_id: this.source?._id }]);
  }

  goToOriginal() {
    if (this.source?.fileLink) window.open(this.source?.fileLink, '_blank');
  }

  mark(textToSearch: string) {
    const instance = new Mark('span.mark-text');
    const pattern = textToSearch.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    const reg = new RegExp(pattern, 'gim');
    instance.markRegExp(reg, {
      className: 'marked-text',
      done: (marksTotal: number) => {
        this.navigateToMarked(marksTotal);
      },
    });
    // instance.mark(textToSearch, {
    //   className: 'marked-text',
    // });
  }

  private navigateToMarked(marksTotal: number) {
    if (marksTotal > 0) {
      const firstMarked = document.querySelector('mark');
      if (firstMarked) {
        firstMarked.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
      }
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
