import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '@core';
import { filter, map } from 'rxjs/operators';

@Directive({
  selector: '[minRole]',
})
export class MinRoleDirective {
  private subscription: Subscription | undefined;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
  ) {
  }

  @Input()
  set minRole(role: string) {
    this.subscription = this.authService.user().pipe(
      map(user => {
        if (user.roles?.includes('admin')) {
          return true;
        } else if (user.roles?.includes('user') && role !== 'admin') {
          return true;
        }
        return false;
      }),
      filter(Boolean),
    ).subscribe(isVisible => {
      if (isVisible) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
      this.subscription?.unsubscribe();
    });
  }

}
