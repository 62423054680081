import { Icons } from 'app/enums/icons';

export interface TIconItem {
  icon: string;
}

export function isCustomIcon(item: TIconItem): boolean {
  const customIcons: string[] = Object.values(Icons);
  return customIcons.includes(item.icon);
}
