import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

import { TBotItem } from '@core/interfaces/TBotItem';
import { TSourceDetail } from '@core/interfaces/TMessage';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { BotConversationAdapter } from '@shared/services/bots/adapters/BotConversationAdapter';

import { InitStreamConversationBody } from '@core/api/conversations/types';
import { ExportToSelectorComponent } from '@shared/components/export-to-selector/export-to-selector.component';
import { BotMessagesService } from '@shared/services/bots/bot-messages.service';
import { ConversationsService } from '@shared/services/conversations.service';
import { SourcesService } from '@shared/services/sources.service';
import { TalkingAvatarService } from '../talking-avatar/talking-avatar.service';

@Component({
  selector: 'app-conversation-bot',
  templateUrl: './conversation-bot.component.html',
  styleUrls: ['./conversation-bot.component.scss'],
})
export class ConversationBotComponent implements OnInit, OnChanges, OnDestroy {
  @Input() bot: TBotItem = {} as TBotItem;
  @Input() selectedProjectId: string | undefined;
  @Input() selectedCountries: string[] | undefined;
  @Input() selectedDateStart: string | undefined;
  @Input() selectedDateEnd: string | undefined;
  @Input() hideInput = false;
  @Input() allowFilterButton = true;
  @Output() openFilters = new EventEmitter<boolean>();
  @ViewChild('exportSelector') exportSelector: ExportToSelectorComponent | undefined;
  private _subs: Subscription[] = [];
  private sourceIdsChanged = new BehaviorSubject<boolean>(false);
  sourceId = '';
  selectedSource: TSourceItem | null = null;

  isStartingConversation = true;
  isWriting = false;
  isSearching = false;
  currentConversationId: string | null = null;
  sourceDetails: TSourceDetail[] = [];
  currentUserMessage = '';
  private files: File[] = [];
  private messageFomQueryParams: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _conversationService: ConversationsService,
    protected botMessageService: BotMessagesService,
    private _sourceService: SourcesService,
    private _talkingAvatarService: TalkingAvatarService
  ) {
    this.botMessageService.setAdapter(new BotConversationAdapter());
    this.route.queryParams.subscribe(params => {
      if (params.source) {
        this.sourceId = params.source;
        this.sourceIdsChanged.next(true);
      }
      if (params.message) {
        this.messageFomQueryParams = params.message;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { message: null },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  ngOnInit(): void {
    this._subs.push(
      this._conversationService.getIsSearchingObservable().subscribe(r => {
        this.isSearching = r;
      })
    );

    this._subs.push(
      this._conversationService.getIsWritingObservable().subscribe(isWriting => {
        this.isWriting = isWriting;
        this.botMessageService.toggleIsWritingStatus(isWriting);
      })
    );

    this._subs.push(
      this._conversationService.getConversationIdObservable().subscribe(r => {
        this.currentConversationId = r;
      })
    );

    this._subs.push(
      this._conversationService.getCurrentConversationObservable().subscribe(res => {
        if (res) {
          if (res.type === 'message_id') {
            this.botMessageService.updateLastMessageId(res.data);
          }
          this.botMessageService.appendMessageViaAdapter(res, this.sourceDetails);
        }
      })
    );

    this._subs.push(
      this._conversationService.getErrorOccurredAsObservable().subscribe(error => {
        if (error) {
          this.botMessageService.addErrorMessage(error);
        }
      })
    );

    this._subs.push(
      this._conversationService.getSourceDetailsObservable().subscribe(res => {
        if (res) {
          this.sourceDetails = res;
        }
      })
    );

    this._subs.push(
      this.sourceIdsChanged.subscribe(hasChanged => {
        if (hasChanged) {
          this._sourceService.getSource(this.sourceId, (res: TSourceItem) => {
            this.selectedSource = res;
          });
        }
      })
    );

    this.sendMessageFromQueryParams();
  }

  private resetState(): void {
    this.setCurrentMessage();
    this.botMessageService.reset();
    this.stopWriting();
    this.currentConversationId = null;
    this.sourceDetails = [];
    this.isStartingConversation = true;
    this.isWriting = false;
    this.isSearching = false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.bot?.previousValue !== changes.bot?.currentValue) {
      this.resetState();
    }
  }

  public setMessageAndSubmit(message: string, files?: File[]) {
    this.setCurrentMessage(message, files);
    this.sendMessage();
  }

  public addUserMessage(message: string): void {
    this.botMessageService.addMessage('user', message);
  }

  public addBotMessage(conversationId: string): void {
    this.botMessageService.addMessage('assistant', '', conversationId);
  }

  setCurrentMessage(message = '', files: File[] = []): void {
    this.currentUserMessage = message;
    this.files = files;
  }

  public sendMessage(message?: string): void {
    this._talkingAvatarService.init();
    if (this.currentUserMessage) {
      if (!message) this.addUserMessage(this.currentUserMessage);
      if (this.isStartingConversation) {
        this.isStartingConversation = false;
        const initStreamBody: InitStreamConversationBody = {
          question: this.currentUserMessage,
          project_id: this.selectedProjectId,
          countries: this.selectedCountries,
          dateStart: this.selectedDateStart,
          dateEnd: this.selectedDateEnd,
        };
        if (this.sourceId !== '') {
          initStreamBody.source_id = this.sourceId;
        }
        this._conversationService.initStreamConversation(
          this.bot._id,
          initStreamBody,
          this.files,
          response => {
            this.botMessageService.updateLastMessageId(response.message_id);
            this.botMessageService.updateLastMessageByFiled(
              'user',
              'files',
              response.uploadedFiles
            );
            this.getConversation(response.conversation_id);
          }
        );
      } else {
        this._conversationService.addQuestionToConversation(
          this.bot._id,
          {
            question: this.currentUserMessage,
            conversation_id: this.currentConversationId as string,
          },
          (conversationId, messageId) => {
            this.botMessageService.updateLastMessageId(messageId);
            this.getConversation(conversationId);
          }
        );
      }
    }
    this.setCurrentMessage('');
  }

  private getConversation(conversationId: string | undefined): void {
    if (!conversationId) return;
    this.addBotMessage(conversationId);
    this._conversationService.getConversationAsStream(
      {
        botId: this.bot._id,
        conversationId,
      },
      () => this.generateAvatarMessage()
    );
  }

  protected generateAvatarMessage(): void {
    const msg = this.botMessageService.getLastMessageByAuthor('assistant');
    const txt = this.botMessageService.getCopyMessage(msg);
    this._talkingAvatarService.setIds(this.currentConversationId as string, msg._id);
    this._talkingAvatarService.sendQuestion(txt);
  }

  regenerateMessage(): void {
    const lastMessage = this.botMessageService.getLastMessageByAuthor('user').paragraphs[0].text;
    this.setCurrentMessage(lastMessage);
    this.sendMessage(lastMessage);
  }

  stopWriting(): void {
    this._conversationService.cancelStreamConversation();
  }

  sendMessageFromQueryParams(): void {
    if (this.messageFomQueryParams) {
      this.setMessageAndSubmit(this.messageFomQueryParams);
    }
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
