export class Tags {
  static getTagCSS(tag: string): string {
    const classes: string[] = [`tag`];
    switch (tag.toLowerCase()) {
      case `offline`:
      case `status`:
        classes.push(`mat-secondary`);
        break;
      case `tokenized`:
      case `active`:
        classes.push(`mat-success`);
        break;
    }
    return classes.join(` `);
  }

  static getTagLabel(tag: string): string {
    switch (tag.toLowerCase()) {
      case `offline`:
      case `status`:
        return `Offline`;
      case `tokenized`:
      case `active`:
        return `Tokenized`;
      default:
        return ``;
    }
  }
}
