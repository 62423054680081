<div class="new-item-modal">
  <h3 class="mat-h3">Add new source</h3>
  <form class="form-field-full" [formGroup]="newSourceForm">
    <div class="form-inputs">
      <mat-form-field class="gingr-input-wrapper w-full">
        <input matInput placeholder="Source name" type="text" formControlName="name" required />
        <mat-error *ngIf="name.invalid">
          <span *ngIf="name.errors?.required">Please enter source name </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="gingr-input-wrapper w-full">
        <mat-select formControlName="status" placeholder="Select status">
          <mat-option *ngFor="let st of statuses" [value]="st">
            {{ st | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="status.invalid">
          <span *ngIf="status.errors?.required">Please provide status </span>
        </mat-error>
      </mat-form-field>

      <div class="gingr-content">
        <mat-form-field class="gingr-input-wrapper w-full">
          <input
            formControlName="publicationDate"
            matInput
            [matDatepicker]="picker"
            placeholder="Choose a date"
          />
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <mat-form-field class="gingr-input-wrapper w-full">
        <input matInput placeholder="Source" type="text" formControlName="source" />
      </mat-form-field>

      <mat-form-field class="gingr-input-wrapper w-full">
        <textarea matInput class="" placeholder="Content" formControlName="content"> </textarea>
      </mat-form-field>

      <mat-form-field class="gingr-input-wrapper w-full">
        <textarea matInput class="" placeholder="References" formControlName="references">
        </textarea>
      </mat-form-field>

      <mat-form-field class="gingr-input-wrapper w-full">
        <mat-select placeholder="Select country" formControlName="countries" multiple>
          <mat-option
            *ngFor="let value of getCountriesAndRegions(); let i = index"
            [value]="value._id"
          >
            {{ value.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="gingr-input-wrapper w-full">
        <mat-select formControlName="priority" placeholder="Select a priority level">
          <mat-option *ngFor="let val of [1, 2, 3, 4, 5]" [value]="val">
            {{ getPriorityLabel(val) }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <app-drag-drop (handleFileUpload)="onFileUpload($event)"></app-drag-drop>
    </div>
    <div class="d-flex justify-content-center">
      <mat-progress-spinner
        *ngIf="isLoading"
        class="spinner"
        [color]="'accent'"
        [mode]="'indeterminate'"
        [value]="50"
      >
      </mat-progress-spinner>
    </div>
    <button
      mat-raised-button
      color="accent"
      [disabled]="newSourceForm.invalid || isLoading"
      (click)="addNewItem()"
    >
      Add new source
    </button>
  </form>
</div>
