import { Component, EventEmitter, Output, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-conversation-dnd',
  templateUrl: './conversation-dnd.component.html',
  styleUrls: ['./conversation-dnd.component.scss'],
})
export class ConversationDndComponent implements OnDestroy {
  protected isDropAllowed = false;
  @Output() filesDropped = new EventEmitter<File[]>();
  @Output() isDragging = new EventEmitter<boolean>();

  private dragEnterListener: (event: DragEvent) => void;
  private dragLeaveListener: (event: DragEvent) => void;

  constructor() {
    this.dragEnterListener = this.onDragEnter.bind(this);
    this.dragLeaveListener = this.onDragLeave.bind(this);

    document.body.addEventListener('dragenter', this.dragEnterListener);
    document.body.addEventListener('dragleave', this.dragLeaveListener);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.filesDropped.emit(Array.from(event.dataTransfer?.files || []));
    this.stopDragOver();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    if (event.clientY === 0 && event.clientX === 0) {
      this.setIsDropAllowed(false);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    document.querySelector('.drop-area')?.classList.add('drag-over');
  }

  stopDragOver() {
    this.setIsDropAllowed(false);
    document.querySelector('.drop-area')?.classList.remove('drag-over');
  }

  setIsDropAllowed(isDragging: boolean) {
    this.isDropAllowed = isDragging;
    this.isDragging.emit(isDragging);
  }

  private onDragEnter(event: DragEvent) {
    this.setIsDropAllowed(true);
  }

  ngOnDestroy() {
    document.body.removeEventListener('dragenter', this.dragEnterListener);
    document.body.removeEventListener('dragleave', this.dragLeaveListener);
  }
}
