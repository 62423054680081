import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, Observable, of, Subject, takeUntil } from 'rxjs';

import { ContentItemType } from '@core/interfaces/ContentItemType';
import { Endpoints } from '@core/interfaces/Endpoints';
import {
  TCurrentConversation,
  TResultItem,
  TResultsBotItem,
  TResultsItem,
} from '@core/interfaces/TResultsItem';
import { TMessage, TSourceDetail } from '@core/interfaces/TMessage';
import { map, switchMap } from 'rxjs/operators';
import { CommonService } from '@shared/services/common.service';
import { ResultsApi } from '@core/api/results/api';

@Injectable({
  providedIn: 'root',
})
export class ResultsService {
  resultsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private results: BehaviorSubject<TResultsItem[]> = new BehaviorSubject<TResultsItem[]>([]);
  private result: BehaviorSubject<TResultsBotItem[]> = new BehaviorSubject<TResultsBotItem[]>([]);
  public currentConversationId: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null
  );
  public currentConversation: BehaviorSubject<TCurrentConversation> =
    new BehaviorSubject<TCurrentConversation>({} as TCurrentConversation);
  private cancelDelete$ = new Subject<void>();

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService
  ) {}

  public getResultObservable(): Observable<TResultsBotItem[]> {
    return this.result.asObservable();
  }

  public getResult(): TResultsBotItem[] {
    return this.result.value;
  }

  public getResultsLoadingObservable(): Observable<boolean> {
    return this.resultsLoading.asObservable();
  }

  public getCurrentConversationObservable(): Observable<TCurrentConversation> {
    return this.currentConversation.asObservable();
  }

  public getResults(): TResultsItem[] {
    return this.results.value;
  }

  public getCurrentConversation(): TCurrentConversation {
    return this.currentConversation.value;
  }

  public addNewItem(newItem: TResultsItem): void {
    this.results.next([...this.getResults(), newItem]);
  }

  public fetchResultsByBot(): void {
    this.resultsLoading.next(true);
    const response = this._http.get<TResultsBotItem[]>(Endpoints.bot.fetchResultsByBot.url);
    let res;
    response.subscribe({
      error: err => {
        console.log(err);
        this.resultsLoading.next(false);
      },
      next: response => {
        res = response.map(item => {
          const children = item.conversations.map(child => {
            return {
              ...child,
              __type: ContentItemType.ResultConversation,
            };
          });
          return {
            ...item,
            group: {
              name: item.name,
              id: item.name,
            },
            children,
            title: item.name,
            _id: item._id,
            __type: ContentItemType.Results,
          };
        });
        this.resultsLoading.next(false);
        this.result.next(res);
      },
    });
  }

  fetchResultConversation(
    conversationId: string,
    callback?: (res: TCurrentConversation) => void
  ): void {
    const response = this._http.get<TCurrentConversation>(
      Endpoints.bot.fetchResultConversation.url(conversationId)
    );
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: response => {
        const data = this.appendContentEmbeddingToSourceDetails(response);
        const res = {
          ...data,
          __type: ContentItemType.ResultConversation,
        };
        this.currentConversation.next(res);
        if (callback) {
          callback(res);
        }
      },
    });
  }

  protected appendContentEmbeddingToSourceDetails(data: TCurrentConversation): any {
    data.messages?.forEach((message: TMessage) => {
      message?.sourceDetails?.forEach((sourceDetail: TSourceDetail) => {
        const content = data?.embeddingContent![sourceDetail._id]
          ? data?.embeddingContent![sourceDetail._id][0].content
          : null;
        if (content) {
          sourceDetail.embeddingContent = content;
        }
      });
    });
    delete data.embeddingContent;
    return data;
  }

  public deleteConversation(id: string, callback: () => void): void {
    const response = this._http.delete<TResultItem[]>(Endpoints.bot.deleteConversation.url(id));
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: () => {
        this.removeItemAfterDelete(id);
        callback();
      },
    });
  }

  public deleteMultiple(conversationIds: string[]): void {
    const { url, body } = ResultsApi.deleteMultiple(conversationIds);
    const response = this._http.post<TResultItem[]>(url, body);
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: () => {
        conversationIds.forEach(id => {
          this.removeItemAfterDelete(id);
        });
      },
    });
  }

  cancelableDeleteConversation(result: TResultsBotItem): void {
    this.cancelDelete$.next();
    const originalResults: TResultsBotItem[] = this.result.value;
    of(result)
      .pipe(
        map(() => {
          this.removeItemAfterDelete(result._id!);
          const snackBarSub = this._commonService
            .openSnackBar(`Conversation "${result.title}" deleted`, 'Undo', 5000)
            .onAction()
            .subscribe(() => {
              this.cancelDelete$.next();
              this.result.next(originalResults);
              snackBarSub.unsubscribe();
            });
        }),
        delay(5000),
        switchMap(() =>
          this._http.delete<TResultItem[]>(Endpoints.bot.deleteConversation.url(result._id!))
        ),
        takeUntil(this.cancelDelete$)
      )
      .subscribe({
        error: err => {
          console.log(err);
        },
      });
  }

  private removeItemAfterDelete(id: string) {
    const res = this.result.value.map(item => {
      return {
        ...item,
        conversations: item.conversations.filter(c => c._id !== id),
        children: item.conversations.filter(c => c._id !== id),
      };
    });
    this.result.next(res);
    this.clearCurrentConversation();
  }

  public clearCurrentConversation(): void {
    this.currentConversationId.next(null);
  }
}
