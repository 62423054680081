import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Token, User } from './interface';
import { Menu, TokenService } from '@core';
import { map } from 'rxjs/operators';
import { Endpoints, TUser, TUserLoginBody } from '@core/interfaces/Endpoints';
import { environment } from '@env/environment';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    protected http: HttpClient,
    private _tokenService: TokenService
  ) {}

  login(username: string, password: string, rememberMe = false) {
    const url = `${environment.backendURL}${Endpoints.user.login.url}`;
    const body: TUserLoginBody = {
      password,
      username,
    };
    return this.http.post<{ token?: string, requires2FA: boolean, userId: string }>(url, body);
  }

  refresh(params: Record<string, any>) {
    return this.http.post<Token>('/auth/refresh', params);
  }

  verify2FaCode(userId: string, token: string){
    return this.http.post<{ token:string }>(`${environment.backendURL}/api/auth/verify-2fa`, {
      userId,
      token
    });
  }

  logout() {
    //Handle logout on backend
    return of({});
  }

  me(username: string | undefined): Observable<User> {
    let url = ``;
    if (isNullOrUndefined(username)) {
      url = `${environment.backendURL}${Endpoints.user.readUserByUsername.url(
        this._tokenService.getUsername()
      )}`;
    } else {
      url = `${environment.backendURL}${Endpoints.user.readUserByUsername.url(username)}`;
    }
    return this.http.get<TUser<string>>(url).pipe(
      map(user => {
        const frontUser: User = {
          avatar:
            (user.metadata.find(it => it.slug === `avatar`)?.value as string) ??
            `./assets/images/default-user.png`,
          name: user.username,
          is2FAEnabled: user.is2FAEnabled,
          roles: user.roles,
          displayName: user.metadata.find(it => it.slug === `display_name`)?.value as string || '',
          metadata: user.metadata,
        };
        return frontUser;
      })
    );
  }

  menu() {
    return this.http
      .get<{ menu: Menu[] }>('assets/data/menu.json?_t=' + Date.now())
      .pipe(map(res => res.menu));
  }
}
