import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TBotItem } from '@core/interfaces/TBotItem';


@Component({
  selector: 'app-bot-context-menu',
  templateUrl: './bot-context-menu.component.html',
  styleUrls: ['./bot-context-menu.component.scss'],
})
export class BotContextMenuComponent {
  @Input({ required: true }) bot: Pick<TBotItem, 'botType'> | undefined;
  @Output() public edit = new EventEmitter();
  @Output() public history = new EventEmitter();
  @Output() public promptHints = new EventEmitter();
  @Output() public delete = new EventEmitter();
  @Output() public duplicate = new EventEmitter();
}
