import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentComponent } from '@shared/components/file-preview-modal/drivers/document/document.component';
import { SourcesMaterialsApi } from '@core/api/materials/api';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss'],
})
export class PreviewModalComponent implements AfterViewInit {
  @ViewChild('dynamicContainer', { read: ViewContainerRef }) container:
    | ViewContainerRef
    | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { filename: string },
    private dialogRef: MatDialogRef<PreviewModalComponent>,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.createComponent();
  }

  createComponent() {
    const componentRef = this.container?.createComponent(DocumentComponent);
    if (componentRef) {
      componentRef.instance.fileLink = this.getLink(this.data.filename!);
      this.cdr.detectChanges();
    }
  }

  protected getLink(fileName: string) {
    return SourcesMaterialsApi.downloadFile(fileName).url;
  }

  cancel() {
    this.dialogRef.close();
  }
}
