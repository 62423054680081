<div class="new-item-modal ecr-modal">
  <form class="form-field-full" [formGroup]="newFolderForm">
    <div class="form-inputs">
      <mat-form-field class="gingr-input-wrapper w-full">
        <input
          matInput
          placeholder="Folder name"
          type="text"
          formControlName="name"
          required />
      </mat-form-field>

      <mat-form-field class="gingr-input-wrapper w-full">
        <mat-select formControlName="status" placeholder="Select status">
          <mat-option *ngFor="let st of statuses" [value]="st">
            {{ st | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div class="overflow-y-auto projects-container">
    <h3 class="mat-h3">Select RISE project</h3>
    <mat-radio-group *ngIf="!isLoading; else loadingBlock" (change)="selectProject($event)">
      <div *ngFor="let category of ECRProjectsCategories" class="m-t-24 category">
        {{category.name}}

        <ng-container *ngFor="let project of category.projects">
          <mat-radio-button [value]=project>{{project.name}}</mat-radio-button>
        </ng-container>

      </div>
    </mat-radio-group>

    <ng-template #loadingBlock>
      <div class="d-flex align-items-center justify-content-center">
        <mat-progress-spinner
          *ngIf="isLoading"
          class="spinner"
          [color]="'accent'"
          [mode]="'indeterminate'"
          [value]="50">
        </mat-progress-spinner>
      </div>
    </ng-template>
  </div>

  <div class="m-t-48 d-flex justify-content-between controls">
    <button
      class="d-flex align-items-center justify-start m-t-16"
      mat-raised-button
      color="accent"
      (click)="cancel()">
      Close
    </button>
    <button
      class="d-flex align-items-center justify-start m-t-16"
      mat-raised-button
      color="accent"
      (click)="handleNextClick()"
      [disabled]="!selectedProject.id || newFolderForm.invalid">
      Next
    </button>
  </div>
</div>
