import { environment } from '@env/environment';

import { TMetadata } from '@core/api/metadata/types';
import { GetRequestData, PostRequestData } from '@core/api/utils';

export class MetadataApi {
  static set(body: TMetadata<any>): PostRequestData<TMetadata<any>> {
    return {
      url: `${environment.backendURL}/api/metadata`,
      body,
    };
  }

  static get(slug: string): GetRequestData {
    return {
      url: `${environment.backendURL}/api/metadata/${slug}`,
    };
  }
}
