<div class="new-item-modal">
  <h3 class="mat-h3">Choose one of the following sources to import data</h3>
  <div class="explanation">For Rise and Confluence you have to set credentials in
    <span class="link" (click)="goToSettings()">API Settings</span> page.
  </div>


  <div class="options m-t-48">
    <button
      mat-raised-button
      color="accent"
      (click)="handleOption1Click('ecr')"
    >
      RISE
    </button>

    <button
      mat-raised-button
      color="accent"
      (click)="handleOption1Click('confluence')"
    >
      Confluence
    </button>

    <button
      mat-raised-button
      color="accent"
      (click)="handleOption1Click('manual')"
    >
      Manually
    </button>
  </div>
</div>
