<mat-drawer-container class="gingr-content">
  <mat-drawer class="gingr-content__drawer" #drawer [opened]="drawerOpened" mode="side">
    <div class="gingr-content__drawer__header">
      <label>{{ title }}</label>
      <button mat-icon-button *allowedRole="'admin'" (click)="addNewItem()" color="accent">
        <mat-icon>add_box</mat-icon>
      </button>
    </div>

    <ng-container>
      <ul class="gingr-content__drawer-content gingr-content__drawer-content--items">
        <li
          *ngFor="let item of items"
          class="gingr-content__drawer-content__item"
          [ngClass]="{ selected: selectedItem?._id === item._id }"
        >
          <span class="gingr-content__drawer-content__item" (click)="selectItem(item)">
            <ng-container *ngIf="hasIcon(item)">
              <ng-container *ngIf="isCustomIcon(item)">
                <mat-icon
                  class="menu-icon"
                  [ngClass]="getItemCss(item)"
                  svgIcon="{{ item.icon }}"
                ></mat-icon>
              </ng-container>
              <ng-container *ngIf="!isCustomIcon(item)">
                <mat-icon class="menu-icon" [ngClass]="getItemCss(item)">{{ item.icon }}</mat-icon>
              </ng-container>
            </ng-container>
            <span class="item-title">{{ item.title }}</span>
            <span *ngIf="hasSufix(item)" class="gingr-content__drawer-content__item__sufix">
              {{ item.sufix }}
            </span>
          </span>
          <div class="d-flex">
            <span
              *ngIf="canDisplayItemInfo(item)"
              class="d-flex gingr-content__drawer-content__item__sufix delete-icon"
            >
              <mat-icon class="icon" matTooltip="{{ item.description }}">info</mat-icon>
            </span>
            <app-bot-status-badge
              class="m-r-8"
              *ngIf="type === ContentItemType.Bots"
              [botType]="item.botType"
              [botStatus]="item.bot_status"
            >
            </app-bot-status-badge>
            <span
              *ngIf="canDeleteItem(item)"
              class="d-flex gingr-content__drawer-content__item__sufix delete-icon"
            >
              <ng-container *allowedRole="'admin'">
                <mat-icon
                  class="icon"
                  *ngIf="type !== ContentItemType.Bots"
                  (click)="openDeleteItemModal(item)"
                  >delete</mat-icon
                >
              </ng-container>
            </span>
          </div>
        </li>
      </ul>
    </ng-container>
  </mat-drawer>
  <mat-drawer-content class="gingr-content__drawer-content">
    <ng-container *ngIf="isProjectsContent()">
      <app-projects-content [selectedItem]="selectedItem"></app-projects-content>
    </ng-container>

    <button class="drawer-toggle" color="secondary" mat-raised-button (click)="toggleDrawer()">
      <mat-icon *ngIf="drawerOpened">keyboard_arrow_left</mat-icon>
      <mat-icon *ngIf="!drawerOpened">keyboard_arrow_right</mat-icon>
    </button>
  </mat-drawer-content>
</mat-drawer-container>
