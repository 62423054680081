import { Injectable } from '@angular/core';
import {
  IFileExportDriver,
  TExportOptions,
  TFileExportConfiguration,
  TFileExportDriversAvailable,
} from '@shared/services/file-export/file-export-interface';

import { StringUtils } from '@shared/utils/stringUtils';
import { CommonService } from '@shared/services/common.service';
import { ExportFilesFactory } from '@shared/services/file-export/drivers';

@Injectable({
  providedIn: 'root',
})
export class FileExportService {

  private pages: string[] = [];
  private driver: IFileExportDriver | undefined;

  constructor(private commonService: CommonService) {
  }

  protected setPages(pages: string[] | undefined): FileExportService {
    if (pages) {
      this.pages = pages;
    }
    return this;
  }

  protected setDriver(driver: TFileExportDriversAvailable): FileExportService {
    this.driver = ExportFilesFactory.instantiateDriver(driver);
    return this;
  }

  protected generateFile(options: TExportOptions, filename = 'results') {
    if (!this.driver) {
      throw new Error('No export driver selected');
    }
    this.commonService.setIsLoading(true);
    this.driver?.setLogo(StringUtils.brandResource('logoFile'))
      .then(() => {
        this.driver!
          .setOptions(options)
          .setPages(this.pages)
          .generate(filename);
        this.driver!.cleanUp();
        this.commonService.setIsLoading(false);
      });
  }

  public getFile(data: TFileExportConfiguration, filename?: string) {
    this.setPages(data.pages)
      .setDriver(data.driver)
      .generateFile(data.options, filename);
  }
}

