export enum Icons {
  TestIcon = 'test-icon',
  Bots = 'bots',
  Results = 'results',
  Projects = 'projects',
  Sources = 'sources',
  New = 'new',
  ResultsNoBG = 'results-nobg',
  BotLogo = 'bot_logo',
  BotEyeSmall = 'bot-eye-small',
  ProjectsNobg = 'projects-nobg',
  Configuration = 'configuration',
  AIInstructions = 'AI-Instructions',
  Training = `training`,
  users = `users`,
}
