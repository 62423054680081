import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { TInnerBot } from '@core/interfaces/TBot';

import { BotsService } from '@shared/services/bots.service';
import { innerBotMessages } from '@shared/utils/messages';

import { BotConfigHistoryModalComponent } from '../modals/bot-config-history-modal/bot-config-history-modal.component';

@Component({
  selector: 'app-inner-bot-modal',
  templateUrl: './inner-bot-modal.component.html',
  styleUrls: ['./inner-bot-modal.component.scss'],
})
export class InnerBotModalComponent implements OnInit {
  public newBotForm: FormGroup;
  public innerBotMessages = innerBotMessages;
  public inputsConfig = {
    top_p: { min: 0, max: 1, step: 0.1 },
    temperature: { min: 0, max: 2, step: 0.1 },
    presence_penalty: { min: -2, max: 2, step: 0.1 },
    frequency_penalty: { min: -2, max: 2, step: 0.1 },
  };

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<InnerBotModalComponent>,
    private _botService: BotsService,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { botCreatorId: string; bot?: TInnerBot }
  ) {
    this.newBotForm = this.fb.nonNullable.group({
      name: ['', [Validators.required]],
      description: [''],
      preSearch: [''],
      behaviour: [''],
      instruction: [''],
      config: [''],
      top_p: [0.5],
      temperature: [0.7],
      presence_penalty: [0],
      frequency_penalty: [0],
      display_resources: [true],
    });
  }

  public ngOnInit(): void {
    if (this.data.bot) {
      this.newBotForm.patchValue({
        name: this.data.bot.name,
        description: this.data.bot.description,
        preSearch: this.data.bot.preSearch,
        instruction: this.data.bot.instruction,
        behaviour: this.data.bot.behaviour,
        config: this.data.bot.config,
        top_p: this.data.bot.top_p,
        temperature: this.data.bot.temperature,
        presence_penalty: this.data.bot.presence_penalty,
        frequency_penalty: this.data.bot.frequency_penalty,
        display_resources: this.data.bot.display_resources,
      });
    }
  }

  public get name() {
    return this.newBotForm.get('name')!;
  }

  public get description() {
    return this.newBotForm.get('description')!;
  }

  public get behaviour() {
    return this.newBotForm.get('behaviour')!;
  }

  public get preSearch() {
    return this.newBotForm.get('preSearch')!;
  }

  public get instruction() {
    return this.newBotForm.get('instruction')!;
  }

  public get config() {
    return this.newBotForm.get('config')!;
  }

  public get top_p() {
    return this.newBotForm.get('top_p')!;
  }

  public get temperature() {
    return this.newBotForm.get('temperature')!;
  }

  public get presence_penalty() {
    return this.newBotForm.get('presence_penalty')!;
  }

  public get frequency_penalty() {
    return this.newBotForm.get('frequency_penalty')!;
  }

  public addOrUpdateBot(): void {
    const fv = this.newBotForm.value;
    const requestBody = {
      name: fv.name || '',
      description: fv.description,
      instruction: fv.instruction,
      preSearch: fv.preSearch,
      behaviour: fv.behaviour,
      config: fv.config,
      top_p: fv.top_p,
      temperature: fv.temperature,
      presence_penalty: fv.presence_penalty,
      frequency_penalty: fv.frequency_penalty,
      display_resources: fv.display_resources,
    };
    if (this.data.bot) {
      this._botService.editBot({ id: this.data.bot._id, ...requestBody }, () =>
        this.dialogRef.close()
      );
    } else {
      this._botService.addInnerBot(this.data.botCreatorId, requestBody, () =>
        this.dialogRef.close()
      );
    }
  }

  formatTopP(value: number): string {
    return `${Math.round(value * 100)}%`;
  }

  formatSlider(value: number): string {
    return `${Math.round((value * 100) / 2)}%`;
  }

  protected showHistoryModal() {
    this._dialog.open(BotConfigHistoryModalComponent, {
      data: {
        bot: this.data.bot,
        fieldSet: 'innerBot',
      },
    });
  }
}
