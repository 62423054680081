import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Language } from '@core/interfaces/language';

import { TSourceDetail } from '@core/interfaces/TMessage';

import { isOrderedSource, ReferencesUtils, TOrderedSource } from '@shared/utils/referencesUtils';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss'],
})
export class ReferencesComponent implements OnChanges {
  @Input() lang: Language | undefined = 'en';

  @Input() set sources(sources: TSourceDetail[] | TOrderedSource[]) {
    if (isOrderedSource(sources[0])) {
      this.orderedSources = sources as TOrderedSource[];
    } else {
      this.referenceSources = sources as TSourceDetail[];
    }
  }

  @Input() isNumbered = false;
  orderedSources: TOrderedSource[] = [];
  referenceSources: TSourceDetail[] = [];

  referenceLabel = '';

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.lang?.previousValue !== changes.lang?.currentValue) {
      this.referenceLabel = ReferencesUtils.getReferenceLabelForLanguage(this.lang);
    }
  }

  getSourceLink(source: TSourceDetail): string {
    return ReferencesUtils.getLink(source);
  }
}
