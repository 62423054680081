<div class="{{ asSingle ? 'as-single' : 'container' }}">
  <div *ngIf="material?.fileType === 'video'">
    <app-video-player [videoLink]="linkToFile()"></app-video-player>
  </div>

  <div *ngIf="material?.fileType === 'image'">
    <app-image-viewer [imageLink]="linkToFile()"></app-image-viewer>
  </div>

  <div *ngIf="material?.fileType === 'youtube'">
    <app-youtube-player [link]="material?.url"></app-youtube-player>
  </div>

  <div *ngIf="material?.fileType === 'file'">
    <app-file-download [link]="linkToFile()" [fileName]="material?.fileLink"></app-file-download>
  </div>

  <div *ngIf="material?.fileType === 'link'">
    <app-link [url]="material?.url!"></app-link>
  </div>

  <p class="m-0">{{ material?.name }}</p>
</div>
