<h3 class="p-24 p-l-32 mat-h3">Edit your profile</h3>
<div class="p-24 p-t-0 d-flex flex-col align-items-center w-full">
  <form class="form-field-full" [formGroup]="editProfileForm">
    <h4 class="m-t-0">Your name / e-mail</h4>
    <mat-form-field class="gingr-input-wrapper w-full">
      <input
        matInput
        type="text"
        formControlName="name"
        readonly />
    </mat-form-field>
    <div matSuffix class="m-b-24 w-full suffix">
      You cannot change your name / e-mail. If you want to change name of your account visible in the app, please change
      your display name.
    </div>

    <h4 class="p-t-16">Your display name</h4>
    <mat-form-field class="gingr-input-wrapper w-full">
      <input
        matInput
        placeholder="Type your display name"
        type="text"
        formControlName="displayName"
      />
    </mat-form-field>
    <button
      class="m-b-24"
      mat-raised-button
      color="accent"
      [disabled]="isDisplayNameEditingDisabled"
      (click)="saveField(allowedUserFormFields.DISPLAY_NAME, true)">
      Update display name
    </button>

    <h4>Two factor authorization</h4>
    <div class="d-flex m-t-12">
      <toggle-user-two-factor-auth [user]="user"></toggle-user-two-factor-auth>
    </div>


    <h4>Your password</h4>
    <mat-form-field class="gingr-input-wrapper w-full">
      <mat-label>Old password</mat-label>
      <input
        matInput
        type="password"
        formControlName="oldPassword"
      />
    </mat-form-field>
    <mat-error *ngIf="oldPassword.errors?.incorrect">
      <span>Old password is not correct.</span>
    </mat-error>
    <mat-form-field class="gingr-input-wrapper w-full">
      <mat-label>New password</mat-label>
      <input
        matInput
        type="password"
        formControlName="newPassword"
      />
    </mat-form-field>
    <mat-form-field class="gingr-input-wrapper w-full">
      <mat-label>Repeat new password</mat-label>
      <input
        matInput
        type="password"
        formControlName="newPasswordRepeated"
      />
    </mat-form-field>
    <mat-error *ngIf="newPasswordRepeated.errors">
      <span>{{newPasswordRepeated.errors[0]}}</span>
    </mat-error>
    <mat-error *ngIf="newPasswordRepeated.errors?.notEqual">
      <span>Password fields don't match.</span>
    </mat-error>
    <mat-error *ngIf="newPasswordRepeated.errors?.notValid">
      <span>The password must contain between 6 and 16 characters, at least 1 number and 1 special character.</span>
    </mat-error>
    <button
      class="m-b-24"
      mat-raised-button
      color="accent"
      [disabled]="oldPassword.errors || newPasswordRepeated.errors"
      (click)="savePassword()">
      Update password
    </button>
  </form>
</div>
