import { Component, Input, OnDestroy } from '@angular/core';
import { MetadataService, MetadataSlugs } from '@shared/services/metadata.service';
import { Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { TBotItem } from '@core/interfaces/TBotItem';
import { VoiceEmotion } from '@heygen/streaming-avatar';

@Component({
  selector: 'app-talking-avatar-settings',
  templateUrl: './talking-avatar-settings.component.html',
  styleUrls: ['./talking-avatar-settings.component.scss'],
})
export class TalkingAvatarSettingsComponent implements OnDestroy {
  private subs: Subscription[] = [];
  protected avatarConfigForm = this.fb.group({
    isAvailable: [false],
    avatarId: [''],
    voiceRate: [1],
    voiceEmotion: [VoiceEmotion.BROADCASTER],
  });
  protected botItem: TBotItem | undefined;
  protected voiceEmotions = Object.values(VoiceEmotion);
  @Input({ required: true })
  public set bot(bot: TBotItem) {
    this.botItem = bot;
    this.subs.push(
      this._metadataService.get<string>(this.getMetadataKey()).subscribe(metadata => {
        if (metadata.value) {
          const avatarConfig = JSON.parse(metadata.value);
          this.avatarConfigForm.patchValue(avatarConfig);
        }
      })
    );
  }

  constructor(
    private fb: FormBuilder,
    private _metadataService: MetadataService
  ) {}

  storeTalkingAvatarConfig() {
    const avatarVal = this.avatarConfigForm.value;
    this.subs.push(
      this._metadataService.set(this.getMetadataKey(), JSON.stringify(avatarVal)).subscribe()
    );
  }

  private getMetadataKey() {
    return `${MetadataSlugs.TalkingAvatarSettings}_${this.botItem?._id}`;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
