<div class="row align-items-center justify-content-between m-b-24">
  <h3 class="mat-h3 m-b-0">Project's sources</h3>
  <ng-container *allowedRole="'admin'">
    <button
      *ngIf="projectFolders.length"
      mat-raised-button
      class="m-l-16 control"
      color="accent"
      (click)="openAllFoldersListModal()"
    >
      Edit project sources
    </button>
  </ng-container>
</div>
<ng-container *ngIf="projectFolders.length; else noFolders">
  <div
    *ngFor="let folder of projectFolders"
    class="d-flex align-items-center m-b-24 cursor-pointer"
    (click)="navigateToFolder(folder._id)"
  >
    <mat-icon class="m-r-12" svgIcon="sources"></mat-icon>
    <div>{{ folder.name }}</div>
  </div>
</ng-container>
<ng-template #noFolders>
  <div class="empty-container">
    <mat-icon svgIcon="sources" class="m-b-48 empty-icon"></mat-icon>
    <div class="empty-list-indicator">
      There are no sources assigned to this project. Click button below to change it.
    </div>
    <button mat-raised-button class="m-t-48" color="accent" (click)="openAllFoldersListModal()">
      Add project sources
    </button>
  </div>
</ng-template>
