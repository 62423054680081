export interface TGlobalInstruction {
  content: string;
  name: string;
  keywords: string,
  status: GlobalInstructionsStatusEnum,
  embedding?: string[],
  _id?: string
}

export enum GlobalInstructionsStatusEnum {
  Active = 'active',
  Inactive = 'inactive'
}

export const globalInstructionsStatuses = Object.values(GlobalInstructionsStatusEnum);
