<div class="new-item-modal">
    <h3 class="mat-h3">Add new metadata</h3>
    <form class="form-field-full" [formGroup]="newSourceMetadataForm">
        <div class="form-inputs">
            <mat-form-field class="gingr-input-wrapper w-full">
                <input
                       matInput
                       placeholder="Slug"
                       type="text"
                       formControlName="slug"
                       required />
                <mat-error *ngIf="slug.invalid">
                    <span *ngIf="slug.errors?.required">Please enter source slug
                    </span>
                </mat-error>
            </mat-form-field>


            <mat-form-field class="gingr-input-wrapper w-full">
                <input
                       matInput
                       placeholder="Value"
                       type="text"
                       formControlName="value"
                       required />
                <mat-error *ngIf="value.invalid">
                    <span *ngIf="value.errors?.required">Please provide value
                    </span>
                </mat-error>
            </mat-form-field>
        </div>
        <button
                class=""
                mat-raised-button
                color="accent"
                [disabled]="!!newSourceMetadataForm.invalid"
                (click)="addNewItem()">
            Add new source
        </button>
    </form>
</div>
