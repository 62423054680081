import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { CommonService } from '@shared/services/common.service';
import { ECRService } from '@shared/services/ecr.service';
import { MetadataService, MetadataSlugs } from '@shared/services/metadata.service';
import { ConfluenceService } from '@shared/services/integrations/confluence.service';

@Component({
  selector: 'app-api-access-settings',
  templateUrl: './api-access-settings.component.html',
  styleUrls: ['./api-access-settings.component.scss'],
})
export class ApiAccessSettingsComponent implements OnInit, OnDestroy {
  private _subs: Subscription[] = [];
  protected editECRAccessForm = this.fb.nonNullable.group({
    name: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });
  protected confluenceSettings = this.fb.nonNullable.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
    url: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private _commonService: CommonService,
    private _ecrService: ECRService,
    private _metadataService: MetadataService,
    private _confluenceService: ConfluenceService
  ) {}

  ngOnInit(): void {
    this._ecrService.fetchECRCredentials();
    this._subs.push(
      this._ecrService.getECRCredentialsObservable().subscribe(result => {
        this.editECRAccessForm.patchValue({
          name: result?.name,
          password: result?.password,
        });
      })
    );
    this._subs.push(
      this._metadataService.get<string>(MetadataSlugs.ConfluenceSettings).subscribe({
        next: metadata => {
          if (metadata.value) {
            const confluenceSettings = JSON.parse(metadata.value);
            this.confluenceSettings.patchValue(confluenceSettings);
          }
        },
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  saveECRAccessSettings(): void {
    const name = this.editECRAccessForm.controls.name.value;
    const password = this.editECRAccessForm.controls.password.value;
    this._ecrService.updateECRCredentials(name, password, () =>
      this._commonService.openSnackBar('RISE credentials are successfully edited')
    );
  }

  saveConfluenceSettings() {
    const form = this.confluenceSettings.controls;
    if (form.username.invalid || form.password.invalid || form.url.invalid) {
      this._commonService.openSnackBar('Please fill all fields');
      return;
    }
    this._confluenceService.saveConfluenceSettings(
      {
        username: form.username.value,
        password: form.password.value,
        url: form.url.value,
      },
      () => {
        this._commonService.openSnackBar('Confluence settings stored successfully');
      }
    );
  }
}
