import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  public forgotPasswordForm = this.fb.nonNullable.group({
    email: ['', [Validators.required, Validators.email]],
  });
  public isSubmitting = false;
  public emailSent = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private translate: TranslateService
  ) {}

  public get email() {
    return this.forgotPasswordForm.get('email')!;
  }

  public getCheckYourEmailDescription(): string {
    const results = this.translate.instant('check_your_email_description', {
      email: this.email.value,
    });
    return results;
  }

  public sendRemind(): void {
    this.emailSent = true;
  }
}
