import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Subscription } from 'rxjs';

import { TECRProject, TECRProjectCategory } from '@core/interfaces/TECR';

import { CommonService } from '@shared/services/common.service';
import { ECRService } from '@shared/services/ecr.service';

import { allowedStatuses } from '../../../../enums/status';
import { TileOptionModalComponent } from '../tile-option-modal/tile-option-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-ecr-data-modal',
  templateUrl: './new-ecr-data-modal.component.html',
  styleUrls: ['./new-ecr-data-modal.component.scss'],
})
export class NewECRDataModalComponent implements OnInit, OnDestroy {
  ECRProjectsCategories: TECRProjectCategory[] = [];
  selectedProject: TECRProject = {} as TECRProject;
  statuses = allowedStatuses;
  isLoading = true;
  private _subs: Subscription[] = [];

  public newFolderForm = this.fb.nonNullable.group({
    name: ['', [Validators.required]],
    status: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<NewECRDataModalComponent>,
    private _commonService: CommonService,
    private _ecrService: ECRService,
    private router: Router,
  ) {}

  ngOnInit() {
    this._ecrService.fetchECRProjectCategories();
    this._subs.push(
      this._commonService.getIsLoadingObservable().subscribe(results => {
        this.isLoading = results;
      })
    );
    this._subs.push(
      this._ecrService.getECRProjectCategoriesObservable().subscribe(categories => {
        this.ECRProjectsCategories = categories;
      })
    );
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  selectProject(event: MatRadioChange) {
    this.selectedProject = event.value;
  }

  handleNextClick(): void {
    this.dialogRef.close();
    const ECROptionDialogRef = this.dialog.open(TileOptionModalComponent, {
      data: {
        actionLabel: 'Fetch data from RISE',
        option1: 'PubMed articles',
        option2: 'ClinicalTrials.gov',
      },
    });
    const handleActionClickSubscription =
      ECROptionDialogRef.componentInstance.handleActionClick.subscribe(res => {
        ECROptionDialogRef.close();
        handleActionClickSubscription.unsubscribe();
        const folderName = this.newFolderForm.get('name')?.value;
        const folderStatus = this.newFolderForm.get('status')?.value;
        const ECRSource = res === 'option1' ? 'pubmed' : 'clinicalTrials';
        if (folderName) {
          this._ecrService.fetchECRProjectData(
            folderName,
            folderStatus,
            this.selectedProject.id,
            ECRSource,
            (folderID) => {
              this._commonService.openSnackBar(
                `Data from ECR project ${this.selectedProject.name} starts to processing`
              );
              this.router.navigate(['/sources', { folder_id: folderID }]);
            }
          );
        }
      });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
