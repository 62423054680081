import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import {
  ConfirmationModalComponent,
} from '../../../../components/modals/confirmation-modal/confirmation-modal.component';
import {
  GeneralInstructionsModalComponent,
} from '../../../../components/modals/general-instructions-modal/general-instructions-modal.component';
import { GlobalInstructionsStatusEnum, TGlobalInstruction } from '@core/api/global-instructions/types';
import { CommonService } from '@shared/services/common.service';
import { GlobalInstructionsService } from '@shared/services/global-instructions.service';

@Component({
  selector: 'app-global-instructions',
  templateUrl: './global-instructions.component.html',
  styleUrls: ['./global-instructions.component.scss'],
})
export class GlobalInstructionsComponent implements OnInit, OnDestroy {
  protected instructions: TGlobalInstruction[] | [] = [];
  private _subs: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private _globalInstructionService: GlobalInstructionsService,
    private _commonService: CommonService,
  ) {
  }

  ngOnInit(): void {
    this.fetch();
  }

  fetch() {
    this._subs.push(this._globalInstructionService.fetch().subscribe(response => {
      this.instructions = response;
    }));
  }

  createModal() {
    const modalRef = this.dialog.open(GeneralInstructionsModalComponent, { data: null });
    this._subs.push(modalRef
      .componentInstance
      .changed
      .subscribe((formItem: TGlobalInstruction) => {
          this._globalInstructionService.create(formItem).subscribe({
            next: (newItem: TGlobalInstruction) => {
              // this.instructions = [...this.instructions!, newItem]; //for unknown reason we're not receiving _id from service
              this.fetch();
              this._commonService.openSnackBar('Successful created');
            },
            error: (err: any) => {
              console.error(err);
              this._commonService.openSnackBar('Create failed');
            },
            complete: () => {
              modalRef.componentInstance.enableForm();
              modalRef.close();
            },
          });
        },
      ),
    );
  }

  openEditModal(item: TGlobalInstruction) {
    const modalRef = this.dialog.open(GeneralInstructionsModalComponent, { data: item });
    this._subs.push(modalRef
      .componentInstance
      .changed
      .subscribe((formItem: TGlobalInstruction) => {
        this._globalInstructionService.update(formItem).subscribe({
          next: (value: TGlobalInstruction) => {
            Object.assign(item, value);
            this._commonService.openSnackBar('Successful updated');
          },
          error: (err: any) => {
            console.error(err);
            this._commonService.openSnackBar('Update failed');
          },
          complete: () => {
            modalRef.componentInstance.enableForm();
            modalRef.close();
          },
        });
      }));
  }

  openDeleteModal(item: TGlobalInstruction) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent,
      { data: { text: `Are you sure you want to delete "${item.name}"?` } },
    );
    this._subs.push(dialogRef.componentInstance.actionCallback.subscribe(() => {
        this._globalInstructionService.delete(item._id!).subscribe({
          next: () => {
            this._commonService.openSnackBar('Deleted successfully');
            this.instructions = this.instructions!.filter(instruction => instruction._id !== item._id);
            dialogRef.close();
          },
          error: (err: any) => {
            this._commonService.openSnackBar('Delete failed');
            console.error(err);
          },
        });
      },
    ));
  }

  toggleStatus(item: TGlobalInstruction) {
    const desiredStatus = item.status === GlobalInstructionsStatusEnum.Active
      ? GlobalInstructionsStatusEnum.Inactive : GlobalInstructionsStatusEnum.Active;

    this._globalInstructionService.update({ ...item, status: desiredStatus }).subscribe({
      next: (value) => {
        Object.assign(item, value);
        this._commonService.openSnackBar('Status changed successfully');
      },
      error: (err) => {
        console.error(err);
        this._commonService.openSnackBar('Change status failed');
      },
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach(value => value.unsubscribe());
  }
}
