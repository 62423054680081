<form class="form-field-full" [formGroup]="botEditorForm">
  <h3 class="mat-h3 m-t-24">{{ bot.type.replace('Bot', '') }} bot details</h3>
  <div class="row m-b-24">
    <span class="custom-label">Name</span>
    <mat-form-field class="gingr-input-wrapper w-full">
      <input matInput placeholder="Bot name" type="text" formControlName="name" required/>
    </mat-form-field>
    <span class="custom-label">Description</span>
    <mat-form-field class="gingr-input-wrapper w-full">
      <textarea
        matInput
        placeholder="Bot description"
        formControlName="description"
        rows="3"
      ></textarea>
    </mat-form-field>
    <ng-template *ngIf="bot.type !== 'quizBot'">
      <span class="custom-label d-flex align-items-center">Presearch</span>
      <mat-form-field class="gingr-input-wrapper w-full">
      <textarea
        matInput
        placeholder="Presearch"
        formControlName="preSearch"
        rows="2"
      ></textarea>
      </mat-form-field>
    </ng-template>
    <span class="custom-label d-flex align-items-center"
    >Instruction <mat-icon class="icon" matTooltip="{{ infoInstruction }}">info</mat-icon></span
    >
    <mat-form-field class="gingr-input-wrapper w-full">
      <textarea
        matInput
        placeholder="Instruction"
        formControlName="instruction"
        rows="3"
      ></textarea>
    </mat-form-field>
    <span class="custom-label d-flex align-items-center">
      Behaviour <mat-icon class="icon" matTooltip="{{ infoBehaviour }}">info</mat-icon>
    </span>
    <mat-form-field class="gingr-input-wrapper w-full">
      <textarea matInput placeholder="Behaviour" formControlName="behaviour" rows="3"></textarea>
    </mat-form-field>

    <ng-container *ngIf="['summaryBot', 'quizBot'].includes(bot.type)">
      <span class="custom-label d-flex align-items-center">
        Config <mat-icon class="icon" matTooltip="{{ botConfig }}">info</mat-icon>
      </span>
      <mat-form-field class="gingr-input-wrapper w-full">
        <textarea matInput placeholder="Config" formControlName="config" rows="3"></textarea>
      </mat-form-field>
    </ng-container>

    <span class="custom-label d-flex align-items-center"> Bot model </span>
    <mat-form-field class="gingr-input-wrapper w-full ">
      <mat-select formControlName="bot_model" placeholder="Select model">
        <mat-option *ngFor="let model of botsModels" [value]="model.id">
          {{ model.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <span class="custom-label d-flex align-items-center"> Bot Status </span>
    <mat-form-field class="gingr-input-wrapper w-full hide-sub">
      <mat-select formControlName="bot_status" placeholder="Select status">
        <mat-option *ngFor="let status of botStatuses" [value]="status">
          {{ status.replace('_', ' ') | firstUpper }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-notice
      *ngIf="
        botsTypesForcedStatus.includes(bot.type) &&
        appGlobalSettings.getSettingsValue().master_bot_status
      "
      [text]="
        'Master status (' +
        appGlobalSettings.getSettingsValue().master_bot_status +
        ') overrides status for this bot type.'
      "
    ></app-notice>
  </div>
  <ng-container *ngIf="bot.type === 'quizBot'">
    <h3 class="mat-h3 m-t-24">Quiz bot extra configurations</h3>
    <div formGroupName="extraConfigurations" class="m-b-24">
      <div formGroupName="feedback">
        <div>
          <span class="custom-label">Feedback behaviour</span>
          <mat-form-field class="gingr-input-wrapper w-full">
            <input matInput placeholder="Feedback behaviour" formControlName="behaviour"/>
          </mat-form-field>
        </div>
        <div>
          <span class="custom-label">Feedback instruction</span>
          <mat-form-field class="gingr-input-wrapper w-full">
            <input matInput placeholder="Feedback instruction" formControlName="instruction"/>
          </mat-form-field>
        </div>
        <div>
          <span class="custom-label">Feedback config</span>
          <mat-form-field class="gingr-input-wrapper w-full">
            <input matInput placeholder="Feedback config" formControlName="config"/>
          </mat-form-field>
        </div>
      </div>
      <div formGroupName="initMessage">
        <div>
          <span class="custom-label">Init message behaviour</span>
          <mat-form-field class="gingr-input-wrapper w-full">
            <input matInput placeholder="Init message behaviour" formControlName="behaviour"/>
          </mat-form-field>
        </div>
        <div>
          <span class="custom-label">Init message instruction</span>
          <mat-form-field class="gingr-input-wrapper w-full">
            <input matInput placeholder="Init message instruction" formControlName="instruction"/>
          </mat-form-field>
        </div>
        <div>
          <span class="custom-label">Init message config</span>
          <mat-form-field class="gingr-input-wrapper w-full">
            <input matInput placeholder="Init message config" formControlName="config"/>
          </mat-form-field>
        </div>
      </div>
      <div formGroupName="secondRun">
        <div>
          <span class="custom-label">Second run config</span>
          <mat-form-field class="gingr-input-wrapper w-full">
            <input matInput placeholder="Second run config" formControlName="config"/>
          </mat-form-field>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="d-flex justify-content-between">
    <div *ngIf="bot.type !== 'quizBot'">
      <span class="custom-label m-l-4 d-flex align-items-center">
        Materials <mat-icon class="icon" matTooltip="{{ materialsInfo }}">info</mat-icon>
      </span>
      <div class="d-flex option-box m-b-24">
        <mat-slider class="gingr-input-wrapper materials-input" min="0" max="10" step="1" discrete>
          <input matInput matSliderThumb formControlName="maximum_resources"/>
        </mat-slider>
        <div class="d-flex m-t-12">
          <mat-slide-toggle
            formControlName="display_resources"
            class="m-l-16"
            matTooltip="enable/disable materials"
          />
        </div>
      </div>
    </div>
    <div>
      <span class="custom-label m-l-4 d-flex align-items-center">
        Number of publications
        <mat-icon class="icon" matTooltip="{{ publicationsCountInfo }}">info</mat-icon>
      </span>
      <div class="d-flex option-box m-b-24">
        <mat-slider
          class="gingr-input-wrapper materials-input"
          min="0"
          max="{{ MAX_PUBLICATIONS_LIMIT }}"
          step="1"
          discrete
        >
          <input matInput matSliderThumb formControlName="maximum_publications"/>
        </mat-slider>
        <div class="d-flex m-t-12">
          <mat-slide-toggle
            formControlName="use_all_publications"
            class="m-l-16"
            matTooltip="use all publications"
          />
        </div>
      </div>
    </div>
  </div>

  <app-inner-bots [bot]="bot" *ngIf="bot.type === 'botCreator'"></app-inner-bots>

  <app-talking-avatar-settings [bot]="bot"></app-talking-avatar-settings>

  <h3 class="mat-h3 m-t-48">Show on home page</h3>
  <ng-container *ngIf="bot.type === 'botCreator'">
    <mat-checkbox formControlName="showOnDashboard">Show on home page</mat-checkbox>
  </ng-container>

  <div class="d-flex align-items-center justify-content-end m-t-16">
    <button
      class="action-button"
      mat-raised-button
      color="accent"
      [disabled]="botEditorForm.invalid"
      (click)="saveBotConfig()"
    >
      Save
    </button>
  </div>
</form>
