import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ContentItemType } from '@core/interfaces/ContentItemType';
import { Endpoints, TProject } from '@core/interfaces/Endpoints';
import { TAIInstruction } from '@core/interfaces/TAIInstruction';
import { TProjectConfig } from '@core/interfaces/TProjectConfig';
import { TProjectDetails, TProjectItem } from '@core/interfaces/TProjectItem';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private projects: BehaviorSubject<TProjectItem[]> = new BehaviorSubject<TProjectItem[]>([]);

  constructor(private _http: HttpClient) {}

  public getProjectsObservable(): Observable<TProjectItem[]> {
    return this.projects.asObservable();
  }

  public addConfigurationToProject(
    project: TProjectItem,
    configuration: TProjectConfig,
    callback: () => void
  ): void {
    const response = this._http.post(
      Endpoints.projects.addConfigurationToProject.url(project._id ?? ``),
      configuration
    );
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: () => {
        this.setProjects(() => callback());
      },
    });
  }

  public addInstructionToProject(
    project: TProjectItem,
    instructions: TAIInstruction,
    callback: () => void
  ): void {
    const response = this._http.post(
      Endpoints.projects.addInstructionsToProject.url(project._id ?? ``),
      instructions
    );
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: () => {
        this.setProjects(() => callback());
      },
    });
  }

  public editInstruction(
    project: TProjectItem,
    instruction: TAIInstruction,
    callback: () => void
  ): void {
    const response = this._http.put(
      Endpoints.projects.editInstruction.url(project._id ?? ``, instruction.slug),
      instruction
    );
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: () => {
        callback();
      },
    });
  }

  public deleteProject(projectId: string, callback: () => void): void {
    const response = this._http.delete(Endpoints.projects.deleteProject.url(projectId));
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: () => {
        this.projects.next(this.projects.value.filter(it => it._id !== projectId));
        callback();
      },
    });
  }

  public addNewProject(newProjectItem: TProject, callback: () => void) {
    const response = this._http.post<TProject>(
      Endpoints.projects.createProject.url,
      newProjectItem
    );
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: () => {
        this.setProjects();
        callback();
      },
    });
  }

  public setProjects(callback?: () => void): void {
    const response = this._http.get<TProject[]>(Endpoints.projects.listProjects.url);
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: response => {
        this.projects.next(this._mapper(response));
        if (callback) {
          callback();
        }
      },
    });
  }

  addBotToProject(projectId: string, botId: string, callback?: () => void): void {
    const response = this._http.post<TProjectItem>(
      Endpoints.projects.addBotToProject.url(botId, projectId),
      { botId, projectId }
    );
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: res => {
        const allProjects = this.projects.value;
        const editedProject = allProjects.findIndex(project => project._id === projectId);
        this.projects.getValue().splice(editedProject, 1, res);
        const projects = this.projects.value.map(it => ({
          ...it,
          __type: ContentItemType.Projects,
          icon: 'projects-nobg',
          title: it.name,
        }));
        this.projects.next(projects);
        callback && callback();
      },
    });
  }

  updateProjectBots(projects: TProject[], botId: string): void {
    const projectsToRemove = this.projects.value.filter(
      project => !projects.find(it => it._id === project._id)
    );
    projectsToRemove.forEach(project => {
      if (project.bots?.includes(botId)) {
        this.removeBotFromProject(project._id as string, botId);
      }
    });

    projects.forEach(project => {
      const savedProject = this.projects.value.find(it => it._id === project._id);
      if (!savedProject?.bots?.includes(botId)) {
        this.addBotToProject(project._id as string, botId);
        return;
      }
    });
  }

  removeBotFromProject(projectId: string, botId: string, callback?: () => void): void {
    const response = this._http.delete<TProjectItem>(
      Endpoints.projects.removeBotFromProject.url(botId, projectId)
    );
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: res => {
        const editedProjectIndex = this.projects
          .getValue()
          .findIndex(project => project._id === projectId);
        this.projects.getValue().splice(editedProjectIndex, 1, res);
        const projects = this.projects.value.map(it => ({
          ...it,
          __type: ContentItemType.Projects,
          icon: 'projects-nobg',
          title: it.name,
        }));
        this.projects.next(projects);
        callback && callback();
      },
    });
  }

  updateProjectDetails(
    project: TProjectItem,
    details: TProjectDetails,
    callback: () => void
  ): void {
    const response =
      project._id &&
      this._http.put<TProject>(Endpoints.projects.updateProject.url(project._id), {
        name: details.name,
      });
    response &&
      response.subscribe({
        error: err => {
          console.log(err);
        },
        next: res => {
          const editedProject = this._mapper([res])[0];
          const editedProjectIndex = this.projects.getValue().findIndex(p => p._id === project._id);
          this.projects.getValue().splice(editedProjectIndex, 1, editedProject);
          this.handleProjectDescription(editedProject, details.description, () => callback());
        },
      });
  }

  handleProjectDescription(project: TProjectItem, description: string, callback: () => void): void {
    const isDescriptionMetadata = !!project.metadata?.find(item => item.slug === 'description');
    let response;
    if (isDescriptionMetadata) {
      response =
        project._id &&
        this._http.put<{ message: string; project: TProject }>(
          Endpoints.projects.updateProjectDescription.url(project._id, 'description'),
          { name: 'description', slug: 'description', value: description }
        );
    } else {
      response =
        project._id &&
        this._http.post<{ message: string; project: TProject }>(
          Endpoints.projects.createProjectDescription.url(project._id),
          { name: 'description', slug: 'description', value: description }
        );
    }
    response &&
      response.subscribe({
        error: err => {
          console.log(err);
        },
        next: result => {
          const editedProject = this._mapper([result.project])[0];
          const editedProjectIndex = this.projects.getValue().findIndex(p => p._id === project._id);
          this.projects.getValue().splice(editedProjectIndex, 1, editedProject);
          callback();
        },
      });
  }

  private _mapper(sources: TProject[]): TProjectItem[] {
    return sources.map(it => {
      const mappedItem: TProjectItem = {
        __type: ContentItemType.Projects,
        bots: it.bots || [],
        description:
          (it.metadata?.find(item => item.slug === 'description')?.value as string) ?? '',
        icon: `projects-nobg`,
        title: it.name,
        name: it.name,
        status: it.status,
        _id: it._id,
        configuration: it.configuration,
        instructions: it.instructions,
        metadata: it.metadata,
      };
      return mappedItem;
    });
  }
}
