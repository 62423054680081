import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

import { TBotItem } from '@core/interfaces/TBotItem';
import { TSearchResponseItem } from '@core/interfaces/TSearch';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { SearchService } from '@shared/services/search.service';
import { SourcesService } from '@shared/services/sources.service';
import { initialPaging } from '@shared/utils/paging';
import { ConversationsService } from '@shared/services/conversations.service';
import { BotConversationAdapter } from '@shared/services/bots/adapters/BotConversationAdapter';
import { BotMessagesService } from '@shared/services/bots/bot-messages.service';

@Component({
  selector: 'app-summary-bot',
  templateUrl: './summary-bot.component.html',
  styleUrls: ['./summary-bot.component.scss'],
})
export class SummaryBotComponent implements OnInit, OnDestroy {
  @Input() bot: TBotItem = {} as TBotItem;
  isSearchLoading = false;
  sourcesIds: string[] = [];
  selectedSource: TSourceItem[] = [];
  private _subs: Subscription[] = [];
  private sourcesIdsChanged = new BehaviorSubject<boolean>(false);
  searchResults: TSearchResponseItem<TSourceItem> = {} as TSearchResponseItem<TSourceItem>;
  paging = initialPaging;

  public searchForm = this.fb.nonNullable.group({
    query: [''],
  });

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _searchService: SearchService,
    private _sourceService: SourcesService,
    private _conversationService: ConversationsService,
    private botMessageService: BotMessagesService
  ) {}

  ngOnInit(): void {
    this.botMessageService.reset();
    this.botMessageService.setAdapter(new BotConversationAdapter());

    this.route.queryParams.subscribe(params => {
      this.sourcesIds = [];
      if ((params.source || params.sources) && params.action === 'summary') {
        this.sourcesIds = Array.isArray(params.sources)
          ? params.sources
          : [params.source || params.sources];
        this.sourcesIdsChanged.next(true);
      }
    });

    this._subs.push(
      this.sourcesIdsChanged.subscribe(hasChanged => {
        if (hasChanged) {
          this.getSummary();
        }
      })
    );

    this._subs.push(
      this._searchService.getSearchLoadingObservable().subscribe(res => {
        this.isSearchLoading = res;
      })
    );

    this._subs.push(
      this._conversationService.getCurrentConversationObservable().subscribe(res => {
        if (res) {
          this.botMessageService.appendMessageViaAdapter(res);
        }
      })
    );

    this._subs.push(
      this._searchService.getSearchResponseObservable().subscribe(res => {
        if (res) {
          this.searchResults = res.sources;
          this.paging = { totalResults: res.totalResults ?? 0, page: res.page ?? 1 };
        }
      })
    );
  }

  public getSummary(): void {
    this.botMessageService.addMessage('user', `Summary of:   \n`);
    this.botMessageService.addMessage('assistant', '');
    this.botMessageService.toggleIsWritingStatus(true);
    this.sourcesIds?.forEach(sourceId => {
      this._sourceService.getSource(sourceId, (res: TSourceItem) => {
        this.selectedSource.push(res);
        this.botMessageService.updateLastMessage('user', `   - ${res.name}   \n`);
      });
    });

    this._conversationService.getAskBotAsStream(
      {
        botId: this.bot._id,
        body: { sourcesIds: this.sourcesIds },
      },
      () => {
        this.botMessageService.toggleIsWritingStatus(false);
      }
    );
  }

  public search(): void {
    if (this.searchForm.get('query')?.value !== '') {
      this._searchService.search(
        'sources',
        this.searchForm.get('query')?.value ?? '',
        this.paging.page
      );
    }
  }

  public clearSearch(): void {
    this.searchForm.get('query')?.setValue('');
    this.searchResults = {} as TSearchResponseItem<TSourceItem>;
    this.paging = initialPaging;
    this.sourcesIds = [];
    this.selectedSource = [];
  }

  public closeSummary(): void {
    this.clearSearch();
  }

  handlePageEvent(e: PageEvent) {
    this.paging.page = e.pageIndex + 1;
    this._searchService.search(
      'sources',
      this.searchForm.get('query')?.value ?? '',
      this.paging.page
    );
  }

  public makeSourceSummary(sourceId: string): void {
    this.sourcesIds.push(sourceId);
    this.getSummary();
  }

  ngOnDestroy(): void {
    this._searchService.clearSearch();
    this.botMessageService.reset();
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
