import { ReferencesUtils } from '@shared/utils/referencesUtils';
import { TAdaptedBotConversation, TAdaptedMessage } from '@shared/services/bots/types/types';
import { BehaviorSubject } from 'rxjs';
import { StringUtils } from '@shared/utils/stringUtils';

export class DataConversion {
  protected $adaptedBotConversation = new BehaviorSubject<TAdaptedBotConversation | null>(null);

  public getValue() {
    return this.$adaptedBotConversation.getValue();
  }

  messageToString(message: TAdaptedMessage, includeRefs = true): string {
    let concatenatedText = message.paragraphs.map(text => {
      let textWithRefs = StringUtils.sanitizeMarkdown(text.text.trim());
      if (includeRefs) {
        const refs = text.references?.map(ref => `[${ref.order}]`).join(' ');
        textWithRefs = `${textWithRefs.trim()} ${refs}`;
      }
      return textWithRefs.trim() + '\n\n'
    }).join('');

    if (includeRefs && message.sources.length) {
      const sources = message.sources.map((source, i) => `${i + 1}. ${StringUtils.sanitizeMarkdown(source.references).trimEnd()}`).join('\n');
      concatenatedText += `\n${ReferencesUtils.getReferenceLabelForLanguage(this.getValue()?.language)}: \n\n${sources}`;
    }

    return concatenatedText;
  }


  getCopyMessage(message: TAdaptedMessage): string {
    return message.paragraphs.flatMap(value => value.text).join('');
  }
}
