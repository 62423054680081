import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
  @Input() showToggle = true;
  @Input() showUser = true;
  @Input() toggleChecked = false;

  @Output() toggleCollapsed = new EventEmitter<void>();


  public getCSS(): string {
    const classes: string[] = [`matero-sidebar-main`, `scrollbar-none`];
    if (this.toggleChecked) {
      classes.push(`matero-sidebar-main--close`);
    }
    return classes.join(` `);
  }


}
