import { NewFileModalComponent } from '../../gingr-new-items-modals/new-file-modal/new-file-modal.component';
import { NewSourceMetadataModalComponent } from '../../gingr-new-items-modals/new-source-metadata-modal/new-source-metadata-modal.component';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { TSourceItem } from '@core/interfaces/TSourceItem';

import { TCountry, TRegion } from '@core/api/countries/types';
import { CommonService } from '@shared/services/common.service';
import { CountriesService } from '@shared/services/countries.service';
import { FoldersService } from '@shared/services/folders.service';
import { SourcesService } from '@shared/services/sources.service';
import { priorityLabels, PriorityValue } from '@shared/utils/priority';
import { Tags } from '@shared/utils/tags';
import { allowedStatuses } from 'app/constants/status';

import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { SourceContentModalComponent } from '../source-content-modal/source-content-modal.component';
import { TMetadataNew } from '@core/interfaces/TMetadata';

@Component({
  selector: 'app-sources-content',
  templateUrl: './sources-content.component.html',
  styleUrls: ['./sources-content.component.scss'],
})
export class SourcesContentComponent implements OnInit, OnDestroy {
  @Input() public selectedItem: TSourceItem = {} as TSourceItem;
  @Output() public handleCancel = new EventEmitter();
  private _subs: Subscription[] = [];

  file: File | undefined;
  allowedStatuses = allowedStatuses;
  isFileLinkEditing = false;
  countries: TCountry[] = [];
  regions: TRegion[] = [];

  public sourceForm = this.fb.nonNullable.group({
    name: ['', [Validators.required]],
    status: [''],
    publicationDate: [''],
    priority: [0],
    references: [''],
    fileLink: [''],
    countries: [[] as string[]],
    metadata: this.fb.array([]),
  });

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private _commonService: CommonService,
    private _folderService: FoldersService,
    private _sourceService: SourcesService,
    private _countriesService: CountriesService
  ) {}

  ngOnInit(): void {
    this._countriesService.getCountriesAndRegions();
    const { countries, regions } = this._countriesService.getCountriesAndRegionsObservable();
    this._subs.push(
      countries.subscribe(response => {
        this.countries = response;
      })
    );
    this._subs.push(
      regions.subscribe(response => {
        this.regions = response;
      })
    );

    this.sourceForm.patchValue({
      name: this.selectedItem.name,
      status: this.selectedItem.status,
      publicationDate: this.selectedItem.publicationDate,
      priority: this.selectedItem.priority,
      references: this.selectedItem.references,
      fileLink: this.selectedItem.fileLink,
      countries: this.selectedItem.countries,
    });
    if (this.selectedItem.metadata) {
      this.selectedItem.metadata.forEach(meta => {
        this.metadata.push(
          this.fb.group({
            slug: [meta.slug],
            value: [meta.value],
          })
        );
      });
    }
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  public getDate(date: Date | undefined): string {
    const formattedDate = Date.parse(date as unknown as string);
    if (date && isNaN(formattedDate ?? NaN)) {
      return `Invalid date`;
    }
    return new Intl.DateTimeFormat('en-US').format(formattedDate);
  }

  public getCountriesAndRegions(): (TCountry | TRegion)[] {
    return [...this.regions, ...this.countries];
  }

  public getTagCSS(tag: string): string {
    return Tags.getTagCSS(tag);
  }

  public getTagLabel(tag: string): string {
    return Tags.getTagLabel(tag);
  }

  public getPriorityLabel(priority: number): string {
    return priorityLabels[priority as PriorityValue];
  }

  public toggleFileLinkEditing(): void {
    this.isFileLinkEditing = !this.isFileLinkEditing;
  }

  get metadata(): FormArray {
    return this.sourceForm.get('metadata') as FormArray;
  }

  public uploadFileCallback(e: Event) {
    const target: HTMLInputElement = e.target as HTMLInputElement;

    const files: FileList | null = target.files;
    const formData = new FormData();
    const f = files![0];
    this.addNewFile(f);
    formData.append('file', f);
    formData.append(`sourceId`, this.selectedItem._id ?? ``);
  }

  public addNewFile(f: File): void {
    const dialogRef = this.dialog.open(NewFileModalComponent, {
      data: { file: f, source: this.selectedItem },
    });

    dialogRef.afterClosed().subscribe((data: File | undefined) => {
      this.file = data;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById('sourceFileInput').value = null;
      if (data) {
        this.selectedItem.fileUrl = data.name;
      } else {
        this.selectedItem.fileUrl = ``;
        this.selectedItem.originalFileName = ``;
      }
    });

    dialogRef.componentInstance.handleFileContent.subscribe((res: string) => {
      this.selectedItem.content = res;
    });
  }

  public addNewMetadata(): void {
    const dialogRef = this.dialog.open(NewSourceMetadataModalComponent, {
      data: { source: this.selectedItem },
    });

    dialogRef.componentInstance.metadataCreated.subscribe((newMetadata: TMetadataNew) => {
      this.metadata.push(this.fb.group({
        slug: [newMetadata.slug],
        value: [newMetadata.value],
      }));
    });
  }

  public removeMetadata(index: number): void {
    this.metadata.removeAt(index);
  }

  public saveSource(): void {
    const source = {
      ...this.selectedItem,
      name: this.sourceForm.value.name,
      status: this.sourceForm.value.status,
      publicationDate: this.sourceForm.value.publicationDate,
      priority: this.sourceForm.value.priority,
      references: this.sourceForm.value.references,
      fileLink: this.sourceForm.value.fileLink,
      countries: this.sourceForm.value.countries,
      metadata: this.sourceForm.value.metadata as TMetadataNew[],
    };
    this._countriesService.updateSourceCountries(
      source._id,
      this.sourceForm.value.countries as string[]
    );
    this._folderService.updateFolderSource(source, () =>
      this._commonService.openSnackBar('Source saved')
    );
  }

  public openContentModal(): void {
    const dialogRef = this.dialog.open(SourceContentModalComponent, {
      data: { content: this.selectedItem.content },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(result => {
      this._commonService.setIsLoading(true);
      this.selectedItem.content = result;
      this._sourceService.updateSource(
        { _id: this.selectedItem._id, content: this.selectedItem.content },
        () => {
          this._commonService.setIsLoading(false);
          dialogRef.close();
          this._commonService.openSnackBar('Content edited');
        }
      );
      dialogSubscription.unsubscribe();
    });
  }

  openConfirmationModal(): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { text: 'Are you sure you want to regenerate embeddings manually?' },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      if (this.selectedItem._id) {
        this._folderService.regenerateEmbeddings(this.selectedItem, () => {
          dialogRef.close();
          this._commonService.openSnackBar(`Embeddings regenerated`);
        });
      }
      dialogSubscription.unsubscribe();
    });
  }

  public back(): void {
    if (this.selectedItem.folder[0]) {
      this.handleCancel.emit(this.selectedItem.folder[0]);
    }
  }
}
