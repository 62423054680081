import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-file-preview',
  templateUrl: './input-file-preview.component.html',
  styleUrls: ['./input-file-preview.component.scss'],
})
export class InputFilePreviewComponent {
  protected item: File | undefined;
  // protected preview: any;//currently we are not using img files
  @Input() itemFormIndex: number | undefined;

  @Input({ required: true }) set file(file: File) {
    this.item = file;
    // this.getPreview();//currently we are not using img files
  }

  @Output() removeAt = new EventEmitter<number>();

  // private getPreview() {//currently we are not using img files
  //   const reader = new FileReader();
  //   reader.onload = (ev: any) => {
  //     this.preview = ev.target.result;
  //   };
  //   reader.readAsDataURL(this.item!);
  // }

  protected removeItem() {
    this.removeAt.emit(this.itemFormIndex);
  }

}
