<div class="results-modal">
  <div class="modal-top">
    <div>
      <h3 class="mat-h3">All Results for query: {{ data.query }}</h3>
    </div>
    <div class="close-dialog-container">
      <button mat-dialog-close mat-icon-button aria-label="close">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="loading" class="d-flex align-items-center w-full">
    <mat-progress-spinner class="spinner" [color]="'accent'" [mode]="'indeterminate'">
    </mat-progress-spinner>
  </div>

  <div *ngIf="!sources.total && !conversations.total && !loading">No results</div>

  <div *ngIf="!loading && (sources.total > 0 || conversations.total > 0)">
    <mat-table *ngIf="sources.total > 0" [dataSource]="sources.data" #table>
      <ng-container matColumnDef="name">
        <mat-cell *matCellDef="let row" (click)="selectResult(row)">
          <div class="d-flex align-items-center fs-14">
            <div class="d-flex flex-col">
              <span class="name">{{ row.name }}</span>
              <span class="desc">{{ row.references }}</span>
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">

        <mat-cell *matCellDef="let row">
          <div class="d-flex align-items-center fs-14">
            <div class="d-flex flex-col">
              <app-add-to-list-button [source]="row"></app-add-to-list-button>
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <mat-row *matRowDef="let row; columns: ['name', 'action']"></mat-row>
    </mat-table>

    <mat-table *ngIf="conversations.total > 0" [dataSource]="conversations.data" #table>
      <ng-container matColumnDef="name">
        <mat-cell *matCellDef="let row" (click)="selectResult(row)">
          <div class="d-flex align-items-center fs-14">
            <div class="d-flex flex-col">
              <span class="name">{{ row.messages[0]?.text }}</span>
              <span class="desc">{{ row.messages[1]?.text }}</span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: ['name']"></mat-row>
    </mat-table>

    <mat-paginator
      #paginator
      (page)="handlePageEvent($event)"
      [length]="totalResults()"
      [pageSize]="10"
      [pageIndex]="paging.page - 1"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
</div>
