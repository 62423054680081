import { SourceSortField } from '@core/interfaces/TSources';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INITIAL_PAGING, TPaging } from '@core/interfaces/TPaging';
import { BehaviorSubject, Observable } from 'rxjs';

import { SourcesApi } from '@core/api/sources/api';
import { ContentItemType } from '@core/interfaces/ContentItemType';
import { Endpoints, TFolder, TSource } from '@core/interfaces/Endpoints';
import { TFolderItem } from '@core/interfaces/TFolderItem';
import { TSourceItem } from '@core/interfaces/TSourceItem';

import { CommonService } from '@shared/services/common.service';
import { SortDirection } from '@angular/material/sort';

@Injectable({
  providedIn: 'root',
})
export class FoldersService {
  private folders: BehaviorSubject<TFolderItem[]> = new BehaviorSubject<TFolderItem[]>([]);
  foldersLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  folderSources: BehaviorSubject<TSourceItem[]> = new BehaviorSubject<TSourceItem[]>([]);
  folderSourcesPaging: BehaviorSubject<TPaging> = new BehaviorSubject<TPaging>(INITIAL_PAGING);

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService
  ) {}

  public getFoldersObservable(): Observable<TFolderItem[]> {
    return this.folders.asObservable();
  }

  public getFoldersLoadingObservable(): Observable<boolean> {
    return this.foldersLoading.asObservable();
  }

  public getFolderSourcesObservable(): Observable<TSourceItem[]> {
    return this.folderSources.asObservable();
  }

  public getFolderSourcesPagingObservable(): Observable<TPaging> {
    return this.folderSourcesPaging.asObservable();
  }

  public setFolderSourcesPaging(paging: TPaging): void {
    this.folderSourcesPaging.next(paging);
  }

  public addNewFolder(newFolderItem: TFolder, callback: (folderId: string) => void) {
    const response = this._http.post<TFolderItem>(
      Endpoints.sources.createFolder.url,
      newFolderItem
    );
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: res => {
        const newItem = {
          ...res,
          children: [],
          sufix: res.sourceCount ?? 0,
          title: res.name,
          __type: ContentItemType.Folders,
        };
        this.folders.next([...this.folders.getValue(), newItem]);
        callback(res._id);
      },
    });
  }

  public deleteFolder(folderId: string, callback: () => void) {
    const response = this._http.delete<TFolderItem>(Endpoints.sources.deleteFolder.url(folderId));
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        this.folders.next(this.folders.getValue().filter(folder => folder._id !== folderId));
        callback();
      },
    });
  }

  public fetchFolders(): void {
    this.foldersLoading.next(true);
    const response = this._http.get<TFolderItem[]>(Endpoints.sources.listFolders.url);
    response.subscribe({
      error: err => {
        this.foldersLoading.next(false);
        console.error(err);
      },
      next: res => {
        const result = res.map(folder => {
          return {
            ...folder,
            title: folder.name,
            __type: ContentItemType.Folders,
            sufix: folder.sourceCount,
          };
        });
        this.folders.next(result);
        this.foldersLoading.next(false);
      },
    });
  }

  addFolderProject(folder: TFolderItem, projectId: string, callback: () => void): void {
    if (folder._id) {
      const response = this._http.put<TFolderItem>(Endpoints.sources.updateFolder.url(folder._id), {
        ...folder,
        project: [...folder.project, projectId],
      });
      response.subscribe({
        error: err => {
          console.error(err);
        },
        next: res => {
          const editedFolderIndex = this.folders.getValue().findIndex(f => f._id === res._id);
          this.folders.getValue().splice(editedFolderIndex, 1, res);
          callback();
        },
      });
    }
  }

  public updateFolder(folder: TFolderItem, callback: () => void): void {
    if (folder._id) {
      const response = this._http.put<TFolderItem>(
        Endpoints.sources.updateFolder.url(folder._id),
        folder
      );
      response.subscribe({
        error: err => {
          console.error(err);
        },
        next: res => {
          const updatedFolders = this.folders.value.map(f =>
            f._id === res._id
              ? {
                  ...f,
                  title: res.name,
                }
              : f
          );
          this.folders.next(updatedFolders);
          callback();
        },
      });
    }
  }

  removeFolderProject(folder: TFolderItem, projectId: string, callback: () => void): void {
    if (folder._id) {
      const response = this._http.put<TFolderItem>(Endpoints.sources.updateFolder.url(folder._id), {
        ...folder,
        project: [...folder.project.filter(id => id !== projectId)],
      });
      response.subscribe({
        error: err => {
          console.error(err);
        },
        next: res => {
          const editedFolderIndex = this.folders.getValue().findIndex(f => f._id === res._id);
          this.folders.getValue().splice(editedFolderIndex, 1, res);
          callback();
        },
      });
    }
  }

  public fetchProjectFolders(projectId: string | undefined): TFolderItem[] {
    return this.folders
      .getValue()
      .filter(folder => projectId && folder.project.includes(projectId));
  }

  public fetchProjectFoldersIds(projectId: string | undefined): string[] {
    return this.folders
      .getValue()
      .filter(folder => projectId && folder.project.includes(projectId))
      .map(folder => {
        return folder._id;
      });
  }

  public addNewSourceToFolder(newSourceItem: TSource, callback: (res: TSourceItem) => void) {
    const response = this._http.post<TSourceItem>(
      Endpoints.sources.createSource.url,
      newSourceItem
    );
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: res => {
        const results = [...this.folderSources.getValue(), res];
        const sources = results.map((source: TSourceItem) => {
          return { ...source, __type: ContentItemType.Sources };
        });
        this.folderSources.next(sources);
        callback(res);
      },
    });
  }

  public deleteSource(source: TSourceItem, callback: () => void) {
    const response = this._http.delete(Endpoints.sources.deleteSource.url(source._id!));
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        this.folderSources.next(this.folderSources.getValue().filter(s => s._id !== source._id));
        callback();
      },
    });
  }

  public updateFolderSource(
    source: Partial<TSource>,
    callback: (isUpdateSuccessfully: boolean) => void
  ): void {
    if (source.publicationDate) {
      source.publicationDate = new Date(source.publicationDate).toISOString();
    }
    const { url, body } = SourcesApi.updateSource(source);
    const response = this._http.put<TSourceItem>(url, body);
    response.subscribe({
      error: err => {
        console.error(err);
        callback(false);
      },
      next: result => {
        const editedSource = this.folderSources.value.find(s => s._id === result._id);
        const editedSourceIndex = this.folderSources.value.findIndex(s => s._id === result._id);
        if (editedSource) {
          this.folderSources.value.splice(editedSourceIndex, 1, { ...editedSource, ...result });
          this.folderSources.next(this.folderSources.value);
        }
        callback(true);
      },
    });
  }

  public regenerateEmbeddings(source: Partial<TSource>, callback: () => void): void {
    this._commonService.setIsLoading(true);
    const response = this._http.get(Endpoints.sources.regenerateEmbeddings.url(source._id ?? ''));
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        callback();
      },
      complete: () => {
        this._commonService.setIsLoading(false);
      },
    });
  }

  getFolderSources(
    folderId: string,
    page: number,
    limit: number,
    sortOrder: SortDirection,
    sortField: SourceSortField,
    callback?: () => void
  ): void {
    this._commonService.setIsLoading(true);
    const response = this._http.get<{
      total: number;
      page: number;
      limit: number;
      result: TSourceItem[];
    }>(Endpoints.sources.listFolderSources.url(folderId, page, limit, sortField, sortOrder));
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: res => {
        const sources = res.result.map((source: TSourceItem) => {
          return { ...source, __type: ContentItemType.Sources };
        });
        this.folderSources.next(sources);
        this.setFolderSourcesPaging({
          total: res.total,
          page: res.page,
          limit: res.limit,
        });
        callback && callback();
      },
      complete: () => {
        this._commonService.setIsLoading(false);
      },
    });
  }

  getSource(sourceId: string, callback: (source: TSourceItem) => void): void {
    const response = this._http.get<TSourceItem>(Endpoints.sources.getSource.url(sourceId));
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: res => {
        const source = {
          ...res,
          __type: ContentItemType.Sources,
        };
        callback(source);
      },
    });
  }

  setFolders(newFolder: TFolderItem): void {
    this.folders.next([...this.folders.getValue(), newFolder]);
  }

  setFolderSources(source: TSourceItem): void {
    const editedSourceIndex = this.folderSources.getValue().findIndex(s => s._id === source._id);
    if (editedSourceIndex !== -1) {
      this.folderSources
        .getValue()
        .splice(editedSourceIndex, 1, { ...source, __type: ContentItemType.Sources });
    } else {
      this.folderSources.next([
        ...this.folderSources.getValue(),
        { ...source, __type: ContentItemType.Sources },
      ]);
    }
  }
}
