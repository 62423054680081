<div class="new-item-modal">
  <h3 class="mat-h3">Preview {{ data.filename }}</h3>

  <div class="component-container">
    <ng-template #dynamicContainer></ng-template>
  </div>

  <div class="d-flex align-items-center justify-content-between m-t-16">
    <button
      mat-raised-button
      color="accent"
      (click)="cancel()">
      Cancel
    </button>
  </div>
</div>
