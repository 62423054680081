<div class="gingr-input-wrapper gingr-input-wrapper--with-form search-wrapper">
  <form [formGroup]="searchForm" (ngSubmit)="showAllResults()">
    <input
      class="gingr-input--primary"
      matInput
      placeholder="Search {{ searchIn }}"
      formControlName="query"
      autocomplete="off"
    />
  </form>
  <div class="search-buttons">
    <button mat-icon-button (click)="clearSearch()">
      <mat-icon>close</mat-icon>
    </button>
    <button mat-icon-button (click)="showAllResults()">
      <mat-icon>search</mat-icon>
    </button>
  </div>
  <!-- menu was removed in task: GINGR-428. Check git history -->
</div>
