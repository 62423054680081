import { IFileExportDriver, TExportOptions } from '@shared/services/file-export/file-export-interface';
import {
  AlignmentType,
  Document,
  Footer,
  Header,
  ImageRun,
  Packer,
  PageBreak,
  PageNumber,
  Paragraph,
  TextRun,
} from 'docx';
import { saveAs } from 'file-saver';
import { FileImage } from '@shared/services/file-export/tools/file-image';
import { FileChild } from 'docx/build/file/file-child';

export class DocxDriver implements IFileExportDriver {

  private pages: string[] = [];
  private logoFile: FileImage | undefined;
  private readonly logoHeight = 40;
  private options: TExportOptions | undefined;

  setPages(pages: string[]): IFileExportDriver {
    this.pages = pages;
    return this;
  }

  setOptions(options: TExportOptions): IFileExportDriver {
    this.options = options;
    return this;
  }

  async setLogo(filePath: string): Promise<any> {
    this.logoFile = new FileImage();
    return await this.logoFile.setLogo(filePath);
  }

  generate(filename: string) {
    const doc = new Document({
      sections: [
        {
          headers: this.headers(),
          children: this.documentBody(),
          footers: this.footer(),
        },
      ],
    });


    Packer.toBlob(doc).then(blob => {
      saveAs(blob, `${filename}.docx`);
    }).catch(reason => {
      console.error(reason);
    });
  }

  protected documentBody(): FileChild[] {
    const children: any[] = [];
    this.pages.forEach((value, pageIndex) => {

      value.split('\n').forEach((text) => {
        children.push(new Paragraph({
            children: [
              new TextRun(text),
            ],
          },
        ));
      });

      if (pageIndex < this.pages.length - 1) {
        children.push(new Paragraph({ children: [new PageBreak()] }));
      }

    });

    return children;
  }

  protected headers() {
    if (!this.options?.headerLogo) return;
    return {
      default: new Header({
          children: [
            new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new ImageRun({
                      data: this.logoFile!.logoBase as string,
                      transformation: {
                        width: this.calculateImageWidth(),
                        height: this.logoHeight,
                      },
                    },
                  ),
                ],
              },
            ),
          ],
        },
      ),
    };
  }


  protected footer() {
    const paragraphChildren = [];

    if (this.options?.confidentialityClause) {
      paragraphChildren.push(this.footerParagraph([this.options?.confidentialityClauseText ?? '']));
    }

    if (this.options?.pageNumber) {
      paragraphChildren.push(this.footerParagraph(['Page ', PageNumber.CURRENT, ' of ', PageNumber.TOTAL_PAGES]));
    }

    return {
      default: new Footer({
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: paragraphChildren,
          }),
        ],
      }),
    };
  }

  protected calculateImageWidth(): number {
    if (this.logoFile?.logoDimensions) {
      return this.logoFile.logoDimensions.w * (this.logoHeight / this.logoFile.logoDimensions?.h);
    }
    return 0;
  }

  protected footerParagraph(children: any[]) {
    return new Paragraph({
      alignment: AlignmentType.CENTER,
      children: [
        new TextRun({
          children,
        }),
      ],
    });
  }

  public cleanUp() {
    this.pages = [];
    this.logoFile = undefined;
  }
}
