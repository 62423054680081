import { Component } from '@angular/core';
import { MaterialModule } from '../../../../../material.module';
import { NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'app-app-auth-list',
  templateUrl: './app-auth-list.component.html',
  standalone: true,
  styleUrls: ['./app-auth-list.component.scss'],
  imports: [
    MaterialModule,
    NgForOf,
    NgIf,
  ],
})
export class AppAuthListComponent {
  protected readonly appList: { name: string, androidUrl?: string, iosUrl?: string }[] = [
    {
      name: 'Google Authenticator (recommended)',
      androidUrl: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
      iosUrl: 'https://apps.apple.com/pl/app/google-authenticator/id388497605',
    }, {
      name: 'Microsoft Authenticator (recommended)',
      androidUrl: 'https://play.google.com/store/apps/details?id=com.azure.authenticator',
      iosUrl: 'https://apps.apple.com/pl/app/microsoft-authenticator/id983156458',
    }, {
      name: 'LastPass Authenticator',
      androidUrl: 'https://play.google.com/store/apps/details?id=com.lastpass.authenticator',
      iosUrl: 'https://apps.apple.com/us/app/lastpass-authenticator/id1079110004',
    },{
      name: 'Twilio Authy Authenticator',
      androidUrl: 'https://play.google.com/store/apps/details?id=com.authy.authy',
      iosUrl: 'https://apps.apple.com/us/app/twilio-authy/id494168017',
    }
  ];
}
