export interface TPaging {
  total: number;
  page: number;
  limit: number;
}

export const INITIAL_PAGING: TPaging = {
  total: 0,
  page: 1,
  limit: 25,
};

export const PAGE_SIZES = [10, 25, 50, 100];
