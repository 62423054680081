import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { TCountry, TRegion } from '@core/api/countries/types';
import { CountriesService } from '@shared/services/countries.service';

@Component({
  selector: 'app-countries-allowed',
  templateUrl: './countries-allowed.component.html',
  styleUrls: ['./countries-allowed.component.scss'],
})
export class CountriesAllowedComponent implements OnInit, OnDestroy {
  private _subs: Subscription[] = [];
  countries: TCountry[] = [];
  regions: TRegion[] = [];
  searchCountry = '';
  searchRegion = '';

  public countryForm = this.fb.nonNullable.group({
    name: ['', Validators.required],
  });

  public regionForm = this.fb.nonNullable.group({
    name: ['', Validators.required],
    countries: [[] as TCountry[], Validators.required],
  });

  constructor(
    private _countriesService: CountriesService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this._countriesService.getCountriesAndRegions();
    const { countries, regions } = this._countriesService.getCountriesAndRegionsObservable();

    this._subs.push(
      countries.subscribe(response => {
        this.countries = response;
      })
    );
    this._subs.push(
      regions.subscribe(response => {
        this.regions = response;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  public addCountry(): void {
    this._countriesService.addCountry({ name: this.countryForm.value.name as string });
    this.countryForm.setValue({ name: '' });
  }

  public deleteCountry(id: string): void {
    this._countriesService.deleteCountry(id);
  }

  public addRegion(): void {
    this._countriesService.addRegion({
      name: this.regionForm.value.name as string,
      countries: this.regionForm.value.countries as TCountry[],
    });
    this.regionForm.setValue({ name: '', countries: [] });
  }

  public deleteRegion(id: string): void {
    this._countriesService.deleteRegion(id);
  }

  public regionCountries(region: TRegion): string {
    return region.countries.map(country => country.name).join(', ');
  }

  protected clearSearchCountry() {
    this.searchCountry = '';
  }

  protected clearSearchRegion() {
    this.searchRegion = '';
  }
}
