import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { Status, TFolder } from '@core/interfaces/Endpoints';

import { FoldersService } from '@shared/services/folders.service';

import { allowedStatuses } from '../../../../enums/status';

@Component({
  selector: 'app-new-folder-modal',
  templateUrl: './new-folder-modal.component.html',
  styleUrls: ['./new-folder-modal.component.scss'],
})
export class NewFolderModalComponent {
  public newFolderForm = this.fb.nonNullable.group({
    name: ['', [Validators.required]],
    status: ['', [Validators.required]],
  });

  statuses = allowedStatuses;

  constructor(
    private dialogRef: MatDialogRef<NewFolderModalComponent>,
    private fb: FormBuilder,
    private _folderService: FoldersService
  ) {}

  public get name() {
    return this.newFolderForm.get('name')!;
  }

  public get status() {
    return this.newFolderForm.get('status')!;
  }

  public addNewItem(): void {
    if (this.newFolderForm.valid) {
      const newFolderItem: TFolder = {
        name: this.name.value ?? ``,
        status: (this.status.value ?? Status.Inactive) as unknown as TFolder['status'],
      };
      this._folderService.addNewFolder(newFolderItem, () => this.dialogRef.close());
    }
  }
}
