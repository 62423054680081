<div class="m-b-24">
  <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" [multiple]="maxInputFiles > 1">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div *ngIf="!files.length">Drop file here</div>
      <button type="button" mat-raised-button color="accent" class="m-t-12" (click)="openFileSelector()">Browse Files
      </button>
      <div *ngIf="!files.length" class="m-t-12">
        (allowed extensions: {{ allowedExt.join(', ') }} with max size of {{ maxFileSizeMB }} MB)
      </div>
      <div *ngIf="files.length" class="m-y-12">
        <strong *ngFor="let file of files">{{ file.relativePath }} </strong>
      </div>
    </ng-template>
  </ngx-file-drop>

  <div *ngIf="errorMsg.length" class="m-t-12 error">
    <p *ngFor="let err of errorMsg">{{ err }}</p>
  </div>
</div>
