export class ArrayUtils {
  static shuffle<T>(arr: T[]): T[] {
    return arr.sort(() => 0.5 - Math.random());
  }

  static last<T>(array: T[] | undefined): T | undefined {
    if (array) {
      return array.length > 0 ? array[array.length - 1] : undefined;
    }
    return undefined;
  }

  static sortBy<T>(list: T[], property: keyof T): T[] {
    return list.sort((a, b) => (a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0));
  }


  static uniqueBy<T>(list: T[], property: keyof T): T[] {
    const uniqueResults: T[] = [];

    list.forEach(item => {
      if (!uniqueResults.find(x => x[property] === item[property])) {
        uniqueResults.push(item);
      }
    });

    return uniqueResults;
  }

  static sortUniqueBy<T>(list: T[], property: keyof T): T[] {
    return this.sortBy(this.uniqueBy(list, property), property);
  }
}
