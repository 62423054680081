<h3 class="mat-h3 m-t-48">Inner bots</h3>
<ul class="inner-bot-list" cdkDropList (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let bot of botCreator.bots;let j = index">
      <div class="inner-bot-row align-items-center" cdkDrag>
        <div class="d-flex align-items-center">
          <mat-icon class="drag-handle">drag_handle</mat-icon>
          <mat-icon class="menu-icon" svgIcon="bot-eye-small"></mat-icon>
          <span class="item-title">{{ bot.name }}</span>
        </div>
        <div class="d-flex align-items-center">
          <mat-icon class="icon-button" (click)="openEditBot(bot)">edit</mat-icon>
          <mat-icon class="icon-button" (click)="removeBotModal(bot)">delete</mat-icon>
        </div>
      </div>
    </ng-container>
</ul>
<div class="m-b-32">
    <button type="button" mat-raised-button color="accent" class="m-r-16" (click)="addBots()"> + </button>
</div>