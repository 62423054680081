import { Component, ElementRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { TAdaptedMessage } from '@shared/services/bots/types/types';
import { marked } from 'marked';
import { SourcePreviewButtonComponent } from '../../../modals/source-preview/button/source-preview-button.component';
import { SourcesMaterialsApi } from '@core/api/materials/api';

@Component({
  selector: 'app-single-message',
  templateUrl: './single-message.component.html',
  styleUrls: ['./single-message.component.scss'],
})
export class SingleMessageComponent {
  protected msg: TAdaptedMessage | undefined;
  text = '';
  @Input() isLoading = true;

  @Input() set message(message: TAdaptedMessage | undefined) {
    this.msg = message;
    this.getText().then(value => {
      this.text = value;
      setTimeout(() => {
        this.addReferences();
      }, 0);
    });
  }

  @ViewChild('botResults', { static: false }) botResults: ElementRef | undefined;

  constructor(private viewContainerRef: ViewContainerRef) {}

  private async getText() {
    const text = this.msg!.paragraphs.map((paragraph, paragraphIndex) => {
      let text = paragraph.text;
      if (paragraph.references) {
        paragraph.references.forEach((reference, referenceIndex) => {
          text += `<span class="ref_indicator" data-par="${paragraphIndex}" data-ref="${referenceIndex}"></span>`;
        });
      }
      return text;
    }).join('');

    return marked.parse(text);
  }

  protected addReferences() {
    this.botResults?.nativeElement
      .querySelectorAll('.ref_indicator')
      .forEach((singleText: Element) => {
        const reference = this.getReference(singleText);
        const component = this.createComponent(reference);

        singleText.appendChild(component.location.nativeElement);
      });
  }

  private getReference(singleText: Element) {
    const par = parseInt(singleText.getAttribute('data-par') ?? '');
    const ref = parseInt(singleText.getAttribute('data-ref') ?? '');

    if (isNaN(par) || isNaN(ref)) {
      return undefined;
    }

    return this.msg?.paragraphs?.[par]?.references?.[ref];
  }

  private createComponent(reference: any) {
    const component = this.viewContainerRef.createComponent(SourcePreviewButtonComponent);
    component.setInput('label', reference.order);
    component.setInput('sourceDetail', reference.source);
    return component;
  }

  protected getLink(fileName: string) {
    return SourcesMaterialsApi.downloadFile(fileName).url;
  }
}
