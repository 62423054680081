<div class="container" *ngIf="randomHints.length>0">
  <div class="hint" *ngFor="let hint of randomHints" (click)="hintSelected.emit(hint)">
    <div class="icon">
      <mat-icon>lightbulb_outline</mat-icon>
    </div>
    <span>
    {{ hint }}
    </span>
  </div>
</div>

