import { Component, Input, ViewEncapsulation } from '@angular/core';

import { MenuService } from '@core';

import { isCustomIcon, TIconItem } from '@shared/utils/isCustomIcon';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidemenuComponent {
  // Note: Ripple effect make page flashing on mobile
  @Input() ripple = false;
  @Input() toggleChecked = false;

  public menu$ = this.menu.getAll();
  public buildRoute = this.menu.buildRoute;

  constructor(private menu: MenuService) {}

  public isCustomIcon(item: TIconItem): boolean {
    return isCustomIcon(item);
  }
}
