<div class="new-item-modal modal">
  <h3 class="mat-h3">Select RISE data source</h3>
  <div class="m-t-48 d-flex justify-content-around">
    <mat-card
      class="d-flex align-items-center justify-content-center cursor-pointer card"
      (click)="handleOption1Click()"
      [ngClass]="{ selected: selectedOption === 'option1' }"
    >
      {{ data.option1 }}
    </mat-card>
    <mat-card
      class="d-flex align-items-center justify-content-center cursor-pointer card"
      (click)="handleOption2Click()"
      [ngClass]="{ selected: selectedOption === 'option2' }"
    >
      {{ data.option2 }}
    </mat-card>
  </div>
  <div class="m-t-48 d-flex justify-content-end">
    <button
      class="d-flex align-items-center justify-start m-t-16"
      mat-raised-button
      color="accent"
      (click)="onActionClick()"
      [disabled]="!selectedOption">
      {{ data.actionLabel }}
    </button>
  </div>
</div>
