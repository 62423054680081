import { booleanAttribute, Component, Input } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() color: 'primary' | 'active' | 'inactive' | 'disabled' = 'primary';
  @Input() tooltip = '';
  @Input() tooltipPosition: 'left' | 'right' | 'above' | 'below' | 'before' | 'after' = 'above';
  @Input({ transform: booleanAttribute }) clickable = false;
}
