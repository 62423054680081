<div class="p-24 p-t-0 d-flex flex-col align-items-baseline">
  <h3 class="mat-h3">App name</h3>
  <mat-form-field class="gingr-input-wrapper w-full">
    <input
      [(ngModel)]="appName"
      matInput
      type="text"
      required
      placeholder="Enter application name here"
    />
  </mat-form-field>
  <button mat-raised-button class="m-t-16" color="accent" (click)="saveAppName()">Save</button>
</div>

<div class="p-24 p-t-0 d-flex flex-col align-items-baseline">
  <h3 class="mat-h3">App version</h3>
  <mat-form-field class="gingr-input-wrapper w-full">
    <input
      [(ngModel)]="version"
      matInput
      type="text"
      required
      placeholder="Enter version text here"
    />
  </mat-form-field>
  <button mat-raised-button class="m-t-16" color="accent" (click)="saveAppVersion()">Save</button>
</div>
