<form class="form-field-full" [formGroup]="vocabularyRulesForm">
  <mat-form-field class="gingr-input-wrapper w-full">
    <textarea
      placeholder="Enter rules"
      matInput
      formControlName="rules"
      required
      rows="10"
    ></textarea>
  </mat-form-field>
  <button
    mat-raised-button
    color="accent"
    [disabled]="vocabularyRulesForm.invalid"
    (click)="submit()">
    Save
  </button>
</form>
