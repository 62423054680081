import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FileStorageService } from '@shared/services/file-storage.service';
import { TAdaptedBotConversation } from '@shared/services/bots/types/types';
import { TConversationVideoItem } from '@core/api/conversations/types';
import { ConversationsService } from '@shared/services/conversations.service';

@Component({
  selector: 'app-talking-avatar-playlist',
  templateUrl: './talking-avatar-playlist.component.html',
  styleUrls: ['./talking-avatar-playlist.component.scss'],
})
export class TalkingAvatarPlaylistComponent {
  protected videos: TConversationVideoItem[] = [];
  protected videoPlaceHolder = '/assets/avatar/prev.mov';
  protected videoData = {
    url: this.videoPlaceHolder,
    loop: true,
  };
  protected activeVideoIndex: number | null = null;

  constructor(
    private storageService: FileStorageService,
    private conversationService: ConversationsService
  ) {}

  @Input() set conversation(conversation: TAdaptedBotConversation | null) {
    this.videos = [];
    if (conversation) {
      this.conversationService.getVideosForConversation(conversation.id!).subscribe(value => {
        this.videos = value.avatarVideos;
      });
    }
  }

  @ViewChild('video') video: ElementRef<HTMLVideoElement> | undefined;

  getVideo(id: string, index: number) {
    this.activeVideoIndex = index;
    this.storageService.download(id).subscribe(blob => {
      this.videoData = {
        url: URL.createObjectURL(blob),
        loop: false,
      };
    });
  }

  onVideoEnded() {
    if (this.videoData.url !== this.videoPlaceHolder) {
      this.videoData = {
        url: this.videoPlaceHolder,
        loop: true,
      };
    }
  }
}
