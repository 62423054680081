import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { TProjectItem } from '@core/interfaces/TProjectItem';

import { ProjectsService } from '@shared/services/projects.service';

import { projectTab } from '../../../../enums/tab';
import { EditProjectModalComponent } from '../../modals/edit-project-modal/edit-project-modal.component';

@Component({
  selector: 'app-projects-content',
  templateUrl: './projects-content.component.html',
  styleUrls: ['./projects-content.component.scss'],
})
export class ProjectsContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public selectedItem: TProjectItem = {} as TProjectItem;
  private _subs: Subscription[] = [];

  public selectedTab: projectTab | undefined = undefined;
  allowedTabs = projectTab;

  constructor(public dialog: MatDialog, private _projectsService: ProjectsService) {}

  public ngOnInit(): void {
    this._subs.push(
      this._projectsService.getProjectsObservable().subscribe(results => {
        const newProjectsData: TProjectItem | undefined = results.find(
          it => it._id === this.selectedItem._id
        );
        if (newProjectsData) {
          this.selectedItem = newProjectsData;
        }
      })
    );
    this._projectsService.setProjects();
    this.selectedTab = projectTab.SOURCES;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItem.previousValue !== changes.selectedItem.currentValue) {
      this.selectedItem = changes.selectedItem.currentValue;
    }
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  public selectTab(tab: projectTab): void {
    this.selectedTab = tab;
  }
  public getTabCSS(tab: projectTab): string {
    return tab === this.selectedTab ? `active` : ``;
  }

  protected isTabVisible(tab:projectTab){
    return this.selectedTab === tab;
  }

  openEditModal(): void {
    const dialogRef = this.dialog.open(EditProjectModalComponent, {
      data: {
        name: this.selectedItem.name,
        description: this.selectedItem.description,
      },
    });

    const saveDialogSubscription = dialogRef.componentInstance.saveCallback.subscribe(response => {
      if (this.selectedItem._id) {
        this._projectsService.updateProjectDetails(this.selectedItem, response, () => {
          this.selectedItem = {
            ...this.selectedItem,
            description: response.description,
            title: response.name,
          };
        });
      }
      saveDialogSubscription.unsubscribe();
    });
  }
}
