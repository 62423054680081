import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService, User } from '@core';
import { Endpoints } from '@core/interfaces/Endpoints';
import { TMetadata } from '@core/interfaces/TMetadata';

import { isSlugCreatedInMetadata } from '@shared/utils/slug';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _http: HttpClient, private _authService: AuthService) {}

  public updateCurrentUser(data: Partial<User>, callback: () => void): void {
    let currentUser: User = {};
    this._authService.user().subscribe(user => {
      currentUser = user;
    });
    if (currentUser && currentUser.name) {
      const response = this._http.put(Endpoints.user.updateUserByUsername.url(currentUser.name), data);
      response.subscribe({
        error: err => {
          console.log('err:');
          console.log(err);
        },
        next: () => {
          this._authService.setUser({ ...currentUser, ...data });
          callback();
        },
      });
    }
  }

  public updateCurrentUserMetadata(data: TMetadata, callback: () => void): void {
    const isDisplayNameModified = data.slug === 'display_name';
    let currentUser: User = {};
    this._authService.user().subscribe(user => {
      currentUser = user;
    });
    if (currentUser && currentUser.name) {
      let body: TMetadata[];
      if (currentUser.metadata && !isSlugCreatedInMetadata(data.slug, currentUser.metadata)) {
        body = [...currentUser.metadata, data];
      } else {
        body = [data];
      }
      const response = this._http.put(Endpoints.user.updateUserByUsername.url(currentUser.name), { metadata: body });
      response.subscribe({
        error: err => {
          console.log('err:');
          console.log(err);
        },
        next: () => {
          const userFields = {
            ...currentUser,
            metadata: body,
          };
          if (isDisplayNameModified) {
            userFields.displayName = data.value as string;
          }
          this._authService.setUser(userFields);
          callback();
        },
      });
    }
  }

  updateCurrentUserPassword(oldPassword: string, newPassword: string, callback: () => void, errorCallback: () => void) {
    this.checkPasswordValidity(oldPassword, () => {
      this.updateCurrentUser({ password: newPassword }, callback);
    }, () => {
      errorCallback();
    });
  }

  private checkPasswordValidity(password: string, callback: () => void, errorCallback: () => void): void {
    let currentUser: User = {};
    this._authService.user().subscribe(user => {
      currentUser = user;
    });
    if (currentUser && currentUser.name) {
      const response = this._http.post(Endpoints.user.checkPassword.url, {
        username: currentUser.name,
        password,
      });
      response.subscribe({
        error: () => {
          errorCallback();
        },
        next: () => {
          callback();
        },
      });
    }
  }
}
