<ng-container *ngIf="botItem!.type === 'botCreator'">
  <h3 class="mat-h3 m-t-24">Talking avatar configurations</h3>
  <form [formGroup]="avatarConfigForm">
    <p class="custom-label d-flex align-items-center">
      <span class="m-r-24">Is talking avatar available</span>
      <mat-slide-toggle formControlName="isAvailable"></mat-slide-toggle>
    </p>
    <div *ngIf="avatarConfigForm.controls.isAvailable.value">
      <span class="custom-label">Avatar Name</span>
      <mat-form-field class="gingr-input-wrapper w-full">
        <input matInput placeholder="Avatar ID" formControlName="avatarId"/>
      </mat-form-field>

      <span class="custom-label">Voice Rate</span>
      <div class="form-input">
        <mat-slider class="gingr-input-wrapper materials-input" min="0.5" max="1.5" step="0.1" discrete>
          <input matInput matSliderThumb formControlName="voiceRate"/>
        </mat-slider>
      </div>

      <span class="custom-label">Voice Emotion</span>
      <mat-form-field class="gingr-input-wrapper w-full">
        <mat-select formControlName="voiceEmotion">
          <mat-option *ngFor="let emotion of voiceEmotions" [value]="emotion">{{ emotion }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</ng-container>
