import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ContentItemType } from '@core/interfaces/ContentItemType';
import { TFolderItem } from '@core/interfaces/TFolderItem';
import { TProjectItem } from '@core/interfaces/TProjectItem';

import { ListModalComponent } from '../../components/modals/list-modal/list-modal.component';
import { FoldersService } from '@shared/services/folders.service';

@Component({
  selector: 'app-project-sources',
  templateUrl: './project-sources.component.html',
  styleUrls: ['./project-sources.component.scss']
})
export class ProjectSourcesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public selectedProject: TProjectItem = {} as TProjectItem;

  public allFolders: TFolderItem[] = [];
  public projectFolders: TFolderItem[] = [];
  private _subs: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private _folderService: FoldersService
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this._folderService.getFoldersObservable().subscribe(res => {
        this.allFolders = res;
        this.projectFolders = this._folderService.fetchProjectFolders(this.selectedProject._id);
      })
    );
    this._folderService.fetchFolders();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProject.previousValue !== changes.selectedProject.currentValue) {
      this.projectFolders = this._folderService.fetchProjectFolders(this.selectedProject._id);
    }
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  openAllFoldersListModal(): void {
    const dialogRef = this.dialog.open(ListModalComponent, {
      data: {
        projectId: this.selectedProject._id,
        itemsIds: this._folderService.fetchProjectFoldersIds(this.selectedProject._id),
        type: ContentItemType.Folders,
      }
    });

    dialogRef.componentInstance.toggleCallback.subscribe(() => {
      this.projectFolders = this._folderService.fetchProjectFolders(this.selectedProject._id);
    });
  }

  navigateToFolder(folderId: string): void {
    this.router.navigateByUrl(`sources/${folderId}`);
  }

}
