import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { TBotItem, TBotItemHistory } from '@core/interfaces/TBotItem';

import { CommonService } from '@shared/services/common.service';
import { BotsService } from '@shared/services/bots.service';
import { TInnerBot } from '@core/interfaces/TBot';
import { TFieldSet } from './bot-config-comparison/bot-config-comparison.component';

@Component({
  selector: 'app-bot-config-history',
  templateUrl: './bot-config-history-modal.component.html',
  styleUrls: ['./bot-config-history-modal.component.scss'],
})
export class BotConfigHistoryModalComponent implements OnInit, OnDestroy {

  private _subs: Subscription[] = [];
  protected history: TBotItemHistory[] = [];
  protected isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bot: TBotItem | TInnerBot, fieldSet: TFieldSet },
    private _dialogRef: MatDialogRef<BotConfigHistoryModalComponent>,
    private _botsService: BotsService,
    private _commonService: CommonService,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this._subs.push(
      this._botsService.getBotHistory(this.data.bot)
        .subscribe(historyConfiguration => {
          this.history = historyConfiguration;
          this.isLoading = false;
        }),
    );
  }


  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  protected revertVersion(version: TBotItemHistory) {
    this._subs.push(
      this._botsService.restoreBotHistory(version).subscribe({
        next: () => {
          this.cancel();
          this._commonService.openSnackBar('Version reverted successfully');
        },
        error: () => {
          this._commonService.openSnackBar('Version reverted failed');
        },
      }),
    );
  }

  protected deleteVersion(version: TBotItemHistory) {
    this._subs.push(
      this._botsService.deleteVersion(version).subscribe({
        next: () => {
          this._commonService.openSnackBar('Version deleted successfully');
          this.history = this.history.filter(it => it._id !== version._id);
        },
        error: (err) => {
          console.error(err);
          this._commonService.openSnackBar('Version deleted failed');
        },
      }),
    );
  }

  cancel() {
    this._dialogRef.close();
  }


}
