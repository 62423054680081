<div class="modal">
  <form class="form-field-full" [formGroup]="projectForm">
    <div>
      <div class="m-l-0 custom-label">Project name <span class="asterisk">*</span></div>
      <mat-form-field class="gingr-input-wrapper w-full">
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>
    <div>
      <div class="m-l-0 custom-label">Project description</div>
      <mat-form-field class="gingr-input-wrapper w-full">
        <input matInput formControlName="description" />
      </mat-form-field>
    </div>
    <div class="d-flex align-items-center justify-content-end m-t-48 controls">
      <button mat-raised-button class="m-r-24 secondary-button" color="primary" (click)="cancel()">Cancel</button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="projectForm.invalid"
        (click)="save()">
        Save
      </button>
    </div>
  </form>
</div>
