import { environment } from '@env/environment';

import { PostRequestData } from '../utils';

export class ResultsApi {
  static deleteMultiple(conversationIds: string[]): PostRequestData<{ conversationIds: string[] }> {
    return {
      url: `${environment.backendURL}/api/bots/results/removeMultiple`,
      body: { conversationIds },
    };
  }
}
