<div class="header folders-header">
  <div class="row align-items-center justify-content-between m-b-24">
    <div class="d-flex align-items-center justify-content-center">
      <h3 class="mat-h3 m-t-8 m-b-8">{{ selectedItem.title }}</h3>
      <app-job-status class="m-l-8" [folder]="selectedItem"></app-job-status>
    </div>
    <button mat-raised-button class="m-l-16 control" color="accent" (click)="openEditModal()">
      Edit folder
    </button>
  </div>
  <div class="d-flex justify-content-between">
    <div class="m-b-24">
      <button mat-raised-button color="accent" (click)="openNewSourceModal()">
        <mat-icon>add</mat-icon>
        Upload
      </button>
      <button mat-raised-button color="accent" class="m-l-16" (click)="goToBatchUpload()">
        <mat-icon>add</mat-icon>
        Batch upload
      </button>
    </div>
    <div class="d-flex align-items-center">
      <mat-progress-spinner
        *ngIf="isLoading"
        class="spinner"
        [color]="'accent'"
        [mode]="'indeterminate'"
        [value]="50"
      >
      </mat-progress-spinner>
    </div>
  </div>
  <app-folder-sources-list
    [paging]="paging"
    [isLoading]="isLoading"
    [folder]="selectedItem"
    [folderSources]="folderSources"
    [sorting]="sorting"
    (sourceSelected)="handleSourceSelected($event)"
    (sortChanged)="handleSortChange($event)"
    (pageChanged)="handlePageChange($event)"
  ></app-folder-sources-list>
</div>
