import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BotMessagesService } from '@shared/services/bots/bot-messages.service';
import { TAdaptedMessage } from '@shared/services/bots/types/types';
import { CommonService } from '@shared/services/common.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ConversationsService } from '@shared/services/conversations.service';
import { Subscription } from 'rxjs';
import { TBotItem } from '@core/interfaces/TBotItem';

@Component({
  selector: 'app-message-controls',
  templateUrl: './message-controls.component.html',
  styleUrls: ['./message-controls.component.scss'],
})
export class MessageControlsComponent implements OnInit, OnDestroy {
  @Input() message: TAdaptedMessage | null = null;
  @Input() bot: TBotItem | null = null;
  @Input() hideControls = false;
  public conversationId: string | null = null;
  private _subs: Subscription[] = [];
  public canStar = true;

  constructor(
    private _clipboard: Clipboard,
    private _commonService: CommonService,
    protected botMessagesService: BotMessagesService,
    protected conversationsService: ConversationsService
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this.conversationsService.getConversationIdObservable().subscribe(r => {
        this.conversationId = r;
      })
    );
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  copyMessage(message: TAdaptedMessage) {
    this._clipboard.copy(this.botMessagesService.getCopyMessage(message));
    this._commonService.openSnackBar('Copied to clipboard');
  }

  toggleMessageStar(message: TAdaptedMessage) {
    if (!this.conversationId) {
      return;
    }
    const toggle = () => (message.favorite = !message.favorite);
    if (message.favorite) {
      this.conversationsService.unstarMessage(this.conversationId, message._id, toggle);
    } else {
      this.conversationsService.starMessage(this.conversationId, message._id, toggle);
      this._commonService.openSnackBar('Message saved to favorites');
    }
  }
}
