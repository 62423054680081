import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArrayUtils } from '@shared/utils/arrayUtils';

@Component({
  selector: 'app-prompt-hints',
  templateUrl: './prompt-hints.component.html',
  styleUrls: ['./prompt-hints.component.scss'],
})
export class PromptHintsComponent {
  protected randomHints: string[] = [];
  protected readonly itemsQty: number = 3;

  @Output() hintSelected = new EventEmitter<string>();

  @Input() set hints(hints: string[] | undefined) {
    if (hints) {
      this.randomHints = ArrayUtils.shuffle(hints).slice(0, this.itemsQty);
    }
  }


}
