<ng-container *ngIf="!selectedSource">
  <mat-table [dataSource]="dataSource" #table matSort (matSortChange)="handleSortChange($event)">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="d-flex align-items-center fs-14">
          <mat-icon class="icon" (click)="openDeleteSourceModal(row)">delete</mat-icon>
          <mat-icon class="icon edit-icon" (click)="openSourceDetails(row)">edit</mat-icon>
          <div class="d-flex flex-col">
            <span class="source-name">{{ row.name }}</span>
            <span class="reference">{{ row.references }}</span>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Last Edited</mat-header-cell>
      <mat-cell *matCellDef="let row"
        ><span class="fs-14">{{ row.modifiedDate | date: 'MM/dd/yy' }}</span></mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef class="justify-content-center">Actions</mat-header-cell>
      <mat-cell *matCellDef="let row" class="justify-content-center d-flex flex-col list">
        <app-add-to-list-button [source]="row"></app-add-to-list-button>
        <!--        SINGLE COMPARE AND SUMMARY -->
        <!--                <span [matTooltip]="summaryTooltipMessage" [matTooltipPosition]="'above'">-->
        <!--          <button-->
        <!--            mat-raised-button-->
        <!--            color="primary"-->
        <!--            class="action-button"-->
        <!--            (click)="goToSummaryBot(row._id)"-->
        <!--            [disabled]="!primarySummaryBotId || !row.content"-->
        <!--          >-->
        <!--            Make a summary-->
        <!--          </button>-->
        <!--        </span>-->
        <!--        <span [matTooltip]="compareTooltipMessage" [matTooltipPosition]="'above'">-->
        <!--          <button-->
        <!--            mat-raised-button-->
        <!--            color="primary"-->
        <!--            class="action-button"-->
        <!--            (click)="openCompareSourceModal(row)"-->
        <!--            [disabled]="!primaryCompareBotId || !row.content"-->
        <!--          >-->
        <!--            Compare-->
        <!--          </button>-->
        <!--        </span>-->
        <!--        com-->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef class="justify-content-center" mat-sort-header
        >Status</mat-header-cell
      >
      <mat-cell *matCellDef="let row" class="justify-content-center">
        <app-badge
          (click)="toggleStatus(row)"
          [color]="row.isProcessing ? 'disabled' : row.status"
          [clickable]="true"
          tooltip="Click to toggle the status"
        >
          {{ row.isProcessing === true ? 'Wait...' : (row.status | titlecase) }}
        </app-badge>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['name', 'updatedAt', 'actions', 'status']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['name', 'updatedAt', 'actions', 'status']"></mat-row>
  </mat-table>
  <mat-paginator
    #paginator
    (page)="handlePageEvent($event)"
    [length]="paging.total"
    [pageSize]="paging.limit"
    [pageSizeOptions]="pageSizes"
    [pageIndex]="paging.page - 1"
    aria-label="Select page"
  >
  </mat-paginator>
</ng-container>

<ng-container *ngIf="selectedSource">
  <app-sources-content
    [selectedItem]="selectedSource"
    (handleCancel)="handleCloseSource()"
  ></app-sources-content>
</ng-container>
