import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
})
export class DragAndDropComponent {
  @Output() handleFileUpload: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Input() maxInputFiles = 1;
  @Input() allowedExt: string[] = [];
  @Input() maxFileSizeMB = 6;

  public files: NgxFileDropEntry[] = [];
  protected errorMsg: string[] = [];

  public dropped(files: NgxFileDropEntry[]) {

    this.files = files;
    this.errorMsg = [];
    this.validateFiles();

    const filesToEmit: File[] = [];

    this.files.forEach(droppedFile => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.validateSingleFile(file);
          filesToEmit.push(file);
        });
      }
    });

    if (this.errorMsg.length === 0) {
      this.handleFileUpload.emit(filesToEmit);
    }else {
      this.handleFileUpload.emit([]);
    }
  }

  private validateFiles() {
    if (this.files.length > this.maxInputFiles) {
      this.errorMsg.push(`Up to ${this.maxInputFiles} files allowed`);
    }
  }

  private validateSingleFile(file: File) {
    const fileExtension = file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2);
    if (!this.allowedExt.includes(fileExtension)) {
      this.errorMsg.push(`Not allowed extension for "${file.name}" file. Allowed extensions: ${this.allowedExt.join(', ')}.`);
    }
    if ((file.size / 1024) > this.maxFileSizeMB * 1024) {
      this.errorMsg.push(`File "${file.name}" is to big. Max size is: ${this.maxFileSizeMB} MB"`);
    }
  }
}
