import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Endpoints } from '@core/interfaces/Endpoints';
import { TBotItem } from '@core/interfaces/TBotItem';
import { TFolderItem } from '@core/interfaces/TFolderItem';
import { TSearchIn, TSearchResponse } from '@core/interfaces/TSearch';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private bots: BehaviorSubject<TBotItem[]> = new BehaviorSubject<TBotItem[]>([]);
  private folders: BehaviorSubject<TFolderItem[]> = new BehaviorSubject<TFolderItem[]>([]);
  private searchResponse: BehaviorSubject<TSearchResponse | undefined> = new BehaviorSubject<
    TSearchResponse | undefined
  >(undefined);
  private isSearchLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _http: HttpClient) {}

  public getBotsObservable(): Observable<TBotItem[]> {
    return this.bots.asObservable();
  }

  public getFoldersObservable(): Observable<TFolderItem[]> {
    return this.folders.asObservable();
  }

  public getSearchResponseObservable(): Observable<TSearchResponse | undefined> {
    return this.searchResponse.asObservable();
  }

  public getSearchLoadingObservable(): Observable<boolean> {
    return this.isSearchLoading.asObservable();
  }

  public search(searchIn: TSearchIn, query: string, page: number, folderId?: string): void {
    this.isSearchLoading.next(true);
    const response = this._http.post<TSearchResponse>(Endpoints.search.search.url(page, 10), {
      searchIn,
      query,
      folderId,
    });

    response.subscribe({
      error: err => {
        console.log(err);
        this.isSearchLoading.next(false);
      },
      next: res => {
        this.searchResponse.next(res);
        this.isSearchLoading.next(false);
      },
    });
  }

  public instantSearch(searchIn: TSearchIn, query: string, folderId?: string): void {
    this.isSearchLoading.next(true);
    const response = this._http.post<TSearchResponse>(Endpoints.search.instantSearch.url(5), {
      searchIn,
      query,
      folderId,
    });

    response.subscribe({
      error: err => {
        console.log(err);
        this.isSearchLoading.next(false);
      },
      next: res => {
        this.searchResponse.next(res);
        this.isSearchLoading.next(false);
      },
    });
  }

  public clearSearch(): void {
    this.searchResponse.next(undefined);
  }

  public getFolderList(): void {
    const response = this._http.get<TFolderItem[]>(Endpoints.search.getFolderList.url);
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: response => {
        this.folders.next(response);
      },
    });
  }
  public getBotList(): void {
    const response = this._http.get<TBotItem[]>(Endpoints.search.getBotList.url);
    response.subscribe({
      error: err => {
        console.log(err);
      },
      next: response => {
        this.bots.next(response);
      },
    });
  }
}
