import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';

import { TConversation } from '@core/interfaces/TResultsItem';
import { TSearchIn, TSearchResponseItem } from '@core/interfaces/TSearch';
import { TSourceItem } from '@core/interfaces/TSourceItem';

import { SearchService } from '@shared/services/search.service';
import { initialPaging } from '@shared/utils/paging';

@Component({
  selector: 'app-all-search-results',
  templateUrl: './all-search-results.component.html',
  styleUrls: ['./all-search-results.component.scss'],
  providers: [SearchService],
})
export class AllSearchResultsComponent implements OnInit, OnDestroy {
  @Output() public actionCallback = new EventEmitter();
  private _subs: Subscription[] = [];
  conversations: TSearchResponseItem<TConversation> = {} as TSearchResponseItem<TConversation>;
  sources: TSearchResponseItem<TSourceItem> = {} as TSearchResponseItem<TSourceItem>;
  paging = initialPaging;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { query: string; searchIn: TSearchIn },
    private dialogRef: MatDialogRef<AllSearchResultsComponent>,
    private _searchService: SearchService
  ) {}

  ngOnInit(): void {
    this._searchService.search(this.data.searchIn, this.data.query, 0);

    this._subs.push(
      this._searchService.getSearchResponseObservable().subscribe(res => {
        if (res && this.data.searchIn) {
          this.conversations = res.conversations;
          this.sources = res.sources;
        }
      })
    );
    this._searchService.getSearchLoadingObservable().subscribe(res => {
      this.loading = res;
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  cancel() {
    this.dialogRef.close();
  }

  handlePageEvent(e: PageEvent) {
    this.paging.page = e.pageIndex + 1;
    this._searchService.search(this.data.searchIn, this.data.query, this.paging.page);
  }

  totalResults() {
    if (this.conversations.total > 0) {
      return this.conversations.total;
    } else if (this.sources.total > 0) {
      return this.sources.total;
    }
    return 0;
  }

  selectResult(selected: TConversation | TSourceItem) {
    this.actionCallback.emit(selected);
  }
}
