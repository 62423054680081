import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '@core';
import { filter, map } from 'rxjs/operators';

@Directive({
  selector: '[allowedRole]',
})
export class HasRoleDirective {
  private subscription: Subscription | undefined;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
  ) {
  }

  @Input()
  set allowedRole(role: string) {
    this.subscription = this.authService.user().pipe(
      map(user => user.roles?.includes(role)),
      filter(Boolean),
    ).subscribe(isVisible => {
      if (isVisible) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
      this.subscription?.unsubscribe();
    });
  }

}
