<div class="new-item-modal">
    <h3 class="mat-h3">Add new file</h3>
    <div class="mat-description m-b-24">
      <div>You are going to upload file</div> {{data.file.name}}</div>
      <div class="d-flex justify-content-center">
        <mat-progress-spinner
          *ngIf="isLoading"
          class="spinner"
          [color]="'accent'"
          [mode]="'indeterminate'"
          [value]="50">
        </mat-progress-spinner>
      </div>
      <div class="d-flex justify-content-between">
        <button
          class="m-r-24"
          mat-raised-button
          color="accent"
          (click)="cancel()"
          [disabled]="isLoading">
          Cancel
        </button>
        <button
          mat-raised-button
          color="accent"
          (click)="addNewFile()"
          [disabled]="isLoading">
            Yes, add new file
        </button>
      </div>
</div>
