import { Component, Input } from '@angular/core';
import { DriverBase } from '@shared/components/file-preview-modal/drivers/driver-base';
import { getFileNameAndExtension } from '../../../../../modules/batch-upload/batch-upload/helpers/helpers';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent extends DriverBase {
  protected blobUrl: string | undefined;

  @Input() set fileLink(link: string) {
    this.loadFileContent(link, data => {
      const blob = new Blob([data], {
        type: this.mimeTypes[getFileNameAndExtension(link).extension],
      });
      this.blobUrl = URL.createObjectURL(blob);
    });
  }

  mimeTypes: { [key: string]: string } = {
    pdf: 'application/pdf',
    txt: 'text/plain',
    csv: 'text/csv',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rtf: 'application/rtf',
    html: 'text/html',
    css: 'text/css',
    png: 'image/png',
    jpg: 'image/jpg',
  };
}
