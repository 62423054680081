import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-counter-badge',
  templateUrl: './counter-badge.component.html',
  styleUrls: ['./counter-badge.component.scss'],
})
export class CounterBadgeComponent {
  @Input() number = 0;
  @Input() tick = false;
}
