import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '@shared';
import { AppAuthListComponent } from './app-auth-list/app-auth-list.component';
import { AuthService, User } from '@core';
import { UsersService } from '@shared/services/users.service';
import { CommonService } from '@shared/services/common.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: './two-factor-auth-qr-code-modal.component.html',
  styleUrls: ['./two-factor-auth-qr-code-modal.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    SharedModule,
    AppAuthListComponent,
  ],
})
export class TwoFactorAuthQrCodeModalComponent {
  protected confirmation = false;
  protected state: 'confirmation' | 'displayQr' | 'loading' = 'confirmation';
  protected twoFactorAuthData = {
    qr: '',
    secret: '',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: User, disableCancel: boolean },
    private _dialogRef: MatDialogRef<TwoFactorAuthQrCodeModalComponent>,
    private _usersService: UsersService,
    private _commonService: CommonService,
    private _authService: AuthService,
    private _router: Router,
  ) {
  }

  initialMessage(): string {
    if (this.data.disableCancel) {
      return 'The administrator of this application set the required two-factor authentication. You have to set this feature for safety reasons. Please click "Yes, confirm" to continue, or log out from the application.';
    }
    return `Are you sure you want to ${this.data.user.is2FAEnabled ? 'disable' : 'enable'} two-factor authorization?`;
  }

  confirm() {
    this.state = 'loading';
    const toState = !this.data.user.is2FAEnabled!;

    if (!toState) {
      this.cancel();
    }

    this._usersService.toggle2Fa(this.data.user.name!, toState).subscribe(value => {
      this.data.user.is2FAEnabled = toState;

      if (!toState) {
        this._commonService.openSnackBar('Two factor authorization disabled');
        this.cancel();
        return;
      }
      this.twoFactorAuthData.qr = value.qrCodeUrl;
      this.twoFactorAuthData.secret = value.secret;
      this.state = 'displayQr';
    });
  }

  cancel() {
    this._dialogRef.close();
  }

  logout() {
    this.cancel();
    this._authService.logout().subscribe(() => this._router.navigateByUrl('/auth/login'));
  }
}
