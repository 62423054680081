<ng-container *ngIf="showList && !selectedMaterial">
  <div class="header folders-header">
    <div class="row align-items-center justify-content-between m-b-24">
      <div class="d-flex align-items-center justify-content-center">
        <h3 class="mat-h3 m-t-8 m-b-8">{{ folder?.name }}</h3>
      </div>
      <button mat-raised-button class="m-l-16 control" color="accent" (click)="editFolder()">
        Edit folder
      </button>
    </div>

    <div>
      <button mat-raised-button color="accent" (click)="uploadMaterial()" data-cy="upload-material">
        <mat-icon>add</mat-icon>
        Upload
      </button>
    </div>
  </div>

  <ng-container *ngIf="folder">
    <mat-table [dataSource]="dataSource" #table matSort (matSortChange)="handleSortChange()">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="d-flex align-items-center fs-14">
            <mat-icon class="icon" (click)="deleteItem(row)">delete</mat-icon>
            <mat-icon class="icon edit-icon" (click)="editMaterial(row)">edit</mat-icon>
            <div class="d-flex flex-col">
              <span class="source-name">{{ row.name }}</span>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="keywords">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Keywords</mat-header-cell>
        <mat-cell *matCellDef="let row"
          ><span class="fs-14">{{ row.keywords }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="fileType">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Material type</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span>{{ row.fileType }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="modifiedDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Last Edited</mat-header-cell>
        <mat-cell *matCellDef="let row"
          ><span class="fs-14">{{ row.modifiedDate | date: 'MM/dd/yy' }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="justify-content-center">Actions</mat-header-cell>
        <mat-cell *matCellDef="let row" class="justify-content-center d-flex flex-col list">
          <app-add-to-list-button [source]="row"></app-add-to-list-button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="justify-content-center"
          >Status</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="justify-content-center">
          <app-badge
            (click)="toggleStatus(row)"
            [color]="row.isProcessing ? 'disabled' : row.status.toLowerCase()"
            [clickable]="true"
            tooltip="Click to toggle the status"
          >
            {{ row.isProcessing === true ? 'Wait...' : (row.status | titlecase) }}
          </app-badge>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="['name', 'keywords', 'fileType', 'modifiedDate', 'status']"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: ['name', 'keywords', 'fileType', 'modifiedDate', 'status']"
      ></mat-row>
    </mat-table>
  </ng-container>
</ng-container>

<app-sources-materials-form
  *ngIf="selectedMaterial"
  [selectedMaterial]="selectedMaterial"
  [folder]="folder!"
  (closeForm)="selectedMaterial = null"
></app-sources-materials-form>
