import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsersApi } from '@core/api/users/api';
import { BehaviorSubject } from 'rxjs';
import { TBaseUser } from '@core/interfaces/Endpoints';
import { CommonService } from '@shared/services/common.service';
import { INITIAL_PAGING, TPaging } from '@core/interfaces/TPaging';
import { IGNORE_ERRORS_KEY } from '@core';
import { TEnable2FA } from '@core/api/users/types';

@Injectable({
  providedIn: 'root',
})
export class UsersService {

  private _users = new BehaviorSubject<TBaseUser[]>([]);
  users$ = this._users.asObservable();

  usersPaging$: BehaviorSubject<TPaging> = new BehaviorSubject<TPaging>(INITIAL_PAGING);

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
  ) {
  }

  public setUsersPaging(paging: TPaging): void {
    this.usersPaging$.next(paging);
  }

  getUsers(page = 1, limit = 10): void {
    this._commonService.setIsLoading(true);
    const { url } = UsersApi.getUsers({ page, limit } as TPaging);
    const response = this._http.get<{
      total: number;
      page: number;
      limit: number;
      result: TBaseUser[];
    }>(url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: res => {
        const users = res.result.map((user: TBaseUser) => {
          return user;
        });
        this._users.next(users);
        this.setUsersPaging({
          total: res.total,
          page: res.page,
          limit: res.limit,
        });
      },
      complete: () => {
        this._commonService.setIsLoading(false);
      },
    });
  }

  createUser(user: TBaseUser) {
    const { url, body } = UsersApi.createUser(user);
    return this._http.post<TBaseUser>(url, body);
  }

  updateUser(user: TBaseUser) {
    const { url, body } = UsersApi.updateUser(user);
    return this._http.put<TBaseUser>(url, body);
  }

  deleteUser(user: TBaseUser) {
    const { url } = UsersApi.deleteUser(user);
    return this._http.delete<TBaseUser>(url);
  }

  searchUser(search: string) {
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    const { url } = UsersApi.searchUser(search);
    return this._http.get<TBaseUser[]>(url, { headers });
  }

  attachUsersToProject(projectId: string, userIds: string[]) {
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    const { url, body } = UsersApi.updateProjectUsers(projectId, userIds);
    return this._http.post(url, body, { headers });
  }

  detachUserFromProject(projectId: string, username: string) {
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    const { url } = UsersApi.detachUserFromProject(projectId, username);
    return this._http.delete(url, { headers });
  }

  getUsersForProject(projectId: string) {
    const { url } = UsersApi.getUsersForProjects(projectId);
    return this._http.get<TBaseUser[]>(url);
  }

  toggle2Fa(username: string, toState: boolean) {
    const { url } = UsersApi.toggle2Fa(username, toState);
    return this._http.post<TEnable2FA>(url, null);
  }
}
