<div class="new-item-modal">
    <h3 class="mat-h3">Edit Folder</h3>
    <form [formGroup]="editFolderForm">
        <div class="form-inputs">
          <span class="custom-label">Folder name</span>
          <mat-form-field class="gingr-input-wrapper form-field-full">
              <input
              matInput
              placeholder="name"
              type="text"
              formControlName="name"
              required />
              <mat-error *ngIf="name.invalid">
              <span *ngIf="name.errors?.required">Please enter name</span>
              </mat-error>
          </mat-form-field>
        </div>

      <button
        class=""
        mat-raised-button
        color="accent"
        [disabled]="!!editFolderForm.invalid"
        (click)="updateFolder()">
        Save
      </button>
    </form>
  </div>