import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ContentItemType } from '@core/interfaces/ContentItemType';
import { TBaseContentItem } from '@core/interfaces/TBaseContentItem';
import { TBotItem } from '@core/interfaces/TBotItem';
import { TFolderItem } from '@core/interfaces/TFolderItem';
import { TProjectItem } from '@core/interfaces/TProjectItem';
import { TResultItem } from '@core/interfaces/TResultsItem';

import { BotsService } from '@shared/services/bots.service';
import { CommonService } from '@shared/services/common.service';
import { FoldersService } from '@shared/services/folders.service';
import { JobStatusService } from '@shared/services/job-status.service';
import { ProjectsService } from '@shared/services/projects.service';
import { ResultsService } from '@shared/services/results.service';
import { isCustomIcon, TIconItem } from '@shared/utils/isCustomIcon';
import { isNotNullAndDefined } from '@shared/utils/isNotNullAndDefined';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { getNavigationRoute } from '@shared/utils/routing';

import {
  allowedToDeleteContentItems,
  allowedToDisplayItemInfo,
} from '../../../enums/content-items';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';

export interface TGroup<T> {
  name: string;
  groupItems: TBaseContentItem<T>[];
  isOpened: boolean;
  todayItems?: TBaseContentItem<T>[];
  yesterdayItems?: TBaseContentItem<T>[];
  last7DaysItems?: TBaseContentItem<T>[];
  last30DaysItems?: TBaseContentItem<T>[];
  olderItems?: TBaseContentItem<T>[];
}

@Component({
  selector: 'app-gingr-content',
  templateUrl: './gingr-content.component.html',
  styleUrls: ['./gingr-content.component.scss'],
})
export class GingrContentComponent<T> implements OnInit, OnChanges, OnDestroy {
  @Input() public title = `Drawer Title`;
  @Input() type: ContentItemType | null = null;
  @Input() public items: TBaseContentItem<T>[] = [];
  @Input() public drawerOpened = true;
  @Input() public addNewItem: () => void = () => console.log(`Add new item - default`);
  public groups: TGroup<T>[] = [];
  public isGroupSelected = false;

  public selectedItem: TBaseContentItem<T> | null | any = null;

  private _subs: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private _commonService: CommonService,
    private _botService: BotsService,
    private _projectsService: ProjectsService,
    private _foldersService: FoldersService,
    private _resultsService: ResultsService,
    private _jobsStatusService: JobStatusService
  ) {}

  public ngOnInit(): void {
    this._subs.push(
      this._resultsService.getCurrentConversationObservable().subscribe(result => {
        if (result && this.selectedItem && result?._id === this.selectedItem?._id) {
          this.selectedItem = result;
        }
      })
    );
    this._subs.push(this._jobsStatusService.statusPoolingSubscribe());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.items?.previousValue !== changes.items?.currentValue) {
      if (this.items.length) {
        this.route.children.forEach(child => {
          this._subs.push(
            child.params.subscribe(params => {
              const id = params.id.split('?')[0];
              if (id) {
                const item = this.items.find(item => item._id === id);
                if (item) {
                  this.selectedItem = item;
                }
              }
            })
          );
        });
      }
    }
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  public toggleGroup(group: TGroup<T>): void {
    group.isOpened = !group.isOpened;
  }

  public isCustomIcon(item: TIconItem): boolean {
    return isCustomIcon(item);
  }

  public hasSufix(item: TBaseContentItem<T>): boolean {
    return isNotNullAndDefined(item.sufix);
  }

  public canDeleteItem(item: TBaseContentItem<T>): boolean {
    return allowedToDeleteContentItems.includes(item.__type);
  }

  public canDisplayItemInfo(item: TBaseContentItem<T>): boolean {
    return !!item.description && allowedToDisplayItemInfo.includes(item.__type);
  }

  public toggleDrawer(): void {
    this.drawerOpened = !this.drawerOpened;
  }

  public isBotsContent(): boolean {
    return this.selectedItem && this.selectedItem.__type === ContentItemType.Bots;
  }

  public isProjectsContent(): boolean {
    return this.selectedItem && this.selectedItem.__type === ContentItemType.Projects;
  }

  public selectItem(item: TBaseContentItem<T>): void {
    this.selectedItem = item;
    this.isGroupSelected = Boolean(this.selectedItem.group);
    const navigationRoute = getNavigationRoute(item.__type);
    this.router.navigateByUrl(`/${navigationRoute}/${item._id}`);
  }

  public getItemCss(item: TBaseContentItem<T>): string {
    const classes: string[] = [];
    if (item.tinyIcon === true) {
      classes.push(`menu-icon__tiny`);
    }
    return classes.join(` `);
  }

  public hasIcon(item: TBaseContentItem<T>): boolean {
    return !isNullOrEmpty(item.icon);
  }

  public openDeleteItemModal(item: TBaseContentItem<T>): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { text: `Are you sure you want to delete "${item.title}"?` },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      let id = '';
      switch (item.__type) {
        case ContentItemType.Bots:
          id = (item as TBotItem)._id as string;
          this._botService.deleteBot(id, () => {
            dialogRef.close();
            this._commonService.openSnackBar(`Bot ${item.title} was deleted`);
          });
          return;
        case ContentItemType.Projects:
          id = (item as TProjectItem)._id as string;
          this._projectsService.deleteProject(id, () => {
            dialogRef.close();
            this._commonService.openSnackBar(`Project ${item.title} was deleted`);
          });
          return;
        case ContentItemType.Folders:
          id = (item as TFolderItem)._id as string;
          this._foldersService.deleteFolder(id, () => {
            dialogRef.close();
            this._commonService.openSnackBar(`Folder ${item.title} was deleted`);
          });
          return;
        case ContentItemType.ResultConversation:
          id = (item as TResultItem)._id as string;
          this._resultsService.deleteConversation(id, () => {
            dialogRef.close();
            this._commonService.openSnackBar(`Conversation ${item.title} was deleted`);
          });
          this.selectedItem = this.groups.find(group =>
            group.groupItems.find(groupItem => groupItem._id === id)
          );
      }
      dialogSubscription.unsubscribe();
    });
  }

  protected readonly ContentItemType = ContentItemType;
}
