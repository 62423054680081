<div class="row align-items-center justify-content-between m-b-24">
  <h3 class="mat-h3 m-b-0">Project's users</h3>
</div>
<ng-container *allowedRole="'admin'">
  <h4>Assign users to project</h4>
  <app-users-selector
    (selectedUsers)="attachUsers($event)"
  ></app-users-selector>
</ng-container>

<h4 *allowedRole="'admin'">Users assigned to project</h4>
<mat-table [dataSource]="users" #table *ngIf="!isLoading; else spinner">

  <ng-container matColumnDef="username">
    <mat-header-cell *matHeaderCellDef>Username</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div class="d-flex align-items-center justify-content-between">
        <span class="username">{{ row.username }}</span>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions" >
    <mat-header-cell *matHeaderCellDef>Delete</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <app-badge
        (click)="detachUser(row)"
        [color]="'inactive'"
        [clickable]="true"
      >
        Remove
      </app-badge>
    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="allowedColumnsForRole"></mat-header-row>
  <mat-row *matRowDef="let row; columns: allowedColumnsForRole"></mat-row>
</mat-table>
<ng-template #spinner>
  <app-spinner></app-spinner>
</ng-template>


