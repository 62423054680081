import { environment } from '@env/environment';

import { DeleteRequestData, GetRequestData, PostRequestData } from '../utils';

export class BotApi {


  static getBotHistory(botId: string): GetRequestData {
    return {
      url: `${environment.backendURL}/api/bots/history/${botId}`,
    };
  }

  static restoreVersion(versionId: string): PostRequestData<null> {
    return {
      url: `${environment.backendURL}/api/bots/history/${versionId}/restore`,
    };
  }

  static deleteVersion(versionId: string): DeleteRequestData {
    return {
      url: `${environment.backendURL}/api/bots/history/${versionId}`,
    };
  }

  static getBotsModels(): GetRequestData {
    return {
      url: `${environment.backendURL}/api/bots/models`,
    };
  }
}
