import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private _snackBar: MatSnackBar) {}

  public getIsLoadingObservable(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  public openSnackBar(message: string, actionLabel = 'Close', duration = 3000) {
    return this._snackBar.open(message, actionLabel, { duration });
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading.next(isLoading);
  }
}
