import { TMessage } from '@core/interfaces/TMessage';
import { TCurrentConversation } from '@core/interfaces/TResultsItem';

import { TAdaptedBotConversation, TAdaptedMessage, TReturnedFileData } from '../types/types';
import { MessageParser } from '@shared/services/bots/helpers/messageParser';
import { BaseAdapter } from '@shared/services/bots/adapters/BaseAdapter';

export class BotResultsAdapter extends BaseAdapter {
  adapt(data: TCurrentConversation): TAdaptedBotConversation {
    this.adaptedData = {
      id: data._id,
      messages: this.adaptMessages(data.messages, data.uploadedFiles as TReturnedFileData[]),
      language: data.language,
      favorite: data.favorite,
      type: 'results',
    };

    return this.adaptedData;
  }

  protected adaptMessages(msg: TMessage[], files: TReturnedFileData[] = []): TAdaptedMessage[] {
    const results: TAdaptedMessage[] = [];
    msg?.forEach(originalMsg => {
      const data = MessageParser.parseMaterialsWithReferences(originalMsg);
      results.push(data);
    });
    if (results.length > 0) {
      results[0].files = files;
    }
    return results;
  }
}
