import { Component, OnDestroy } from '@angular/core';
import { TwoFactorAuthQrCodeModalComponent } from '../two-factor-auth-qr-code/two-factor-auth-qr-code-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, User } from '@core';
import { Subscription } from 'rxjs';
import { AppSettingsService } from '@shared/services/settings/app-settings.service';

@Component({
  selector: 'app-two-factor-auth-monitor',
  standalone: true,
  template: ``,
})
export class TwoFactorAuthMonitorComponent implements OnDestroy {
  private subs: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private auth: AuthService,
    private appSettings: AppSettingsService,
  ) {
    this.subs.push(this.auth.user().subscribe(user => {
      if ('is2FAEnabled' in user) {
        this.checkUser(user);
      }
    }));
  }

  checkUser(user: User): void {
    if (user.is2FAEnabled) {
      return;
    }
    if (this.appSettings.getSettingsValue().two_factor_auth_required) {
      this.openModal(user);
    }
  }

  openModal(user: User) {
    this.dialog.open(TwoFactorAuthQrCodeModalComponent, {
      disableClose: true,
      data: { user, disableCancel: true },
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(value => value.unsubscribe());
  }
}
