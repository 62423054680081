import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface TileOptionModalData {
  actionLabel: string;
  option1: string;
  option2: string;
}

type Option = 'option1' | 'option2';

@Component({
  selector: 'app-tile-option-modal',
  templateUrl: './tile-option-modal.component.html',
  styleUrls: ['./tile-option-modal.component.scss']
})
export class TileOptionModalComponent {
  @Output() handleActionClick = new EventEmitter<string>();
  selectedOption: Option | undefined;
  constructor(@Inject(MAT_DIALOG_DATA) public data: TileOptionModalData) {}

  handleOption1Click() {
    this.selectedOption = 'option1';
  }

  handleOption2Click() {
    this.selectedOption = 'option2';
  }

  onActionClick() {
    this.handleActionClick.emit(this.selectedOption);
  }
}
