import { Component, EventEmitter, Input, Output } from '@angular/core';

export type TSimplePaginatorNav = 'prev' | 'next'

@Component({
  selector: 'app-simple-paginator',
  templateUrl: './simple-paginator.component.html',
  styleUrls: ['./simple-paginator.component.scss'],
})
export class SimplePaginatorComponent {
  @Input() disable: { next: boolean, prev: boolean } = {
    next: true,
    prev: false,
  };

  @Output() navChanged = new EventEmitter<TSimplePaginatorNav>();
}
