import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TBotCreator, TInnerBot } from '@core/interfaces/TBot';

import { BotsService } from '@shared/services/bots.service';
import { CommonService } from '@shared/services/common.service';

import { InnerBotModalComponent } from '../inner-bot-modal/inner-bot-modal.component';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { TBotItem } from '@core/interfaces/TBotItem';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inner-bots',
  templateUrl: './inner-bots.component.html',
  styleUrls: ['./inner-bots.component.scss'],
})
export class InnerBotsComponent implements OnInit, OnDestroy{

  @Input()
  public set bot(bot: TBotItem) {
    if (this.botCreator._id !== '' && bot.botCreatorId && this.botCreator._id !== bot.botCreatorId) { //prevent unwanted requests
      this._botsService.fetchBotCreator(bot.botCreatorId);
    }
  }
  private _subs: Subscription[] = [];
  public botCreator: TBotCreator = {} as TBotCreator;
  constructor(
    public dialog: MatDialog,
    private _botsService: BotsService,
    private _commonService: CommonService
  ) {}


  ngOnInit(): void {
    this._subs.push(
      this._botsService.getBotCreatorObservable().subscribe(botCreator => {
        this.botCreator = botCreator;
      }),
    );
  }

  public addBots(): void {
    this.dialog.open(InnerBotModalComponent, { data: { botCreatorId: this.botCreator._id } });
  }

  public openEditBot(bot: TInnerBot): void {
    this.dialog.open(InnerBotModalComponent, { data: { botCreatorId: this.botCreator._id, bot } });
  }

  drop(event: CdkDragDrop<TInnerBot[]>) {
    moveItemInArray(this.botCreator.bots, event.previousIndex, event.currentIndex);
    this._botsService.updateBotsOrder(this.botCreator._id, this.botCreator.bots, () => {
      this._commonService.openSnackBar(`Bots order updated`);
    });
  }

  public removeBotModal(bot: TInnerBot): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { text: `Are you sure you want to delete ${bot.name} bot?` },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      this._botsService.removeInnerBot(this.botCreator._id, bot._id, () => {
        dialogRef.close();
        this._commonService.openSnackBar(`Bot ${bot.name} was deleted`);
        dialogSubscription.unsubscribe();
      });
    });
  }


  ngOnDestroy(): void {
    this._subs.forEach(value => value.unsubscribe());
  }
}
