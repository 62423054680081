import { Component, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { TBaseUser, UserRole } from '@core/interfaces/Endpoints';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UsersService } from '@shared/services/users.service';
import { CommonService } from '@shared/services/common.service';


@Component({
  selector: 'app-user-edit-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss'],
})
export class UserModalComponent implements OnDestroy {

  @Output() userChanged = new EventEmitter<boolean>();

  private _subs: Subscription[] = [];
  protected isEditMode = false;
  protected roles: UserRole[] = ['admin', 'user'];
  protected isInitializing = true;

  public userForm = this._fb.nonNullable.group({
    username: ['', [Validators.required, Validators.minLength(3)]],
    password: ['', [Validators.required, Validators.minLength(5)]],
    roles: this.buildRoles(),
  });

  constructor(
    private dialogRef: MatDialogRef<UserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TBaseUser | null,
    private _router: Router,
    private _fb: FormBuilder,
    private _usersService: UsersService,
    private _commonService: CommonService,
  ) {
    this._subs.push(dialogRef.beforeClosed().subscribe(() => {
      this._router.navigate([]);
    }));

    this._subs.push(dialogRef.afterOpened().subscribe(() => {
      if (this.data) {
        this.isEditMode = true;
        this.updateFormValues(this.data!);
      }
      this.updateFormValidations();
      this.isInitializing = false;
    }));
  }

  private buildRoles() {
    const group: { [key in UserRole]: FormControl } = {} as { [key in UserRole]: FormControl };
    this.roles.forEach(role => {
      group[role] = this._fb.control(false);
    });
    return this._fb.group(group);
  }

  private updateFormValues(data: TBaseUser) {
    this.userForm.patchValue({
      username: data.username,
      roles: this.roles.reduce((result: { [key in UserRole]: boolean }, role) => {
        result[role] = data!.roles.includes(role);
        return result;
      }, {} as { [key in UserRole]: boolean }),
    });
  }

  private updateFormValidations() {
    if (this.isEditMode) {
      this.userForm.get('username')!.disable();
      this.userForm.get('password')!.setValidators([]);
      this.userForm.get('password')!.updateValueAndValidity();
    }
  }

  protected cancel() {
    this.dialogRef.close();
  }

  protected save() {
    const userValue = this.userForm.getRawValue() as unknown as TBaseUser;
    userValue.roles = this.getRolesAsArray();

    const processUser = (method: (value: typeof userValue) => Observable<any>, message: string) => {
      method(userValue).subscribe(() => {
        this.userChanged.emit(true);
        this._commonService.openSnackBar(`User "${userValue.username}" ${message}`);
        this.cancel();
      });
    };

    if (this.isEditMode) {
      processUser((value) => this._usersService.updateUser(value), 'updated');
    } else {
      processUser((value) => this._usersService.createUser(value), 'created');
    }
  }

  private getRolesAsArray(): UserRole[] {
    const roles = this.userForm.value.roles as Record<string, boolean>;
    return Object.keys(roles).filter(role => roles[role]) as UserRole[];
  }

  ngOnDestroy(): void {
    this._subs.forEach(value => value.unsubscribe());
  }


}
