<h3 class="p-24 p-l-32 mat-h3">Edit your API access settings</h3>
<div class="p-24 p-t-0 d-flex flex-col w-full">
  <h3 class="mat-h3">RISE settings</h3>
  <div class="m-b-24 m-t-8 suffix">To use RISE publications and clinical trials as your source data, you must provide
    credentials to RISE.
  </div>
  <form class="form-field-full" [formGroup]="editECRAccessForm">
    <h4 class="m-b-8 m-t-0">E-mail</h4>
    <mat-form-field class="gingr-input-wrapper w-full">
      <input
        matInput
        type="text"
        formControlName="name"
      />
    </mat-form-field>

    <h4 class="m-b-8 m-t-0">Password</h4>
    <mat-form-field class="gingr-input-wrapper w-full">
      <input
        matInput
        type="password"
        formControlName="password"
      />
    </mat-form-field>

    <button
      class="m-b-24"
      mat-raised-button
      color="accent"
      [disabled]="!editECRAccessForm.valid"
      (click)="saveECRAccessSettings()">
      Save
    </button>
  </form>
  <h3 class="mat-h3">Confluence settings</h3>
  <div class="m-b-24 m-t-8 suffix">To import Confluence pages as your source data, you have to provide API settings.</div>
  <form class="form-field-full" [formGroup]="confluenceSettings">
    <h4 class="m-b-8 m-t-0">Username</h4>
    <mat-form-field class="gingr-input-wrapper w-full">
      <input
        matInput
        formControlName="username"
      />
    </mat-form-field>
    <h4 class="m-b-8 m-t-0">API token</h4>
    <div class="m-b-24 m-t-8 suffix">To get API token go to Confluence Manage Account page then Security then Create and Manage API tokens</div>

    <mat-form-field class="gingr-input-wrapper w-full">
      <input
        matInput
        type="password"
        formControlName="password"
      />
    </mat-form-field>
    <h4 class="m-b-8 m-t-0">Url</h4>
    <div class="m-b-24 m-t-8 suffix">The URL for your Confluence instance including: <code>/wiki/rest/api</code></div>
    <mat-form-field class="gingr-input-wrapper w-full">
      <input
        matInput
        formControlName="url"
      />
    </mat-form-field>

    <button
      class="m-b-24"
      mat-raised-button
      color="accent"
      [disabled]="!confluenceSettings.valid"
      (click)="saveConfluenceSettings()">
      Save
    </button>
  </form>
</div>
