import { priorityLabels, PriorityValue } from '@shared/utils/priority';

export function getPriorityLabel(priority: number | null): string {
  if (!priority) return '';
  return priorityLabels[priority as PriorityValue];
}

export function getFileNameAndExtension(file: File | string): { name: string, extension: string } {
  const fullPath = typeof file === 'string' ? file : file.name;

  const baseName = fullPath.split(/[\\/]/).pop() || '';
  const name = baseName.slice(0, baseName.lastIndexOf('.'));
  const extension = baseName.slice(baseName.lastIndexOf('.') + 1).toLowerCase();
  return { name, extension };
}

