import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { allowedStatuses } from '../../../../enums/status';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-source-folder-form',
  templateUrl: './source-folder-form.component.html',
  styleUrls: ['./source-folder-form.component.scss'],
})
export class SourceFolderFormComponent implements OnInit {

  protected readonly statuses = allowedStatuses;
  @Output() formChange: EventEmitter<any> = new EventEmitter();
  @Input() isDisabled = false;

  constructor(
    private fb: FormBuilder,
  ) {
  }

  public newFolderForm = this.fb.nonNullable.group({
    name: ['', [Validators.required]],
    status: ['', [Validators.required]],
  });

  ngOnInit() {
    this.newFolderForm.valueChanges.subscribe(value => {
      this.formChange.emit(this.newFolderForm.valid ? value : null);
    });
  }
}
