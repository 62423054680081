import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaterialTypeInput } from '@core/api/materials/types';

@Component({
  selector: 'app-material-file-input',
  templateUrl: './material-file-input.component.html',
  styleUrls: ['./material-file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialFileInputComponent),
      multi: true,
    },
  ],

})
export class MaterialFileInputComponent implements ControlValueAccessor {

  private _type: MaterialTypeInput | undefined;
  protected file: any;
  protected isDisabled = false;
  private onChange!: (value: any) => void;
  private onTouched!: () => void;

  @Input()
  set type(value: MaterialTypeInput) {
    if (value) {
      this._type = value;
      this.file = undefined;
      this.onInput();
    }
  }

  get type(): MaterialTypeInput | undefined {
    return this._type;
  }

  protected inputTypes: { title: string, type: MaterialTypeInput, ext: string[], size: number }[] = [
    {
      type: 'file',
      title: 'Document',
      ext: ['doc', 'docx', 'pptx', 'pdf', 'txt'],
      size: 5,
    },
    {
      type: 'image',
      title: 'Image',
      ext: ['png', 'webp', 'jpg', 'jpeg'],
      size: 5,
    },
    {
      type: 'video',
      title: 'Video',
      ext: ['mp4', 'webm', 'ogg'],
      size: 100,
    },
  ];


  writeValue(obj: any): void {
    this.file = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInput(data: any = null): void {
    if (data) {
      this.file = data;
    }
    this.onChange(this.file);
    this.onTouched();
  }

}
