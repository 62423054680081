<div class="new-item-modal history-modal">
  <h3 class="mat-h3">Bot "{{data.bot.name}}" configuration history</h3>

  <app-spinner *ngIf="isLoading; else content"></app-spinner>

  <ng-template #content>
    <mat-accordion *ngIf="history.length > 0; else noContent">
      <mat-expansion-panel hideToggle *ngFor="let item of history">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="d-flex align-items-center justify-content-between title">
              <p>{{ item.versionCreatedAt | dateFromNow }}</p>
              <p>{{ item.versionCreatedAt | date:'YYYY-MM-dd, HH:mm' }}</p>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-bot-config-comparison
          [current]="data.bot"
          [fieldSet]="data.fieldSet"
          [version]="item"
          (revert)="revertVersion($event)"
          (delete)="deleteVersion($event)"
        ></app-bot-config-comparison>
      </mat-expansion-panel>
    </mat-accordion>
    <ng-template #noContent>
      <p class="no-content">The history is empty</p>
    </ng-template>
  </ng-template>

  <div class="d-flex align-items-center justify-content-between m-t-16">
    <button
      mat-raised-button
      color="accent"
      (click)="cancel()">
      Cancel
    </button>
  </div>
</div>
