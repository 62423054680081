<div class="modal">
  <h3 class="mat-h3 m-t-24">{{ title }}</h3>
  <form class="form-field-full folders-list-form" [formGroup]="form">
    <ng-container formArrayName="allItems">
      <ng-container *ngFor="let item of allItems.controls; let i = index" [formGroupName]="i">
        <div class="d-flex align-items-center justify-content-between folder-item">
          <div class="d-flex align-items-center">
            <div class="item-name">
              {{ item.value.name }}
              <span
                *ngIf="availableItems[i].sufix"
                class="gingr-content__drawer-content__item__sufix"
              >
                {{ availableItems[i].sufix }}
              </span>
            </div>
            <div *ngIf="botTypes[item.value.id]" class="type-container">
              <mat-chip color="accent">{{ botTypes[item.value.id] }}</mat-chip>
            </div>
          </div>
          <mat-slide-toggle
            class="m-l-16"
            [checked]="item.value.isSelected"
            (change)="toggleItemSelection($event, item.value.id)"
          />
        </div>
      </ng-container>
    </ng-container>
  </form>
  <button
    class="d-flex align-items-center justify-start m-t-16 controls"
    mat-raised-button
    color="accent"
    (click)="cancel()"
  >
    Close
  </button>
</div>
