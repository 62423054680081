<div class="row align-items-center justify-content-between m-b-24">
  <h3 class="mat-h3 m-b-0">Project's bots</h3>
  <button
    *ngIf="projectBots.length"
    mat-raised-button
    class="m-l-16 control"
    color="accent"
    (click)="openAllBotsListModal()">
    Edit project bots
  </button>
</div>
<ng-container *ngIf="projectBots.length else noBots">
  <div class="row">
    <div *ngFor="let bot of projectBots" class="m-r-48 cursor-pointer bot-box" (click)="navigateToBot(bot._id)">
      <mat-icon class="menu-icon" svgIcon="bots"></mat-icon>
      <p>{{bot.name}}</p>
    </div>
  </div>
</ng-container>
<ng-template #noBots>
  <div class="empty-container">
    <mat-icon svgIcon="bots" class="m-b-48 empty-icon"></mat-icon>
    <div class="empty-list-indicator">There are no bots assigned to this project. Click button below to change it.</div>
    <button
      mat-raised-button
      class="m-t-48"
      color="accent"
      (click)="openAllBotsListModal()">
      Add project bots
    </button>
  </div>
</ng-template>
