import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TProjectDetails } from '@core/interfaces/TProjectItem';

@Component({
  selector: 'app-edit-project-modal',
  templateUrl: './edit-project-modal.component.html',
  styleUrls: ['./edit-project-modal.component.scss']
})
export class EditProjectModalComponent {
  @Output() public saveCallback = new EventEmitter();

  public projectForm = this.fb.nonNullable.group({
    name: [this.data.name, [Validators.required]],
    description: [this.data.description]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TProjectDetails,
    private dialogRef: MatDialogRef<EditProjectModalComponent>,
    private fb: FormBuilder,
  ) {
  }

  save(): void {
    this.saveCallback.emit(this.projectForm.value);
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
