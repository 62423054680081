import { DateRange } from '../../enums/date-range';

import * as moment from 'moment';

const REFERENCE = moment();
const TODAY = REFERENCE.clone().startOf('day');
const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

export const getDateRange = (date: Date): DateRange => {
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - new Date(date).getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days

  const isDateToday = isToday(date);
  if (isDateToday) {
    return DateRange.Today;
  }

  const isDateYesterday = isYesterday(date);
  if (isDateYesterday) {
    return DateRange.Yesterday;
  } else if (daysDifference <= 7) {
    return DateRange.Previous7Days;
  } else if (daysDifference <= 30) {
    return DateRange.Previous30Days;
  } else {
    return DateRange.Older;
  }
};

const isToday = (date: Date): boolean => {
  return moment(date).isSame(TODAY, 'd');
};

const isYesterday = (date: Date): boolean => {
  return moment(date).isSame(YESTERDAY, 'd');
};
