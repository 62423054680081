import { environment } from '@env/environment';

import { TMaterialSourceFolder, TSourceMaterial } from '@core/api/materials/types';
import {
  DeleteRequestData,
  GetRequestData,
  PostRequestData,
  PutRequestData,
} from '@core/api/utils';

export class SourcesMaterialsApi {
  private static readonly uri = '/api/resources';

  static getFolders(): GetRequestData {
    return {
      url: `${environment.backendURL}/api/resourceFolders`,
    };
  }

  static add(body: FormData): PostRequestData<FormData> {
    return {
      url: `${environment.backendURL}${this.uri}`,
      body,
    };
  }

  static createFolder(body: TMaterialSourceFolder): PostRequestData<TMaterialSourceFolder> {
    return {
      url: `${environment.backendURL}/api/resourceFolders`,
      body,
    };
  }

  static updateFolder(data: TMaterialSourceFolder): PutRequestData<TMaterialSourceFolder> {
    return {
      url: `${environment.backendURL}/api/resourceFolders/${data._id}`,
      body: data,
    };
  }

  static getAllForFolder(folderId: string): GetRequestData {
    return {
      url: `${environment.backendURL}/api/resourceFolders/${folderId}`,
    };
  }

  static getAllowedFilesTypes(): GetRequestData {
    return {
      url: `${environment.backendURL}${this.uri}/fileTypes`,
    };
  }

  static edit(body: TSourceMaterial, id: string): PutRequestData<TSourceMaterial> {
    return {
      url: `${environment.backendURL}${this.uri}/${id}`,
      body,
    };
  }

  static delete(id: string): DeleteRequestData {
    return {
      url: `${environment.backendURL}${this.uri}/${id}`,
    };
  }
  static deleteFolder(id: string): DeleteRequestData {
    return {
      url: `${environment.backendURL}/api/resourceFolders/${id}`,
    };
  }
  static downloadFile(filename: string | undefined): GetRequestData {
    return {
      url: `${environment.backendURL}/api/fileStorage/download/${filename}`,
    };
  }
}
