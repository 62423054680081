<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <p>
        List of supported applications
      </p>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="app-list-container">
    <ng-container *ngFor="let app of appList">
      <span class="name">{{ app.name }}</span>
      <div *ngIf="app.androidUrl">
        <a target="_blank" [href]="app.androidUrl">
          <button mat-raised-button color="primary">
            Get for Android
            <mat-icon>open_in_new</mat-icon>
          </button>
        </a>
      </div>
      <div *ngIf="app.iosUrl">
        <a target="_blank" [href]="app.iosUrl">
          <button mat-raised-button color="primary">
            Get for iOS
            <mat-icon>open_in_new</mat-icon>
          </button>
        </a>
      </div>
    </ng-container>
  </div>
</mat-expansion-panel>
