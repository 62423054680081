<span>
    <span class="item-title"
          [matTooltipDisabled]=!needsTooltip(item.title)
          matTooltipPosition="right"
          [matTooltip]=item.title
    >{{ item.title === undefined ? "New chat" : item.title }} </span>
  </span>
<span *ngIf="hasSufix(item)" class="gingr-content__drawer-content__item__sufix">
    {{ item.sufix }}
  </span>
<span *ngIf="canDeleteItem" class="d-flex gingr-content__drawer-content__item__sufix delete-icon">
    <mat-icon class="icon" (click)="openDeleteItemModal(item)">delete</mat-icon>
  </span>
<span *ngIf="canBeSelected" class="d-flex gingr-content__drawer-content__item__sufix delete-icon">
    <mat-checkbox (change)="toggleSelection()" [(ngModel)]="isSelected"></mat-checkbox>
  </span>
