<div class="video-container" (mouseenter)="controlsAreShown = true" (mouseleave)="controlsAreShown = false">
  <div>
    <video
      #videoPlayer
      autoplay
      playsinline
      width="900" height="506"
    >
    </video>
  </div>

  <button *ngIf="controlsAreShown && !hideControls" class="play-pause-btn" (click)="togglePlayPause()">
    <mat-icon>{{ isPlaying ? 'pause' : 'play_arrow' }}</mat-icon>
  </button>

</div>
