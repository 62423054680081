import { Component, Input } from '@angular/core';
import { BotStatusTypes } from '@core/interfaces/TBot';
import { AppSettingsService } from '@shared/services/settings/app-settings.service';
import { botsTypesForcedStatus } from '@shared/services/settings/types';

@Component({
  selector: 'app-bot-status-badge',
  templateUrl: './bot-status-badge.component.html',
  styleUrls: ['./bot-status-badge.component.scss'],
})
export class BotStatusBadgeComponent {
  @Input() botStatus: BotStatusTypes | undefined;
  @Input() botType: string | undefined;
  protected masterStatus: string | null | undefined;

  constructor(
    private _appSettingsService: AppSettingsService,
  ) {
    this._appSettingsService.getSettingsAsObservable().subscribe(settings => {
      this.masterStatus = settings.master_bot_status;
    });
  }

  getLabel(): string {
    switch (this.status) {
      case 'issue':
        return 'Issue - do not use';
      case 'in_validation':
        return 'In validation & testing';
      case 'decommissioned':
        return 'Decommissioned';
      case 'validated':
        return 'Validated & ready to use';
      default:
        return '';
    }
  }

  get status(): BotStatusTypes {
    if (botsTypesForcedStatus.includes(this.botType!)) {
      this.botStatus = this.masterStatus ?? this.botStatus;
    }
    return this.botStatus ?? 'in_validation';
  }
}
