import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { botStatuses } from '@core/interfaces/TBot';
import { TBotItem } from '@core/interfaces/TBotItem';

import { BotsService } from '@shared/services/bots.service';
import { CommonService } from '@shared/services/common.service';
import {
  botBehaviourInfoMessage,
  botInstructionInfoMessage,
  materialsConfigInfoMessage,
  publicationsCountInfoMessage,
} from '@shared/utils/messages';
import { AppSettingsService } from '@shared/services/settings/app-settings.service';
import { botsTypesForcedStatus } from '@shared/services/settings/types';
import { TBotModel } from '@core/api/bots/types';
import { Subscription } from 'rxjs';
import { TalkingAvatarSettingsComponent } from './talking-avatar-settings/talking-avatar-settings.component';

@Component({
  selector: 'app-bot-editor',
  templateUrl: './bot-editor.component.html',
  styleUrls: ['./bot-editor.component.scss'],
})
export class BotEditorComponent implements OnInit, OnDestroy {
  private USE_ALL_PUBLICATIONS_COUNT = 999;
  public MAX_PUBLICATIONS_LIMIT = 10;
  private _bot: TBotItem = {} as TBotItem;
  protected botsModels: TBotModel[] = [];
  private subs: Subscription[] = [];
  protected readonly botsTypesForcedStatus = botsTypesForcedStatus;
  @ViewChild(TalkingAvatarSettingsComponent) talkingAvatarSettingsComponent:
    | TalkingAvatarSettingsComponent
    | undefined;

  @Input()
  public set bot(bot: TBotItem) {
    this._bot = bot;
    this.botEditorForm.patchValue({
      name: bot.name,
      description: bot.description,
      preSearch: bot.preSearch,
      instruction: bot.instruction,
      behaviour: bot.behaviour,
      config: bot.config,
      bot_status: bot.bot_status,
      bot_model: bot.botModel,
      showOnDashboard: bot.showOnDashboard,
      display_resources: bot.display_resources,
      maximum_resources: bot.maximum_resources,
      extraConfigurations: bot.extraConfigurations,
      maximum_publications:
        bot.maximum_publications === this.USE_ALL_PUBLICATIONS_COUNT
          ? this.MAX_PUBLICATIONS_LIMIT
          : bot.maximum_publications,
      use_all_publications: bot.maximum_publications === this.USE_ALL_PUBLICATIONS_COUNT,
    });
  }

  get bot(): TBotItem {
    return this._bot;
  }

  @Output() public cancelCallback = new EventEmitter();

  protected readonly botStatuses = botStatuses;
  publicationsCountInfo = publicationsCountInfoMessage;
  materialsInfo = materialsConfigInfoMessage;
  infoBehaviour = botBehaviourInfoMessage;
  infoInstruction = botInstructionInfoMessage;
  botConfig = materialsConfigInfoMessage;

  public botEditorForm = this.fb.nonNullable.group({
    name: ['', [Validators.required]],
    description: [''],
    preSearch: [''],
    instruction: [''],
    behaviour: [''],
    bot_status: ['in_validation'],
    bot_model: [''],
    display_resources: [false],
    config: [''],
    showOnDashboard: [false],
    maximum_resources: [0, [Validators.min(0)]],
    maximum_publications: [0, [Validators.min(0)]],
    use_all_publications: [false],
    extraConfigurations: this.fb.group({
      feedback: this.fb.group({
        behaviour: [''],
        instruction: [''],
        config: [''],
      }),
      initMessage: this.fb.group({
        behaviour: [''],
        instruction: [''],
        config: [''],
      }),
      secondRun: this.fb.group({
        config: [''],
      }),
    }),
  });

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private _commonService: CommonService,
    private _botService: BotsService,
    protected appGlobalSettings: AppSettingsService
  ) {}

  ngOnInit() {
    this.subs.push(
      this._botService.getBotsModelsObservable().subscribe(res => {
        this.botsModels = res;
      })
    );
    this._botService.getBotModels();
  }

  public saveBotConfig(): void {
    this.talkingAvatarSettingsComponent?.storeTalkingAvatarConfig();

    const formVal = this.botEditorForm.value;
    this._botService.editBot(
      {
        id: this.bot._id,
        botCreatorId: this.bot._id,
        name: formVal.name,
        description: formVal.description,
        preSearch: formVal.preSearch,
        instruction: formVal.instruction,
        behaviour: formVal.behaviour,
        config: formVal.config,
        showOnDashboard: formVal.showOnDashboard,
        bot_status: formVal.bot_status,
        botModel: formVal.bot_model,
        display_resources: formVal.display_resources,
        maximum_resources: formVal.maximum_resources,
        maximum_publications: formVal.use_all_publications
          ? this.USE_ALL_PUBLICATIONS_COUNT
          : formVal.maximum_publications,
        extraConfigurations: formVal.extraConfigurations
          ? {
              feedback: formVal.extraConfigurations.feedback,
              initMessage: formVal.extraConfigurations.initMessage,
              secondRun: formVal.extraConfigurations.secondRun,
            }
          : undefined,
      },
      () => {
        this._commonService.openSnackBar('Bot Creator saved');
      }
    );
  }

  ngOnDestroy() {
    this.subs.forEach(value => value.unsubscribe());
  }
}
