<div class="d-flex source-modal">
  <form class="form-field-full" [formGroup]="contentForm">
    <h3 class="mat-h3">Source content</h3>
    <mat-form-field class="gingr-input-wrapper w-full">
      <textarea matInput formControlName="content"></textarea>
    </mat-form-field>
    <div class="d-flex justify-content-center">
      <mat-progress-spinner
        *ngIf="isLoading"
        class="spinner"
        [color]="'accent'"
        [mode]="'indeterminate'"
        [value]="50">
      </mat-progress-spinner>
    </div>
    <div class="d-flex align-items-center justify-content-between m-t-16">
      <button
        mat-raised-button
        color="accent"
        (click)="cancel()"
        [disabled]="isLoading">
        Cancel
      </button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="contentForm.invalid || isLoading"
        (click)="saveContent()">
        Save content
      </button>
    </div>
  </form>
</div>
