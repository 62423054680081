import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TFileExportConfiguration } from '@shared/services/file-export/file-export-interface';

import { SelectItemsToExportModalComponent } from '../../../routes/components/modals/select-items-to-export-modal/select-items-to-export-modal.component';
import { TextFileExportModalComponent } from '../../../routes/components/modals/text-file-export-modal/text-file-export-modal.component';
import { FileExportService } from '@shared/services/file-export/services/file-export.service';

@Component({
  selector: 'app-export-to-selector',
  templateUrl: './export-to-selector.component.html',
  styleUrls: ['./export-to-selector.component.scss'],
})
export class ExportToSelectorComponent implements OnDestroy {
  @Input() isDisabled = false;
  @Output() protected buttonClicked = new EventEmitter<boolean>();
  private _subs: Subscription[] = [];

  constructor(
    protected fileExportService: FileExportService,
    public dialog: MatDialog
  ) {}

  protected selectItemsToExport() {
    this._subs.push(
      this.dialog
        .open(SelectItemsToExportModalComponent)
        .componentInstance.selected.pipe(tap((text: string) => this.exportTo(text)))
        .subscribe()
    );
  }

  protected exportTo(text: string) {
    const dialogRef = this.dialog.open(TextFileExportModalComponent, {
      data: { text },
    });
    this._subs.push(
      dialogRef.componentInstance.actionCallback.subscribe((data: TFileExportConfiguration) => {
        this.fileExportService.getFile(data);
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(item => item.unsubscribe());
  }
}
