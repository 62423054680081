import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileStorageApi } from '@core/api/file-storage/api';
import { TFileUpdated } from '@core/api/file-storage/types';

@Injectable({
  providedIn: 'root',
})
export class FileStorageService {
  constructor(private _http: HttpClient) {}

  upload(file: File): Observable<TFileUpdated> {
    const { url, formData } = FileStorageApi.upload(file);
    return this._http.post<TFileUpdated>(url, formData);
  }

  download(fileKey: string): Observable<Blob> {
    const { url } = FileStorageApi.download(fileKey);
    return this._http.get(url, { responseType: 'blob' });
  }
}
