<div class="new-item-modal duplicate-modal">
  <h3 class="mat-h3">Name of new bot</h3>
    <mat-form-field class="gingr-input-wrapper new-bot-name">
      <input [(ngModel)]="name" matInput placeholder="Bot's title" >
    </mat-form-field>

  <div class="d-flex align-items-center justify-content-between m-t-16">
    <button
      mat-raised-button
      color="accent"
      (click)="cancel()">
      Cancel
    </button>
    <button
      mat-raised-button
      color="accent"
      [disabled]="!isValid"
      (click)="confirm()">
      Duplicate
    </button>
  </div>
</div>
