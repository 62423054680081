<form class="form-field-full" [formGroup]="newFolderForm">
  <div class="form-inputs" *ngIf="!isDisabled">
    <mat-form-field class="gingr-input-wrapper w-full">
      <input
        matInput
        placeholder="Folder name"
        type="text"
        formControlName="name"
        required />
    </mat-form-field>

    <mat-form-field class="gingr-input-wrapper w-full">
      <mat-select formControlName="status" placeholder="Select status">
        <mat-option *ngFor="let st of statuses" [value]="st">
          {{ st | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <app-spinner *ngIf="isDisabled" message="Wait..."></app-spinner>
</form>
