<div class="container">
  <div class="countries">
    <div class="m-b-24 m-t-8 suffix">Please set up countries allowed to be used by bots.</div>
    <mat-form-field class="gingr-input-wrapper">
      <input [(ngModel)]="searchCountry" matInput placeholder="Search countries" />
      <mat-icon (click)="clearSearchCountry()" class="clear-icon" matSuffix>clear</mat-icon>
    </mat-form-field>

    <div class="countries-container">
      <ng-container *ngFor="let country of countries | searchFilter: searchCountry : 'name'">
        <div class="inner-bot-row align-items-center">
          <div class="d-flex align-items-center">
            <mat-icon class="icon">flag</mat-icon>
            <span class="item-title">{{ country.name }}</span>
            <mat-icon class="icon-button m-l-16" (click)="deleteCountry(country._id)"
              >delete</mat-icon
            >
          </div>
        </div>
      </ng-container>
    </div>

    <div class="m-t-24 suffix">Add country:</div>
    <form class="form m-t-8" [formGroup]="countryForm">
      <mat-form-field class="gingr-input-wrapper">
        <input matInput placeholder="country" type="text" formControlName="name" required />
      </mat-form-field>
      <button
        class="m-l-16"
        mat-raised-button
        color="accent"
        [disabled]="countryForm.invalid"
        (click)="addCountry()"
      >
        Add
      </button>
    </form>
  </div>

  <div class="regions">
    <div class="m-b-24 m-t-8 suffix">Please set up regions allowed to be used by bots.</div>
    <mat-form-field class="gingr-input-wrapper">
      <input [(ngModel)]="searchRegion" matInput placeholder="Search regions" />
      <mat-icon (click)="clearSearchRegion()" class="clear-icon" matSuffix>clear</mat-icon>
    </mat-form-field>

    <div class="regions-container">
      <ng-container *ngFor="let region of regions | searchFilter: searchRegion : 'name'">
        <div class="inner-bot-row align-items-center">
          <div class="d-flex align-items-center">
            <mat-icon class="icon">public</mat-icon>
            <span class="item-title" matTooltip="{{ regionCountries(region) }}">{{
              region.name
            }}</span>
            <mat-icon class="icon-button m-l-16" (click)="deleteRegion(region._id)"
              >delete</mat-icon
            >
          </div>
        </div>
      </ng-container>
    </div>

    <div class="m-t-24 suffix">Add region:</div>
    <form class="form m-t-8" [formGroup]="regionForm">
      <mat-form-field class="gingr-input-wrapper">
        <input matInput placeholder="region" type="text" formControlName="name" required />
      </mat-form-field>
      <mat-form-field class="gingr-input-wrapper hide-sub">
        <mat-select formControlName="countries" placeholder="Select country" multiple>
          <mat-option *ngFor="let c of countries; let i = index" [value]="c._id">
            {{ c.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        class="m-l-16"
        mat-raised-button
        color="accent"
        [disabled]="regionForm.invalid"
        (click)="addRegion()"
      >
        Add
      </button>
    </form>
  </div>
</div>
