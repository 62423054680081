import { DeleteRequestData, GetRequestData, PostRequestData, PutRequestData } from '@core/api/utils';
import { environment } from '@env/environment';
import { TPaging } from '@core/interfaces/TPaging';
import { TBaseUser } from '@core/interfaces/Endpoints';
import { TEnable2FA } from '@core/api/users/types';

export class UsersApi {
  static getUsers(paging: TPaging): GetRequestData {
    return {
      url: `${environment.backendURL}/api/users?page=${paging.page}&limit=${paging.limit}`,
    };
  }

  static createUser(user: TBaseUser): PostRequestData<TBaseUser> {
    return {
      body: user,
      url: `${environment.backendURL}/api/users`,
    };
  }

  static updateUser(user: TBaseUser): PutRequestData<TBaseUser> {
    return {
      body: user,
      url: `${environment.backendURL}/api/users/${user.username}`,
    };
  }

  static deleteUser(user: TBaseUser): DeleteRequestData {
    return {
      url: `${environment.backendURL}/api/users/${user.username}`,
    };
  }

  static searchUser(search: string): GetRequestData {
    return {
      url: `${environment.backendURL}/api/users/search?username=${search}`,
    };
  }

  static getUsersForProjects(projectId: string): GetRequestData {
    return {
      url: `${environment.backendURL}/api/users/byProject/${projectId}`,
    };
  }

  static updateProjectUsers(projectId: string, userIds: string[]): PostRequestData<any> {
    return {
      url: `${environment.backendURL}/api/users/assignUsers`,
      body: {
        projectId,
        userIds,
      },
    };
  }

  static detachUserFromProject(projectId: string, username: string): DeleteRequestData {
    return {
      url: `${environment.backendURL}/api/users/removeUser/${username}/${projectId}`,
    };
  }

  static toggle2Fa(username: string, toState: boolean): PostRequestData<TEnable2FA> {
    const changeToState = toState ? 'enable' : 'disable';
    return {
      url: `${environment.backendURL}/api/users/${username}/${changeToState}-2fa`,
    };
  }
}
