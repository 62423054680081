<div
  class="multi-select"
  [ngClass]="{ visible: items.length > 0, collapsed: items.length > 0 ? isCollapsed : false }"
>
  <div [ngClass]="{ highlighted: isHighlighted }" class="multi-select--header">
    <span (click)="toggleCollapsed()">
      <strong>Selected items</strong> ({{ items.length }}/{{
        multiSelectService.maxListItems
      }})</span
    >
    <div class="multi-select--icons">
      <mat-icon (click)="toggleCollapsed()">
        {{ isCollapsed ? 'expand_less' : 'expand_more' }}
      </mat-icon>
      <mat-icon (click)="multiSelectService.deleteAll()">close</mat-icon>
    </div>
  </div>
  <div class="multi-select--content">
    <div *ngFor="let item of items" class="multi-select--list">
      <div>
        {{ item.name }}
      </div>
      <div>
        <mat-icon (click)="multiSelectService.removeItem(item)">delete</mat-icon>
      </div>
    </div>
    <div class="multi-select--action-buttons">
      <div
        [matTooltip]="
          !this.multiSelectService.getSummaryBotId() ? 'There is no summary bot set' : ''
        "
      >
        <button
          [disabled]="!this.multiSelectService.getSummaryBotId()"
          mat-raised-button
          color="accent"
          (click)="makeASummary()"
        >
          Make a summary
        </button>
      </div>

      <div
        [matTooltip]="
          !this.multiSelectService.getCompareBotId() ? 'There is no compare bot set' : ''
        "
      >
        <button
          mat-raised-button
          color="accent"
          [disabled]="items.length <= 1 || !this.multiSelectService.getCompareBotId()"
          (click)="compare()"
        >
          Compare
        </button>
      </div>

      <div
        [matTooltip]="
          !this.multiSelectService.getConversationBotId() ? 'There is no conversation bot set' : ''
        "
      >
        <button
          mat-raised-button
          color="accent"
          [disabled]="items.length !== 1 || !this.multiSelectService.getConversationBotId()"
          (click)="askQuestions()"
        >
          Ask questions
        </button>
      </div>
    </div>
  </div>
</div>
