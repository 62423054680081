import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SourcesMaterialsApi } from '@core/api/materials/api';
import {
  TMaterialSourceFolder,
  TSourceMaterial,
  TSourceMaterialFileTypes,
} from '@core/api/materials/types';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SourceMaterialsService {
  private _materials = new BehaviorSubject<TMaterialSourceFolder | null>(null);
  materials$ = this._materials.asObservable();

  private _folders = new BehaviorSubject<TMaterialSourceFolder[]>([]);
  folders$ = this._folders.asObservable();

  private _selectedFolder = new BehaviorSubject<TMaterialSourceFolder | null>(null);
  selectedFolder$ = this._selectedFolder.asObservable();

  constructor(private _http: HttpClient) {}

  setFolder(folder: TMaterialSourceFolder | null) {
    this._selectedFolder.next(folder);
  }

  getFoldersList() {
    const { url } = SourcesMaterialsApi.getFolders();
    return this._http
      .get<TMaterialSourceFolder[]>(url)
      .pipe(tap(folders => this._folders.next(folders)))
      .subscribe();
  }

  createFolder(data: TMaterialSourceFolder, callback: (item: TMaterialSourceFolder) => void) {
    const { url, body } = SourcesMaterialsApi.createFolder(data);
    const response = this._http.post<TMaterialSourceFolder>(url, body);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: item => {
        const folders = this._folders.getValue();
        folders.push(item);
        this._folders.next(folders);
        callback(item);
      },
    });
  }

  updateFolder(data: TMaterialSourceFolder, callback: () => void) {
    const { url, body } = SourcesMaterialsApi.updateFolder(data);
    const response = this._http.put<TMaterialSourceFolder>(url, body);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: item => {
        const folders = this._folders.getValue();
        const index = folders.findIndex(folder => folder._id === data._id);
        if (index !== -1) {
          folders[index] = item;
          this._folders.next(folders);
        }
        callback();
      },
    });
  }

  create(data: FormData) {
    const { url, body } = SourcesMaterialsApi.add(data);
    return this._http.post<TSourceMaterial>(url, body);
  }

  edit(id: string, data: TSourceMaterial) {
    const { url, body } = SourcesMaterialsApi.edit(data, id);
    return this._http.put<TSourceMaterial>(url, body);
  }

  getAllForSelectedFolder(folderId: string) {
    const { url } = SourcesMaterialsApi.getAllForFolder(folderId);
    return this._http
      .get<TMaterialSourceFolder>(url)
      .pipe(tap(folder => this._materials.next(folder)))
      .subscribe();
  }

  getFileTypes() {
    const { url } = SourcesMaterialsApi.getAllowedFilesTypes();
    return this._http
      .get<{ fileTypes: TSourceMaterialFileTypes[] }>(url)
      .pipe(map(response => response.fileTypes));
  }

  delete(id: string, callback: () => void) {
    const { url } = SourcesMaterialsApi.delete(id);
    const response = this._http.delete(url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        const folder = this._materials.getValue();
        folder!.resources = folder?.resources?.filter(material => material._id !== id);
        this._materials.next(folder);
        callback();
      },
    });
  }

  deleteFolder(id: string, callback: () => void) {
    const { url } = SourcesMaterialsApi.deleteFolder(id);
    const response = this._http.delete(url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        this._folders.next(this._folders.getValue().filter(folder => folder._id !== id));
        callback();
      },
    });
  }
}
