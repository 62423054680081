import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTextEllipsis]',
})
export class TextEllipsisDirective implements AfterViewInit {
  domElement: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.domElement = this.elementRef.nativeElement;

    const elipsify = {
      'text-overflow': 'ellipsis',
      'overflow': 'hidden',
      'white-space': 'nowrap',
    } as const;

    Object.keys(elipsify).forEach(element => {
      this.renderer.setStyle(
        this.domElement,
        `${element}`,
        elipsify[element as keyof typeof elipsify]
      );
    });
  }

  ngAfterViewInit(): void {
    this.renderer.setProperty(this.domElement, 'scrollTop', 1);
    this.setToolTip();
  }

  @HostListener('window:resize', ['$event.target'])
  setToolTip() {
    this.domElement.offsetWidth < this.domElement.scrollWidth
      ? this.renderer.setAttribute(this.domElement, 'title', this.domElement.textContent)
      : this.renderer.removeAttribute(this.domElement, 'title');
  }
}
