<div class="export-selector-modal">
  <h3 class="mat-h3">Choose messages to export</h3>
  <div class="list-container">

    <div
      *ngFor="let msg of botMessageService.getValue()?.messages; index as i"
    >
      <mat-checkbox
        [(ngModel)]="selectedItems[i]"
        [class]=[msg.author]
        (change)="toggleSelection(i, $event)"
      >
        <span>{{ msg.paragraphs[0].text | truncate:80 }}</span>
      </mat-checkbox>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-between m-t-20">
    <button
      mat-raised-button
      color="accent"
      type="button"
      (click)="cancel()">
      Cancel
    </button>
    <div>
      <mat-checkbox [(ngModel)]="includeRefs" class="m-r-16">Include references</mat-checkbox>
      <button
        mat-raised-button
        color="accent"
        class="m-r-16"
        type="button"
        data-cy="select-all"
        (click)="toggleSelectAll()">
        <mat-icon *ngIf="!selectedAll" class="icon">select_all</mat-icon>
        <mat-icon *ngIf="selectedAll" class="icon">clear</mat-icon>
        {{ selectedAll ? 'Unselect all' : 'Select all' }}
      </button>
      <button
        (click)="exportSelected()"
        mat-raised-button
        data-cy="export"
        [disabled]="!isValid()"
        color="accent">
        <mat-icon class="icon">output</mat-icon>
        Export...
      </button>
    </div>
  </div>
</div>
