<span [matTooltip]="'Add prompt to hints'">
  <button
    *ngIf="message?.author === 'user'"
    mat-icon-button
    color="{{ isSelected ? 'accent' : 'white' }}"
    (click)="addToHints()"
  >
    <mat-icon class="icon">thumb_up</mat-icon>
  </button>
</span>
