import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PreloaderService, SettingsService } from '@core';
import { IconService } from '@shared/services/icons.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private preloader: PreloaderService,
    private settings: SettingsService,
    private iconService: IconService
  ) {}

  ngOnInit() {
    this.settings.setDirection();
    const options = this.settings.getOptions();
    options.theme = `dark`;
    this.settings.setOptions(options);
    this.settings.setTheme();
    this.iconService.registerIcons();
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }
}
