import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TBotItem, TBotItemHistory } from '@core/interfaces/TBotItem';
import { TInnerBot } from '@core/interfaces/TBot';

export type TFieldSet = 'bot' | 'innerBot';

@Component({
  selector: 'app-bot-config-comparison',
  templateUrl: './bot-config-comparison.component.html',
  styleUrls: ['./bot-config-comparison.component.scss'],
})
export class BotConfigComparisonComponent {
  @Input() current: TBotItem | TInnerBot | undefined;
  @Input() version: TBotItemHistory | undefined;
  @Input() fieldSet: TFieldSet = 'bot';
  @Output() revert = new EventEmitter<TBotItemHistory>();
  @Output() delete = new EventEmitter<TBotItemHistory>();
  protected compareFieldsCommon: string[] = [
    'name', 'instruction', 'behaviour', 'description', 'config', 'botModel'
  ];
  protected compareFieldsBot: string[] = [
    'display_resources', 'maximum_publications', 'maximum_resources',
  ];
  protected compareFieldsInnerBot: string[] = [
    'presence_penalty', 'temperature', 'top_p', 'frequency_penalty',
  ];

  get compareFields(): string[] {
    return this.compareFieldsCommon.concat(this.fieldSet === 'bot' ? this.compareFieldsBot : this.compareFieldsInnerBot);
  }

  getValue(object: TBotItem | TInnerBot | TBotItemHistory | undefined, key: string): string {
    if (object) {
      const value = object?.[key as keyof typeof object];
      if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
      }
      return value ?? '';
    }
    return '';
  }

  isDiff(field: string): boolean {
    if (this.version && this.current) {
      return this.getValue(this.version, field) !== this.getValue(this.current, field);
    }
    return false;
  }

  onRevert() {
    this.revert.emit(this.version);
  }

  onDelete() {
    this.delete.emit(this.version);
  }
}
